import { BreadcrumbModule } from './../../../../breadcrumb/breadcrumb.module';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MyAccountComponent } from './my-account.component';
import { IgoLanguageModule } from '../../../../language/language.module'; //'@igo2/core';
import { IgoMessageModule } from '@igo2/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StationsFiltersListModule } from 'src/app/pages/menu/menu-pages/stations/stations-filters-list/stations-filters-list.module';
import { AlertComponent } from './alert/alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FormatReadingPipe } from './format-reading.pipe';
import { SharedComponentsModule } from 'src/app/pages/shared/shared-components.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { OrderByPipe } from './orderby.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PhoneFormatPipe } from './phone-format.pipe';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';

@NgModule({
  declarations: [
    MyAccountComponent, AlertComponent, FormatReadingPipe, OrderByPipe, PhoneFormatPipe, WarningDialogComponent
  ],
  imports: [
    CommonModule,
    IgoLanguageModule,
    BreadcrumbModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    StationsFiltersListModule,
    MatDialogModule,
    MatRadioModule,
    IgoMessageModule,
    FormsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [DecimalPipe],
  exports: [
            MyAccountComponent, FormatReadingPipe
          ]
})
export class MyAccountModule { }
