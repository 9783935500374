import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TouchendService {

  @Output() touchEndEvent: EventEmitter<Event> = new EventEmitter();

  touchEnd(event: Event): void {
    this.touchEndEvent.emit(event);
  }
}
