<ng-container *ngIf="!isMobile else mobile">
	<div class="logo-title">
		<div class="logo">
			<a href="https://www.quebec.ca/">
				<img [attr.src]="headerLogo" width="200" height="72">
			</a>
		</div>
		<div class="title">
			<a class="link" routerLink="/">{{ 'header.title' | translate }}</a>
			<span *ngIf="['local', 'dev', 'acc'].includes(getEnvironment())"
				class="messageEnv"
				[ngClass]="{'local': getEnvironment() === 'local', 'dev': getEnvironment() === 'dev', 'acc': getEnvironment() === 'acc'}">{{ getEnvironmentLabel() | translate }}
			</span>
		</div>
	</div>
	<div class="links">
		<!--<a class="link" routerLink="">{{ 'header.language' | translate }}</a>-->
		<a class="link" *ngIf="hasAuthService() && authenticated || !hasAuthService()" routerLink="/nous-joindre">{{ 'header.contact' | translate }}</a>
	</div>
</ng-container>

<ng-template #mobile>
	<div class="logo-links">
		<div class="logo">
			<a href="https://www.quebec.ca/">
				<img [attr.src]="headerLogo" width="200" height="72">
			</a>
		</div>
		<div class="links">
			<!--<a class="link" routerLink="">{{ 'header.language' | translate }}</a>-->
			<a class="link" *ngIf="hasAuthService() && authenticated || !hasAuthService()" routerLink="/nous-joindre">{{ 'header.contact' | translate }}</a>
		</div>
	</div>
	<div class="title">
		<a class="link" routerLink="/">{{ 'header.title' | translate }}</a>
		<span *ngIf="['local', 'dev', 'acc'].includes(getEnvironment())"
			class="messageEnv"
			[ngClass]="{'local': getEnvironment() === 'local', 'dev': getEnvironment() === 'dev', 'acc': getEnvironment() === 'acc'}">{{ getEnvironmentLabel() | translate }}
		</span>
	</div>
</ng-template>
