import { NgModule } from '@angular/core';
import { IgoLanguageModule } from '../../language/language.module'; //'@igo2/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { MenuRoutingModule } from './menu-routing/menu-routing.module';
import { AboutMapModule } from './menu-pages/about/about-map/about-map.module';
import { AboutStationsModule } from './menu-pages/about/about-stations/about-stations.module';
import { AboutTrainingModule } from './menu-pages/about/about-training/about-training.module';
import { AboutTrainingM1Module } from './menu-pages/about/about-training/about-training-m1/about-training-m1.module';
import { AboutTrainingM2Module } from './menu-pages/about/about-training/about-training-m2/about-training-m2.module';
import { AboutTrainingM3Module } from './menu-pages/about/about-training/about-training-m3/about-training-m3.module';
import { AboutTrainingM4Module } from './menu-pages/about/about-training/about-training-m4/about-training-m4.module';
import { AboutTrainingM5Module } from './menu-pages/about/about-training/about-training-m5/about-training-m5.module';
import { HomeModule } from './menu-pages/home/home.module';
import { MapComponent } from './menu-pages/map/map.component';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { ContactModule } from './menu-pages/contact/contact.module';
import { PortalModule } from '../portal';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
//import { BreadcrumbModule } from 'src/app/breadcrumb/breadcrumb.module';

@NgModule({
  declarations: [
    MenuComponent,
    MapComponent
  ],
  imports: [
    CommonModule,
    IgoLanguageModule,
    PortalModule,
    HeaderModule,
    AboutMapModule,
    AboutStationsModule,
    AboutTrainingModule,
    AboutTrainingM1Module,
    AboutTrainingM2Module,
    AboutTrainingM3Module,
    AboutTrainingM4Module,
    AboutTrainingM5Module,
    HomeModule,
    FooterModule,
    ContactModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    //BreadcrumbModule,
    MenuRoutingModule
  ],
  exports:[
    MenuComponent,
    MenuRoutingModule
  ]
})
export class MenuModule { }
