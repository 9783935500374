<table class="table">
    <thead class="table-header">
        <tr>
            <th
                class="table-header-header-cell"
                rowspan="2">{{ 'stationsDetails.time' | translate }}
            </th>
            <th
                class="table-header-header-cell"
                [attr.colspan]="dates.length">{{ flow ? ('stationsDetails.flow' | translate) + " (m³/s)" : ('stationsDetails.level' | translate) + " (m)" }}
            </th>
        </tr>
        <tr>
            <th *ngFor="let date of dates"
                class="table-header-header-cell">{{ date | date:getDateFormat() }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let reading of readingsTable | keyvalue"
            class="table-body-row">
            <th class="table-body-header-cell">{{ getTimeFormat(reading.key) }}</th>
            <td *ngFor="let value of readingsTable[reading.key] | keyvalue"
                class="table-body-data-cell">
                {{ value.value ? (value.value | formatReading) : 'stationsDetails.na' | translate }}
            </td>
        </tr>
    </tbody>
</table>

<p>* {{ 'stationsDetails.stationsDetailsTable.na' | translate }}</p>