<div class="footer-right" id="footer-id">
    <div id="scaleLine"></div>
    <span class="order2">
        <span class="footer-text">
            <a rel="noopener noreferrer" [attr.href]="'footer.conditionsUrl' | translate"
            target="_blank">{{'footer.conditions' | translate}}</a>
        </span>
        <span class="footer-text">  |  </span>
        <span class="footer-text">
            <a rel="noopener noreferrer" [attr.href]="'footer.ministryUrl' | translate"
            target="_blank">{{'footer.ministry' | translate}}</a>
        </span>
        <span class="footer-text">  |  </span>
        <span class="footer-text">
            <a rel="noopener noreferrer" [attr.href]="'footer.govUrl' | translate"
            target="_blank">{{'footer.gov' | translate}}</a>
        </span>
    </span>
</div>
