<div class="container">
	<div class="content">
		<ng-container *ngIf="!isMobile else mobileBreadcrumbs">
			<div class="breadcrumbs">
				<a class="breadcrumbs-element" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<p class="breadcrumbs-element-current">{{ 'about.title' | translate }}</p>
			</div>
		</ng-container>
		
		<ng-template #mobileBreadcrumbs>
			<div class="breadcrumbs">
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
				<a class="breadcrumbs-element-mobile" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
			</div>
		</ng-template>
	
		<div class="menu-about-map">
			<ng-container *ngIf="!isMobile else menuMobile">
				<div class="menu">
					<p class="menu-title">{{ 'about.menu.title' | translate }}</p>
					<a class="menu-option" routerLink="../carte">{{ 'about.menu.map' | translate }}</a>
					<a class="menu-option" routerLink="../stations">Stations</a>
                    <p class="menu-selected">{{ 'about.menu.training.title' | translate }}</p>
				</div>
			</ng-container>
			<ng-template #menuMobile>
				<div class="menu-mobile">
					<div class="menu-mobile-header"
						(click)="menuShown = !menuShown">
						<p class="menu-mobile-header-label">{{ 'about.menu.title' | translate }}</p>
						<mat-icon color="primary">{{ !menuShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="menuShown" class="menu-mobile-options">
                        <a class="menu-mobile-option" routerLink="../carte">{{ 'about.menu.map' | translate }}</a>
						<a class="menu-mobile-option" routerLink="../stations">Stations</a>
                        <p class="menu-mobile-option-selected">{{ 'about.menu.training.title' | translate }}</p>
					</div>
				</div>
			</ng-template>
			<div class="about-map">
				<h1>{{ 'about.training.title' | translate }}</h1>
			</div>
		</div>
		<app-shared-footer class="footer"></app-shared-footer>
	</div>
</div>
