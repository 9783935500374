import { Component, HostListener } from '@angular/core';

import { TouchendService } from "src/app/pages/shared/touchend/touchend.service";
import { AuthService } from '@igo2/auth';
import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { HttpOptions, StationForList } from 'src/app/pages/shared/stations/stations.interface';
import { LanguageService } from '@igo2/core';
import { MessageService } from '@igo2/core';
import { State, StateColor, StateLegend, FilterLabel, Trend } from 'src/app/pages/shared/stations/stations.enum';

@Component({
	selector: 'app-stations-filters-list',
	templateUrl: './stations-filters-list.component.html',
	styleUrls: ['./stations-filters-list.component.scss']
})

export class StationsFiltersListComponent {
	@HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

	@HostListener('touchend', ['$event'])
	onTouchEnd() {
      this._touchendService.touchEnd(event);
	}
	public isMobile: boolean = window.innerWidth >= 768 ? false : true;
	public reportDateTime: Date;
	public reportWindowOpened: boolean = false;
	public stationListReport: StationForList[] = undefined;
	public selectedOptions: object; // selected filter options
	public optionsAreSelected: boolean;
	public filtersString: string; // filters string
	public currentSort: string;
	public removedFilter: object; // removed filter

	FilterLabel = FilterLabel;

	get hasAuthService(): boolean {
		return this._authService.hasAuthService;
	}

	constructor(
		private _touchendService: TouchendService,
		private _authService: AuthService,
		private _stationListService: StationListService,
		private _languageService: LanguageService,
		private _messageService: MessageService) { }

	/**
	 * @description set selected filter options
	 * @param {object} selectedOptions selected filter options
	 */
	setSelectedOptions(selectedOptions: object): void {
		this.selectedOptions = selectedOptions;

		for (const selectedOption in this.selectedOptions) {
			this.optionsAreSelected = false;
			if (this.selectedOptions[selectedOption].length) {
				this.optionsAreSelected = true;
				break;
			}
		}
	}

	/**
	 * @description set selected filter options
	 * @param {object} filter selected filter options
	 */
	setRemovedFilter(filter: object): void {
		this.removedFilter = filter;
	}

	/**
	 * @description set filters string
	 * @param {string} filtersString filters string
	 */
	setFiltersString(filtersString: string): void {
		this.filtersString = filtersString;
	}

	/**
	 * @description set current sort
	 * @param currentSort current sort
	 */
	setCurrentSort(currentSort: string): void {
		this.currentSort = currentSort;
	}

	/**
	 * @description get state color for a station
	 * @param stateValue state value
	 */
	getStationStateColor(stateValue: number): string {
		return StateColor[stateValue];
	}

	/**
	 * @description get the state legend image
	 * @param stateValue numeric value of a state
	 * @returns corresponding legend of the state
	 */
	getStationStateLegend(stateValue: number): string {
		return StateLegend[stateValue];
	}

	/**
	 * @description get the state label
	 * @param stateValue numeric value of a state
	 * @returns corresponding label of the state
	 */
	getStationState(stateValue: number): string {
    	return State[stateValue];
	}

  	/**
	 * @description get the trend label
	 * @param value trend
	 * @returns corresponding trend
	 */
	getStationTrend(value: number): string {
		if (value < 0) {
			return this._languageService.translate.instant("trend.down");
		} else if (value > 0) {
			return this._languageService.translate.instant("trend.up");
		} else {
			return this._languageService.translate.instant("trend.stable");;
		}
	}

	formatReading(reading: number): string {
		return reading.toFixed(2).replace(".", ",");
	}

	/**
	 * @description get date format
	 * @returns date format
	 */
	getDateFormat(): string {
		if (this.getLanguage().includes("fr")) {
			return "d MMMM y, H:mm";
		} else {
			return "MMMM d y, h:mm a";
		}
	}

	/**
	 * @description get browser language
	 * @returns language
	 */
	getLanguage(): string {
		// return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language
		return "fr";
	}

	/**
	 * @description construct a string from an array of selected options
	 * @param filter filter
	 * @param selectedOptionsValues array of selected options for a givien filter
	 * @returns a string of selected options for a given filter
	 */
	constructSelectedOptionsString(filter: string, selectedOptionsValues: string[] | number[] | boolean[]): string {
		if (filter === 'a_etat_max') {
			const selectedOptionsValuesState: string[] = [];

			for (const selectedOptionValues of selectedOptionsValues) {
				selectedOptionsValuesState.push(this._languageService.translate
					.instant(this.getStationState(Number(selectedOptionValues))));
			}
			return selectedOptionsValuesState.join(", ");
		} else if (filter === 'trend_pct') {
			const selectedOptionsValuesTrend: string[] = [];

			for (const selectedOptionValues of selectedOptionsValues) {
				selectedOptionsValuesTrend.push(this._languageService.translate
					.instant(Trend[Number(selectedOptionValues)]));
			}
			return selectedOptionsValuesTrend.join(", ");
		} else if (filter === 'prev') {
			if (selectedOptionsValues[0] === true) {
				return this._languageService.translate.instant("stationsFiltersList.stationsFilters.prevOnly");
			}
		} else {
			return selectedOptionsValues.join(", ");
		}
	}

	/**
	 * @description open report window and get station list
	 */
	openDialog(): void {
		let options: HttpOptions = {
			params: {
				order: this.currentSort
			}
		};

		if (this.filtersString !== "()" && this.filtersString !== undefined) {
			options.params["and"] = this.filtersString;
		}

		this._stationListService.getStationList(options).subscribe((stationList: StationForList[]) => {
			this.stationListReport = stationList;
			this.reportDateTime = new Date();
			this.reportWindowOpened = true;
		});
	}

	/**
	 * @description copy report table to clipboard
	 */
	copyTable(): void {
		const element: HTMLElement = document.getElementById('report-table');

		const range: Range = document.createRange();
		const selection: Selection = window.getSelection();
		selection.removeAllRanges();

		try {
			range.selectNodeContents(element);
			selection.addRange(range);
		} catch (e) {
			range.selectNode(element);
			selection.addRange(range);
		}

		document.execCommand("copy");
		selection.removeAllRanges();
		this._messageService.success(this._languageService.translate.instant("stationsFiltersList.report.copy.message"));
	}

	/**
	 * @description set style for printing report table and open printing tool
	 */
	printTable(): void {
		const title: HTMLElement = document.getElementById("report-title");
		const subtitle: HTMLElement = this.optionsAreSelected ? document.getElementById("report-subtitle") : undefined;
		const filters: HTMLElement = this.optionsAreSelected ? document.getElementById("report-filters") : undefined;
		const table: HTMLTableElement = document.getElementById("report-table") as HTMLTableElement;

		const newWindow: Window = window.open("");
		newWindow.document.write(title.outerHTML.replace('class="report-title', 'style=font-size:28px;line-height:32px;font-weight:bold;' +
			'text-align:center;margin-top:24px;margin-bottom:16px;font-family:"Roboto",sans-serif;color:#223654;display:block;width:100%'));
		if (this.optionsAreSelected) {
			newWindow.document.write(subtitle.outerHTML.replace('class="report-subtitle', 'style=margin-top:0;font-size:21px;' +
				'font-weight-bold;margin-bottom:8px;font-family:"Roboto",sans-serif;color:#223654;display:block;width:100%'));
			newWindow.document.write(filters.outerHTML.replaceAll(/class="filter .*?"/g, 'style=display:flex').replace(/class="filter-.*?"/g, 'style=margin:0;font-family:"Roboto",sans-serif;color:#223654;font-size:16px;line-height:24px'));
		}
		let tableOuterHTML: string = table.outerHTML.replace('class="table"', 'style=width:100%;border-spacing:0;padding-top:4px');
		tableOuterHTML = tableOuterHTML.replace('class="table-header"', 'style=background-color:#223654');
		tableOuterHTML = tableOuterHTML.replaceAll('class="table-header-header-cell"', 'style=font-family:"Roboto",sans-serif;' +
			'font-size:14px;line-height:1.5;padding:4px;text-align:center;vertical-align:middle;color:white;font-weight:bold;' +
			'border-bottom-width:2px;border-bottom-style:solid;border-bottom-color:#dee2e6');
		tableOuterHTML = tableOuterHTML.replaceAll('class="table-body-data-cell"', 'style=font-family:"Roboto",sans-serif;' +
			'font-size:14px;line-height:1.5;padding:4px;text-align:center;vertical-align:middle;border-bottom-width:1px;' +
			'border-bottom-style:solid;border-bottom-color:#dee2e6;color:#212529;font-weight:400');
		tableOuterHTML = tableOuterHTML.replaceAll('class="state-text"', 'style=margin:0');
		newWindow.document.write(tableOuterHTML);

		newWindow.print();
		newWindow.close();
	}
}
