import { Component, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
	selector: 'app-stations-list-paginator',
	templateUrl: './stations-list-paginator.component.html',
	styleUrls: ['./stations-list-paginator.component.scss']
})
export class StationsListPaginatorComponent implements OnInit, OnChanges, OnDestroy {
	@Input() numberOfStations: number; // number of stations
	@Input() numberOfStationsPerPage: number; // number of stations per page
	@Output() pageNumberChange: EventEmitter<number> = new EventEmitter(); // emitted when current page has changed

	public numberOfPages: number; // number of pages
	public pagesArray: number[]; // array of page numbers
	public currentPageIsFirst: boolean; // whether the current page is the first one or not
	public currentPageIsLast: boolean; // whether the current page is the last one or not

	public currentPage$: BehaviorSubject<number> = new BehaviorSubject(1); // current page
	public currentPage$$: Subscription; // subscription to current page

	public queryParamMap$$: Subscription; // subscription to query params

	constructor(private _router: Router, private _activatedRoute: ActivatedRoute) { }

	ngOnInit(): void {
		// subscription to query params
		this.queryParamMap$$ = this._activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
			this.currentPage$.next(Number(paramMap.get("page")) || 1);
		});

		// subscription to current page
		this.currentPage$$ = this.currentPage$.subscribe((currentPage: number) => {
			// set booleans accordingly
			if (currentPage === 1) {
				if(this.numberOfPages === 1) {
					this.currentPageIsFirst = true;
					this.currentPageIsLast = true;
				} else {
					this.currentPageIsFirst = true;
					this.currentPageIsLast = false;
				}
			} else if (currentPage === this.numberOfPages) {
				this.currentPageIsFirst = false;
				this.currentPageIsLast = true;
			} else {
				this.currentPageIsFirst = false;
				this.currentPageIsLast = false;
			}
			this._router.navigate([], {
				queryParams: {
					page: currentPage
				},
				queryParamsHandling: "merge",
			});
			this.pageNumberChange.emit(currentPage);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// if number of stations has changed...
		if (changes.numberOfStations) {
			// ...calculate new number of pages
			this._setCurrentNumberOfPages(changes.numberOfStations.currentValue, this.numberOfStationsPerPage);
		}

		// if number of stations per page has changed...
		if (changes.numberOfStationsPerPage) {
			// ...calculate new number of pages
			this._setCurrentNumberOfPages(this.numberOfStations, changes.numberOfStationsPerPage.currentValue);
		}
	}

	ngOnDestroy(): void {
		this.currentPage$$.unsubscribe();
		this.queryParamMap$$.unsubscribe();
	}

	/**
	 * @description go to previous page (substract 1 from current page)
	 */
	goToPreviousPage(): void {
		this._scrollToTopOfList();
		this.currentPage$.next(this.getCurrentPage() - 1);
	}

	/**
	 * @description go to a specific page
	 * @param event event fired when changing page
	 */
	goToPage(event: any): void {
		this._scrollToTopOfList();
		this.currentPage$.next(parseInt(event.target.innerText));
	}

	/**
	 * @description go to next page (add 1 to current page)
	 */
	goToNextPage(): void {
		this._scrollToTopOfList();
		this.currentPage$.next(this.getCurrentPage() + 1);
	}

	/**
	 * @description get the current page
	 * @returns current page
	 */
	getCurrentPage(): number {
		return this.currentPage$.getValue();
	}

	/**
	 * @description calculate number of pages and construct the corresponding array
	 * @param numberOfStations number of stations
	 * @param numberOfStationsPerPage number of stations per page
	 */
	private _setCurrentNumberOfPages(numberOfStations: number, numberOfStationsPerPage: number): void {
		this.numberOfPages = Math.ceil(numberOfStations / numberOfStationsPerPage);

		if (this.numberOfPages) {
			this.pagesArray = Array.from({length: this.numberOfPages}, (v,k) => k + 1);
			if (this.getCurrentPage() > this.numberOfPages) {
				this.currentPage$.next(1);
			}
		}
	}

	/**
	 * @description scroll to top of list when new page is selected
	 */
	private _scrollToTopOfList(): void {
		document.getElementById("list-scroll").scroll({
			top: 0,
			behavior: 'smooth'
		});
	}
}
