<div [formGroup]="form">
    <div class="filter-header">
        <p class="filter-label">{{ FilterLabel[filter] | translate }}</p>
        <p *ngIf="selectedCount > 0"
            class="filter-selected-count">{{ selectedCount === 1 ? selectedCount + " " + ('stationsFiltersList.stationsFilters.stationsFiltersFilter.selection' | translate) : selectedCount + " " + ('stationsFiltersList.stationsFilters.stationsFiltersFilter.selections' | translate) }}
        </p>
    </div>
    <mat-form-field class="filter-filter" appearance="outline">
        <mat-icon
            matSuffix
            [matTooltip]="!auto.isOpen ? ('stationsFiltersList.stationsFilters.stationsFiltersFilter.openPanel' | translate) : ('stationsFiltersList.stationsFilters.stationsFiltersFilter.closePanel' | translate)"
            color="primary"
            (click)="closeOpenPanel(auto.isOpen, $event)">{{ !auto.isOpen ? 'expand_more' : 'expand_less' }}
        </mat-icon>
        <input
            #panelTrigger="matAutocompleteTrigger"
            matInput
            type="text"
            [formControlName]="filter"
            [placeholder]="'stationsFiltersList.stationsFilters.stationsFiltersFilter.showFilters' | translate"
            matAutocompletePosition="below"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let filterOption of filterOptions$ | async"
                [ngClass]="filterOption.count === 0 ? 'disabled' : 'enabled'"
                [value]="filterOption"
                (click)="$event.stopPropagation(); toggleSelection(filter, filterOption)">
                <mat-checkbox
                    disableRipple="true"
                    [checked]="filterOption.selected"
                    (change)="toggleSelection(filter, filterOption)"
                    (click)="$event.stopPropagation()">
                    {{ filter !== "b_label" ? (filterOption.string | translate) + " (" + filterOption.count + ")" : filterOption.string }}
                </mat-checkbox>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<br>
<br>
