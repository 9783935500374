import { LegendButtonComponent, LegendButtonDialogComponent } from './legend-button.component';
import { IgoLanguageModule } from '../../../language/language.module';//'@igo2/core';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IgoMapModule, IgoLayerModule } from '@igo2/geo';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';

@NgModule({
  declarations: [LegendButtonComponent, LegendButtonDialogComponent],
  imports: [
    IgoLanguageModule,
    MatIconModule,
    MatDialogModule,
    IgoLayerModule,
    IgoMapModule,
    MatButtonModule,
    MatTooltipModule
  ],
  providers:
  [{
    provide: 'app-legend-button-dialog',
    useValue: LegendButtonDialogComponent,
},
      {provide:MatDialogRef , useValue:{} },

      { provide: MAT_DIALOG_DATA, useValue: {} }

],
  exports: [
    LegendButtonComponent,
    LegendButtonDialogComponent
  ],
  bootstrap: [LegendButtonComponent],
})
export class LegendButtonModule { }
