<div class="container">
	<div class="content">
		<ng-container *ngIf="!isMobile else mobileBreadcrumbs">
			<div class="breadcrumbs">
				<a class="breadcrumbs-element" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<p class="breadcrumbs-element-current">{{ 'about.title' | translate}}</p>
			</div>
		</ng-container>
		
		<ng-template #mobileBreadcrumbs>
			<div class="breadcrumbs">
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
				<a class="breadcrumbs-element-mobile" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
			</div>
		</ng-template>
	
		<div class="menu-about-map">
			<ng-container *ngIf="!isMobile else menuMobile">
				<div class="menu">
					<p class="menu-title">{{ 'about.menu.title' | translate }}</p>
					<p class="menu-selected">{{ 'about.menu.map' | translate }}</p>
					<a class="menu-option" routerLink="../stations">Stations</a>
					<div *ngIf="authenticated"
						[ngClass]="!trainingOptionsShown ? 'training-header' : 'training-header-no-border'"
						(click)="trainingOptionsShown = !trainingOptionsShown">
						<p class="training-header-title">{{ 'about.menu.training.title' | translate }}</p>
						<mat-icon color="primary">{{ !trainingOptionsShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="trainingOptionsShown"
						class="training-options">
						<a class="training-option" routerLink="../formations/module-1">{{ 'about.menu.training.module1' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-2">{{ 'about.menu.training.module2' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-3">{{ 'about.menu.training.module3' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-4">{{ 'about.menu.training.module4' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-5">{{ 'about.menu.training.module5' | translate }}</a>
					</div>
				</div>
			</ng-container>
			<ng-template #menuMobile>
				<div class="menu-mobile">
					<div class="menu-mobile-header"
						(click)="menuShown = !menuShown">
						<p class="menu-mobile-header-label">{{ 'about.menu.title' | translate }}</p>
						<mat-icon color="primary">{{ !menuShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="menuShown" class="menu-mobile-options">
						<p class="menu-mobile-option-selected">{{ 'about.menu.map' | translate }}</p>
						<a class="menu-mobile-option" routerLink="../stations">Stations</a>
						<div *ngIf="authenticated"
							class="training-header"
							(click)="trainingOptionsShown = !trainingOptionsShown">
							<p class="menu-mobile-option">{{ 'about.menu.training.title' | translate }}</p>
							<mat-icon color="primary">{{ !trainingOptionsShown ? 'expand_more' : 'expand_less' }}</mat-icon>
						</div>
						<div *ngIf="trainingOptionsShown"
							class="training-options">
							<a class="training-option" routerLink="../formations/module-1">{{ 'about.menu.training.module1' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-2">{{ 'about.menu.training.module2' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-3">{{ 'about.menu.training.module3' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-4">{{ 'about.menu.training.module4' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-5">{{ 'about.menu.training.module5' | translate }}</a>
						</div>

					</div>
				</div>
			</ng-template>
			<div class="about-map">
				<h1>{{ 'about.map.title' | translate }}</h1>
				<p [innerHtml]="'about.map.intro.part1' | translate"></p>
				<div>{{ 'about.map.intro.part2.intro' | translate }}</div>
				<ul>
					<li>{{ 'about.map.intro.part2.address' | translate }}</li>
					<li [innerHtml]="'about.map.intro.part2.location' | translate"></li>
					<li [innerHtml]="'about.map.intro.part2.coordinates' | translate"></li>
				</ul>
				<div class="page-content">
					<p class="page-content-title">{{ 'about.map.content.content' | translate }}</p>
					<a class="page-content-link"
						routerLink="./"
						fragment="legende">{{ 'about.map.content.legend' | translate }}
					</a>
					<a *ngIf="authenticated"
						class="page-content-link"
						routerLink="./"
						fragment="prevision">{{ 'about.map.content.forecast' | translate }}
					</a>
				</div>
				<div id="legende">
					<h2>{{ 'about.map.legend.title' | translate }}</h2>
					<p>{{ 'about.map.legend.intro' | translate }}</p>
					<div class="legend-stations">
						<h3>Stations</h3>
						<p>{{ 'about.map.legend.stations.part1' | translate }}
							<a class="link" routerLink="../stations">{{ 'about.map.legend.stations.part2' | translate }}</a>
							{{ 'about.map.legend.stations.part3' | translate }}
						</p>
						<img class="image" src="assets/images/about/legend-stations.png">
					</div>
					<div *ngIf="authenticated" class="legend-forecast">
						<h3>{{ 'about.map.legend.forecast.title' | translate }}</h3>
						<p>{{ 'about.map.legend.forecast.part1' | translate }}</p>
						<img class="image" src="assets/images/about/legend-index.png">
						<p>{{ 'about.map.legend.forecast.part2' | translate }}</p>
						<img class="image" src="assets/images/about/legend-forecast.png">
					</div>
				</div>
				<div *ngIf="authenticated" class="forecast" id="prevision">
					<h2>{{ 'about.map.forecast.title' | translate }}</h2>
					<p>{{ 'about.map.forecast.part1' | translate }}</p>
					<img class="image" src="assets/images/about/forecast-index.png">
					<p [innerHtml]="'about.map.forecast.part2' | translate"></p>
					<img class="image" src="assets/images/about/forecast.png">
					
					<p [innerHtml]="'about.map.forecast.part3' | translate"></p>
					<img class="image" src="assets/images/about/forecast-toggle.png">
					<p [innerHtml]="'about.map.forecast.part4' | translate"></p>
					<div>{{ 'about.map.forecast.part5.intro' | translate }}</div>
					<ul>
						<li>{{ 'about.map.forecast.part5.max' | translate }}</li>
						<li>{{ 'about.map.forecast.part5.bath' | translate }}</li>
						<li>{{ 'about.map.forecast.part5.models' | translate }}</li>
					</ul>
					<p>{{ 'about.map.forecast.part6' | translate }}</p>
					<p>{{ 'about.map.forecast.part7' | translate }}</p>
					<p>{{ 'about.map.forecast.part8' | translate }}</p>
					<h3>{{ 'about.map.forecast.scenario.title' | translate }}</h3>
					<p>{{ 'about.map.forecast.scenario.part1' | translate }}</p>
					<p>{{ 'about.map.forecast.scenario.part2' | translate }}</p>
				</div>
			</div>
		</div>
		<app-shared-footer class="footer"></app-shared-footer>
	</div>
</div>
