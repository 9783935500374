<div class="container">
	<div class="content">
		<ng-container *ngIf="!isMobile else mobileBreadcrumbs">
			<div class="breadcrumbs">
				<a class="breadcrumbs-element" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<p class="breadcrumbs-element-current">{{ 'about.title' | translate}}</p>
			</div>
		</ng-container>
		
		<ng-template #mobileBreadcrumbs>
			<div class="breadcrumbs">
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
				<a class="breadcrumbs-element-mobile" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
			</div>
		</ng-template>
	
		<div class="menu-about-map">
			<ng-container *ngIf="!isMobile else menuMobile">
				<div class="menu">
					<p class="menu-title">{{ 'about.menu.title' | translate }}</p>
					<a class="menu-option" routerLink="../carte">{{ 'about.menu.map' | translate }}</a>
					<p class="menu-selected">Stations</p>
					<div *ngIf="authenticated"
						[ngClass]="!trainingOptionsShown ? 'training-header' : 'training-header-no-border'"
						(click)="trainingOptionsShown = !trainingOptionsShown">
						<p class="training-header-title">{{ 'about.menu.training.title' | translate }}</p>
						<mat-icon color="primary">{{ !trainingOptionsShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="trainingOptionsShown"
						class="training-options">
						<a class="training-option" routerLink="../formations/module-1">{{ 'about.menu.training.module1' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-2">{{ 'about.menu.training.module2' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-3">{{ 'about.menu.training.module3' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-4">{{ 'about.menu.training.module4' | translate }}</a>
						<a class="training-option" routerLink="../formations/module-5">{{ 'about.menu.training.module5' | translate }}</a>
					</div>
				</div>
			</ng-container>
			<ng-template #menuMobile>
				<div class="menu-mobile">
					<div class="menu-mobile-header"
						(click)="menuShown = !menuShown">
						<p class="menu-mobile-header-label">{{ 'about.menu.title' | translate }}</p>
						<mat-icon color="primary">{{ !menuShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="menuShown" class="menu-mobile-options">
						<a class="menu-mobile-option" routerLink="../carte">{{ 'about.menu.map' | translate }}</a>
						<p class="menu-mobile-option-selected">Stations</p>
						<div *ngIf="authenticated"
							class="training-header"
							(click)="trainingOptionsShown = !trainingOptionsShown">
							<p class="menu-mobile-option">{{ 'about.menu.training.title' | translate }}</p>
							<mat-icon color="primary">{{ !trainingOptionsShown ? 'expand_more' : 'expand_less' }}</mat-icon>
						</div>
						<div *ngIf="trainingOptionsShown"
							class="training-options">
							<a class="training-option" routerLink="../formations/module-1">{{ 'about.menu.training.module1' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-2">{{ 'about.menu.training.module2' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-3">{{ 'about.menu.training.module3' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-4">{{ 'about.menu.training.module4' | translate }}</a>
							<a class="training-option" routerLink="../formations/module-5">{{ 'about.menu.training.module5' | translate }}</a>
						</div>
					</div>
				</div>
			</ng-template>
			<div class="about-map">
				<h1>{{ 'about.stations.title' | translate }}</h1>
				<p>{{ 'about.stations.intro.part1' | translate }}</p>
				<p>{{ 'about.stations.intro.part2' | translate }}</p>
				<p>{{ 'about.stations.intro.part3' | translate }}</p>
				<div class="page-content">
					<p class="page-content-title">{{ 'about.stations.content.content' | translate }}</p>
					<a class="page-content-link"
						routerLink="./"
						fragment="details">{{ 'about.stations.content.details' | translate }}
					</a>
					<a class="page-content-link"
						routerLink="./"
						fragment="etat">{{ 'about.stations.content.state' | translate }}
					</a>
					<a class="page-content-link"
						routerLink="./"
						fragment="hydrogramme">{{ 'about.stations.content.hydrograph' | translate }}
					</a>
					<a class="page-content-link"
						routerLink="./"
						fragment="sources">{{ 'about.stations.content.sources' | translate }}
					</a>
				</div>
				<div id="details">
					<h2>{{ 'about.stations.details.title' | translate }}</h2>
					<p>{{ 'about.stations.details.intro' | translate }}</p>
					
					<div class="details-flow">
						<h3>{{ 'about.stations.details.flow.title' | translate }}</h3>
						<p>{{ 'about.stations.details.flow.description' | translate }}</p>
					</div>
					<div class="details-level">
						<h3>{{ 'about.stations.details.level.title' | translate }}</h3>
						<p [innerHtml]="'about.stations.details.level.description' | translate"></p>
					</div>
					<div class="details-hydrograph">
						<h3>{{ 'about.stations.details.state.title' | translate }}</h3>
						<p>{{ 'about.stations.details.state.description' | translate }}</p>
					</div>
				</div>
				<div id="etat">
					<h2>{{ 'about.stations.state.title' | translate }}</h2>
					<p>{{ 'about.stations.state.intro' | translate }}</p>
					<table>
						<tr>
							<th>{{ 'about.stations.state.tableHeader.state' | translate }}</th>
							<th>{{ 'about.stations.state.tableHeader.effects' | translate }}</th>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/inondation-majeure.svg" width="15" height="15">
									{{ 'about.stations.state.major.state' | translate }}
								</p>
							</td>
							<td>
								<div>{{ 'about.stations.state.major.description.intro' | translate }}</div>
								<ul>
									<li>{{ 'about.stations.state.major.description.inhabitedAreas' | translate }}</li>
									<li>{{ 'about.stations.state.major.description.largeAreas' | translate }}</li>
									<li>{{ 'about.stations.state.major.description.roads' | translate }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/inondation-moyenne.svg" width="15" height="15">
									{{ 'about.stations.state.medium.state' | translate }}
								</p>
							</td>
							<td>
								<div>{{ 'about.stations.state.medium.description.intro' | translate }}</div>
								<ul>
									<li>{{ 'about.stations.state.medium.description.inhabitedAreas' | translate }}</li>
									<li>{{ 'about.stations.state.medium.description.sewers' | translate }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/inondation-mineure.svg" width="15" height="15">
									{{ 'about.stations.state.minor.state' | translate }}
								</p>
							</td>
							<td>
								<div>{{ 'about.stations.state.minor.description.intro' | translate }}</div>
								<ul>
									<li>{{ 'about.stations.state.minor.description.roads' | translate }}</li>
									<li>{{ 'about.stations.state.minor.description.inhabitedAreas' | translate }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/surveillance.svg" width="15" height="15">
									{{ 'about.stations.state.watch.state' | translate }}
								</p>
							</td>
							<td>
								<p>{{ 'about.stations.state.watch.description' | translate }}</p>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/etat-normal.svg" width="15" height="15">
									{{ 'about.stations.state.normal.state' | translate }}
								</p>
							</td>
							<td>
								<p>{{ 'about.stations.state.normal.description' | translate }}</p>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/etat-inconnu.svg" width="15" height="15">
									{{ 'about.stations.state.unknown.state' | translate }}
								</p>
							</td>
							<td>
								<p>{{ 'about.stations.state.unknown.description' | translate }}</p>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/desactivee.svg" width="15" height="15">
									{{ 'about.stations.state.deactivated.state' | translate }}
								</p>
							</td>
							<td>
								<p>{{ 'about.stations.state.deactivated.description' | translate }}</p>
							</td>
						</tr>
						<tr>
							<td class="state-column">
								<p>
									<img src="assets/legends/legend-stations/glace.svg" width="15" height="15">
									{{ 'about.stations.state.ice.state' | translate }}
								</p>
							</td>
							<td>
								<p>{{ 'about.stations.state.ice.description' | translate }}</p>
							</td>
						</tr>
					</table>
					<p>{{ 'about.stations.state.warning' | translate }}</p>
				</div>

				<div id="hydrogramme">
					<h2>{{ 'about.stations.hydrograph.title' | translate }}</h2>
					<p>{{ 'about.stations.hydrograph.intro.part1' | translate }}</p>
					<img src="assets/images/about/forecast-hydrograph.png" width="100%">
					<p>{{ 'about.stations.hydrograph.intro.part2' | translate }}</p>
					<p>{{ 'about.stations.hydrograph.intro.part3' | translate }}</p>
					<div class="hydrograph-forecast">
						<h3>{{ 'about.stations.hydrograph.forecast.title' | translate }}</h3>
						<p>{{ 'about.stations.hydrograph.forecast.part1' | translate }}</p>
						<div>{{ 'about.stations.hydrograph.forecast.part2.intro' | translate }}</div>
						<ul>
							<li>{{ 'about.stations.hydrograph.forecast.part2.data' | translate }}</li>
							<li>{{ 'about.stations.hydrograph.forecast.part2.weather' | translate }}</li>
							<li>{{ 'about.stations.hydrograph.forecast.part2.models' | translate }}</li>
							<li>{{ 'about.stations.hydrograph.forecast.part2.hydrologist' | translate }}</li>
						</ul>
						<p>{{ 'about.stations.hydrograph.forecast.part3' | translate }}</p>
					</div>
					<div class="hydrograph-uncertainty">
						<h3>{{ 'about.stations.hydrograph.uncertainty.title' | translate }}</h3>
						<p>{{ 'about.stations.hydrograph.uncertainty.part1' | translate }}</p>
						<p>{{ 'about.stations.hydrograph.uncertainty.part2' | translate }}</p>
					</div>
				</div>
				<div class="sources" id="sources">
					<h2>{{ 'about.stations.sources.title' | translate }}</h2>
					<p>{{ 'about.stations.sources.intro' | translate }}</p>
					<a
                		class="link"
                		href="https://www.cehq.gouv.qc.ca/"
                		target="_blank">Ministère de l'Environnement et de la Lutte contre les changements climatiques, de la Faune et des Parcs
                		<mat-icon class="link-icon">open_in_new</mat-icon>
            		</a>
					<a
                		class="link"
                		href="https://www.canada.ca/fr/environnement-changement-climatique.html"
                		target="_blank">Environnement et Changement climatique Canada
                		<mat-icon class="link-icon">open_in_new</mat-icon>
            		</a>
					<a
                		class="link"
                		href="https://www.dfo-mpo.gc.ca/index-fra.html"
                		target="_blank">Pêches et Océans Canada
                		<mat-icon class="link-icon">open_in_new</mat-icon>
            		</a>
					<a
                		class="link"
                		href="http://www.ssrc.cobaric.qc.ca/publique_index.php"
                		target="_blank">Système de surveillance de la rivière Chaudière
                		<mat-icon class="link-icon">open_in_new</mat-icon>
            		</a>
					<a
                		class="link"
                		href="https://www.hydrometeo.net/"
                		target="_blank">Hydro Météo
                		<mat-icon class="link-icon">open_in_new</mat-icon>
            		</a>
					<ng-container *ngIf="authenticated">
						<a
                			class="link"
                			href="https://www.hydroquebec.com/production/debits-niveaux-eau.html"
                			target="_blank">Hydro-Québec
                			<mat-icon class="link-icon">open_in_new</mat-icon>
            			</a>
						<a
                			class="link"
                			href="https://montreal.ca/"
                			target="_blank">Ville de Montréal
                			<mat-icon class="link-icon">open_in_new</mat-icon>
            			</a>
					</ng-container>
					<a
                		class="link"
                		href="https://www.usgs.gov/"
                		target="_blank">United States Geological Survey (USGS)
                		<mat-icon class="link-icon">open_in_new</mat-icon>
            		</a>
				</div>
			</div>
		</div>
		<app-shared-footer class="footer"></app-shared-footer>
	</div>
</div>
