<mat-sidenav-container>
  <mat-sidenav-content position="end">

    <igo-backdrop
      [shown]="false"
      (click)="onBackdropClick()">
    </igo-backdrop>

    <app-layer-toggle
      [ngClass]="showLayerToggle ? (mobile ? 'layer-toggle layer-toggle-mobile' : 'layer-toggle layer-toggle-desktop') : 'layer-toggle-hidden'"
      [zoom]="zoom"
      [minZoom]="minZoom"
      [map]="map"
      (scenarioSelected)="toggleScenarioSelected($event)"
      >
    </app-layer-toggle>

    <div id="searchDesktop" *ngIf=!mobile>

      <igo-search-bar *ngIf="showSearchBar"
        #searchbar
        (pointerSummaryStatus)="onPointerSummaryStatusChange($event)"
        appearance="outline"
        searchIcon ="magnify"
        [matTooltip]="'searchBar' | translate"
        matTooltipShowDelay="500"
        tooltip-position="after"
        [searchSettings]="false"
        color="primary"
        [forceNA]="forceCoordsNA"
        [term]="searchBarTerm"
        [store]="searchStore"
        [termSplitter]="termSplitter"
        (searchTermChange)="onSearchTermChange($event)"
        (search)="onSearch($event)"
        (clearFeature)="onClearSearch()"
        (searchSettingsChange)="onSearchSettingsChange()">
      </igo-search-bar>

      <div *ngIf="!mobile" id="scaleLine"></div>

      <app-sideresult
        #sideresultsDesktop
        class="sideresult"
        [map]="map"
        [mobile]="mobile"
        (clearFeature)="unselectResult()"
        [sidenavOpened]="panelOpenState"
        [(opened)]="panelOpenState"
        [searchInit]="searchInit"
        [store]="queryStore"
        [legendPanelOpened]="legendPanelOpened"
        [mapQueryClick]="mapQueryClick"
        [panelOpenState]="panelOpenState"
        (toggleLegend)="togglePanelLegend()"
        (closeLegend)="closePanelLegend()"
        (closeQuery)="closePanelOnCloseQuery()"
        (openLegend)="openPanelLegend()"
        [layers]="mapLayersShownInLegend"
        [scenarioDateToggle]="scenarioDateToggle">
      </app-sideresult>
    </div>

    <igo-map-browser
      #mapBrowser
      igoMapContext
      igoLayerContext
      igoDropGeoFile
      igoHoverFeature
      [igoHoverFeatureEnabled]="true"
      igoQuery
      [queryFeatures]="true"
      (query)="onMapQuery($event)"
      [igoHoverFeatureDelay]=10
      [map]="map"
      [ngClass]="{'igo-attribution-offset': map.getBaseLayers().length > 1, 'map-hasfooter': hasFooter}">

        <igo-baselayers-switcher
          [ngClass]="mobile? ['igo-baselayers-switcher-mobile'] : (panelOpenState? ['baselayers-pushed'] : ['baselayers'])"
          [map]="map"
          [useStaticIcon]="getBaseLayersUseStaticIcon()">
        </igo-baselayers-switcher>

        <div id="map-buttons"
        [ngClass]="mobile? ['map-buttons-mobile'] : ['map-buttons']">
          <igo-geolocate-button *ngIf="hasGeolocateButton" [map]="map" color="primary"></igo-geolocate-button>
          <igo-zoom-button [map]="map" color="primary"></igo-zoom-button>
          <igo-rotation-button [showIfNoRotation]="showRotationButtonIfNoRotation" [map]="map" color="primary"></igo-rotation-button>
          <app-legend-panel-button *ngIf="hasLegendButton && legendInPanel === true" (toggleLegend)="togglePanelLegend()" [legendPanelOpened]="legendPanelOpened" ></app-legend-panel-button>
          <app-legend-button *ngIf="hasLegendButton && legendInPanel === false"></app-legend-button>
        </div>

        <app-bottomresult
          *ngIf="mobile"
          [mobile]="mobile"
          [store]="searchStore"
          [searchInit]="searchInit"
          [mapQueryClick]="mapQueryClick"
          [legendPanelOpened]="legendPanelOpened"
          [panelOpenState]="panelOpenState"
          [expanded]="expanded"
          (closeQuery)="closePanelOnCloseQuery()"
          (closeLegend)="closePanelLegend()"
          (openLegend)="openPanelLegend()"
          (toggleLegend)="togglePanelLegend()"
          [layers]="mapLayersShownInLegend"
          [scenarioDateToggle]="scenarioDateToggle">
        </app-bottomresult>
    </igo-map-browser>

    <app-footer *ngIf="hasFooter && !mobile" [mobile]="mobile"></app-footer>

    <app-map-overlay
    [@controlStateY]="getControlsOffsetY()"
    [@controlStateX]="(mediaService.media$ | async) !== 'mobile' &&
                            sidenavOpened ? 'right' : 'left'">
    </app-map-overlay>

  </mat-sidenav-content>


</mat-sidenav-container>
