<div class="container">
    <div class="content">
        <h1>{{ 'home.title' | translate }}</h1>
        <div *ngIf="authenticated" class="info-container">
            <div class="info">
                <div class="info-left">
                    <mat-icon class="info-icon" svgIcon="information-outline"></mat-icon>
                </div>
                <div class="info-right">
                    <p class="info-text"><b>{{ 'home.info.title' | translate }}</b></p>
                    <p class="info-text" [innerHtml]="'home.info.message' | translate"></p>
                </div>
            </div>
        </div>
        <div class="tiles-container">
            <div class="tiles">
                <a class="tile-link" routerLink="/carte">
                    <app-home-tile class="tile"
                        icon="assets/icons/home-map.svg"
                        [title]="'home.tiles.map.title' | translate"
                        [description]="'home.tiles.map.description' | translate">
                    </app-home-tile>
                </a>
                <a class="tile-link" routerLink="/stations">
                    <app-home-tile class="tile"
                        icon="assets/icons/home-stations.svg"
                        [title]="'home.tiles.stations.title' | translate"
                        [description]="'home.tiles.stations.description' | translate">
                    </app-home-tile>
                </a>
                <a class="tile-link" href="https://www.quebec.ca/securite-situations-urgence/urgences-sinistres-risques-naturels/inondation" target="_blank">
                <app-home-tile class="tile"
                    icon="assets/icons/home-more.svg"
                    [title]="'home.tiles.more.title' | translate"
                    [description]="'home.tiles.more.description' | translate">
                </app-home-tile>
                </a>
            </div>
        </div>
    
        <div class="situation">
            <h2>{{ 'home.situation.situation' | translate }}</h2>
                <a class="situation-stats" routerLink="/stations">
                    <ng-container *ngFor="let count of stationCounts; let length = count; let index = index">
                            <div *ngIf="length - index - 2 !== -1 && length - index - 2 !== 0 && length - index - 2 !== 2" class="situation-state">
                                <div class="situation-state-state">
                                    <img [src]="getStationStateLegend(length - index - 2)" width="20" height="20">
                                    <p class="situation-state-state-title">{{ getStationState(length - index - 2) | translate }}</p>
                                </div>
                                <p class="situation-state-state-count">{{ count }}</p>
                            </div>
                    </ng-container>
                </a>
        </div>    
    
        <div class="about">
            <h2>{{ 'home.about.about' | translate }}</h2>
            <p>{{ 'home.about.part1' | translate }}</p>
            <p>
                {{ 'home.about.part2' | translate }}
                <a class="more-link" href="https://www.quebec.ca/gouvernement/politiques-orientations/plan-de-protection-du-territoire-face-aux-inondations" target="_blank">{{ 'home.about.link' | translate }}
                    <mat-icon class="arrow">open_in_new</mat-icon>
                </a>
                {{ 'home.about.part3' | translate }}
            </p>
        </div>
    
        <div class="more">
            <h2>{{'home.also.also' | translate}}</h2>
            <ng-container *ngIf="authenticated">
                <a class="more-link" href="https://www.quebec.ca/securite-situations-urgence/securite-civile/soutien-municipalites/preparation-sinistres" target="_blank">Préparation municipale aux sinistres
                    <mat-icon class="arrow">open_in_new</mat-icon>
                </a>
                <a class="more-link" href="https://www.quebec.ca/securite-situations-urgence/securite-civile/soutien-municipalites/preparation-sinistres/generale/guide-reponse-sinistres" target="_blank">Guide Préparer la réponse aux sinistres
                    <mat-icon class="arrow">open_in_new</mat-icon>
                </a>
            </ng-container>
            <a class="more-link" href="https://rivieredesoutaouais.ca/" target="_blank">Commission de planification de la régularisation de la rivière des Outaouais
                <mat-icon class="arrow">open_in_new</mat-icon>
            </a>
            <a class="more-link" href="https://www.ijc.org/fr/clofsl" target="_blank">Conseil international du lac Ontario et du fleuve Saint-Laurent
                <mat-icon class="arrow">open_in_new</mat-icon>
            </a>
            <a class="more-link" href="https://www.cehq.gouv.qc.ca/prevision/previsions.asp?Zone=Temiscamingue&Secteur=Archipel" target="_blank">Prévisions hydrologiques et hydrauliques de l'Archipel de Montréal
                <mat-icon class="arrow">open_in_new</mat-icon>
            </a>
            <a class="more-link" href="https://www.cruesgrandmontreal.ca/" target="_blank">Crues Grand Montréal
                <mat-icon class="arrow">open_in_new</mat-icon>
            </a>
        </div>
    
        <app-shared-footer class="footer"></app-shared-footer>
    </div>
</div>
