<button *ngIf="!currentPageIsFirst"
    class="navigation-button"
    mat-icon-button
    color="primary"
    disableRipple="true"
    [matTooltip]="'stationsFiltersList.stationsList.stationsListPaginator.previousPage' | translate"
    tooltip-position="below"
    (click)="goToPreviousPage()">
    <mat-icon svgIcon="chevron-left"></mat-icon>
</button>

<ng-container *ngIf="numberOfPages > 6 else lessThan6Pages">
	<ng-container *ngIf="(currentPage$ | async) >= 5">
		<span
			class="page-button"
			mat-button
			color="primary"
			(click)="goToPage($event)">
			1
		</span>
		<span class="more">
			...
		</span>
	</ng-container>
	
	<ng-container *ngIf="(currentPage$ | async) < 5">
		<span *ngFor="let page of pagesArray.slice(0,5)"
			[ngClass]="page === getCurrentPage() ? 'page-button-bold' : 'page-button'"
			mat-button
			color="primary"
			(click)="goToPage($event)">
			{{ page }}
		</span>
	</ng-container>
	
	<ng-container *ngIf="(currentPage$ | async) >= 5 && (currentPage$ | async) <= numberOfPages - 4">
		<span *ngFor="let page of pagesArray.slice(getCurrentPage() - 3, getCurrentPage() + 2)"
			[ngClass]="page === getCurrentPage() ? 'page-button-bold' : 'page-button'"
			mat-button
			color="primary"
			(click)="goToPage($event)">
			{{ page }}
		</span>
	</ng-container>
	
	<ng-container *ngIf="(currentPage$ | async) > numberOfPages - 4">
		<span *ngFor="let page of pagesArray.slice(numberOfPages - 5)"
			[ngClass]="page === getCurrentPage() ? 'page-button-bold' : 'page-button'"
			mat-button
			color="primary"
			(click)="goToPage($event)">
			{{ page }}
		</span>
	</ng-container>
	
	<ng-container *ngIf="(currentPage$ | async) <= numberOfPages - 4">
		<span class="more">
			...
		</span>
		<span
			[ngClass]="numberOfPages === getCurrentPage() ? 'page-button-bold' : 'page-button'"
			mat-button
			color="primary"
			(click)="goToPage($event)">
			{{ numberOfPages }}
		</span>
	</ng-container>
</ng-container>

<ng-template #lessThan6Pages>
	<p *ngFor="let page of pagesArray"
		[ngClass]="page === getCurrentPage() ? 'page-button-bold' : 'page-button'"
		mat-button
		color="primary"
		(click)="goToPage($event)">
		{{ page }}
	</p>
</ng-template>

<button *ngIf="!currentPageIsLast"
    class="navigation-button"
    mat-icon-button
    color="primary"
    disableRipple="true"
    [disabled]="currentPageIsLast"
    [matTooltip]="'stationsFiltersList.stationsList.stationsListPaginator.nextPage' | translate"
    tooltip-position="below"
    (click)="goToNextPage()">
    <mat-icon svgIcon="chevron-right"></mat-icon>
</button>
