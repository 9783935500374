import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedFooterComponent } from './shared-footer/shared-footer.component';
import { SharedSequentialLinksComponent } from './sequential-links/shared-sequential-links.component';

@NgModule({
  declarations: [SharedFooterComponent],
  imports: [CommonModule, SharedSequentialLinksComponent],
  exports: [SharedFooterComponent, SharedSequentialLinksComponent],
})

export class SharedComponentsModule { }
