<div class="container">
	<div class="content">
		<ng-container *ngIf="!isMobile else mobileBreadcrumbs">
			<div class="breadcrumbs">
				<a class="breadcrumbs-element" routerLink="/">{{ 'breadcrumbs.home' | translate }}</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<a class="breadcrumbs-element" routerLink="/stations" [queryParams]="getQueryParams()">Stations</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<p class="breadcrumbs-element-current">Station {{ stationDetailsMetadata?.label }}</p>
			</div>
		</ng-container>
		
		<ng-template #mobileBreadcrumbs>
			<div class="breadcrumbs">
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
				<a class="breadcrumbs-element-mobile" routerLink="/stations" [queryParams]="getQueryParams()">Stations</a>
			</div>
		</ng-template>
		<ng-container *ngIf="dataPresent else dataNotPresent">
			<ng-container *ngIf="!isMobile else mobile">
				<h1>Station {{ stationDetailsMetadata?.label }}</h1>
				
				<div class="metadata-alerts">
					<div class="station-metadata">
						<div class="attributes">
							<div class="attribute">
								<p class="attribute-label">{{ 'stationsDetails.location' | translate }} :&nbsp;</p>
								<p class="attribute-value">{{ stationDetailsMetadata?.description ? stationDetailsMetadata.description : 'stationsDetails.na' | translate }}</p>
							</div>
							<div class="attribute">
								<p class="attribute-label">{{ 'stationsDetails.body' | translate }} :&nbsp;</p>
								<p class="attribute-value">{{ stationDetailsMetadata?.plan_deau ? stationDetailsMetadata.plan_deau : 'stationsDetails.na' | translate }}</p>
							</div>
							<div class="attribute">
								<p class="attribute-label">{{ 'stationsDetails.municipality' | translate }} :&nbsp;</p>
								<p class="attribute-value">{{ stationDetailsMetadata?.mun ? stationDetailsMetadata.mun : 'stationsDetails.na' | translate }}</p>
							</div>
							<div class="attribute">
								<p class="attribute-label">{{ 'stationsDetails.region' | translate }} :&nbsp;</p>
								<p class="attribute-value">{{ stationDetailsMetadata?.regadmin ? stationDetailsMetadata.regadmin : 'stationsDetails.na' | translate }}</p>
							</div>
							<div class="attribute">
								<p class="attribute-label">Source :&nbsp;</p>
								<a class="link"
									target="_blank"
									[matTooltip]="'stationsDetails.source' | translate"
									[href]="getUrl()">{{ stationDetailsMetadata?.fournisseur_nom ? stationDetailsMetadata.fournisseur_nom : 'stationsDetails.na' | translate }}
									<mat-icon class="link-icon">open_in_new</mat-icon>
								</a>
							</div>
						</div>
					</div>
					<div class="right-links">
						<div
							class="button-link"
							(click)="goToMap()">
							<mat-icon class="button-link-icon">location_on</mat-icon>
							<p class="button-link-text">{{ 'stationsDetails.seeMap' | translate }}</p>
						</div>
						<div *ngIf="hasAuthService && authenticated"
							class="button-link"
							(click)="modifyAlerts()">
							<mat-icon class="button-link-icon">notifications</mat-icon>
							<p class="button-link-text">{{ 'stationsDetails.subscription' | translate }}</p>
						</div>
						<a class='link' href="https://www.quebec.ca/securite-situations-urgence/urgences-sinistres-risques-naturels/inondation" target="_blank">{{ 'home.tiles.more.title' | translate }}
							<mat-icon class="link-icon">open_in_new</mat-icon>
						</a>

					</div>
				</div>

					<div *ngIf="stationDetailsMetadata && stationDetailsMetadata.etat_max === null" class="warning">
						<div class="warning-left">
							<mat-icon class="warning-icon" svgIcon="alert-outline"></mat-icon>
						</div>
						<div class="warning-right">
							<p class="warning-title">{{ 'stationsDetails.warning.title' | translate}}</p>
							<p class="warning-text">
								{{ 'stationsDetails.warning.text.part1' | translate }}
								<a [href]="getUrl()" target= "_blank">{{ 'stationsDetails.warning.text.part2' | translate }}
									<mat-icon class="link-icon">open_in_new</mat-icon>
								</a>
								{{ 'stationsDetails.warning.text.part3' | translate }}
							</p>
						</div>
					</div>
	
					<div>
						<div>
							<h2>{{ 'stationsDetails.readings' | translate }}</h2>
							<div class="attribute">
								<p class="attribute-label">{{ 'stationsDetails.update' | translate }} :&nbsp;</p>
								<p class="attribute-value">{{ dateIsValid(stationDetailsMetadata?.date_prise_valeur) ? (stationDetailsMetadata.date_prise_valeur | date:getDateFormat()) : 'stationsDetails.na' | translate }}</p>
							</div>
						</div>
	
						<div class="flow-state-height-trend">
							<div class="flow-state">
								<div>
									<p class="reading-label">{{ 'stationsDetails.flow' | translate }}</p>
									<div class="reading-line">
										<p class="reading">{{ stationDetailsMetadata?.dern_valeur_deb && stationDetailsMetadata?.etat_max !== null ? (stationDetailsMetadata.dern_valeur_deb | formatReading) + ' m³/s' : 'stationsDetails.na' | translate }}</p>
										<p *ngIf="stationDetailsMetadata?.refoule_deb" class="reading-note">*</p>
									</div>
									<p *ngIf="stationDetailsMetadata?.refoule_deb" class="attribute-label attribute-label-mobile">* {{ 'stationsDetails.note' | translate }}</p>
									<div *ngIf="stationDetailsMetadata?.seuils_deb" class="thresholds-clickable"
										(click)="flowThresholdsShown = !flowThresholdsShown">
										<p class="link">{{ flowThresholdsShown ? ('stationsDetails.thresholds.hide' | translate) : ('stationsDetails.thresholds.show' | translate) }}</p>
										<button mat-icon-button disableRipple>
											<mat-icon
												[svgIcon]="flowThresholdsShown ? 'chevron-up' : 'chevron-down'"
												color="primary">
											</mat-icon>
										</button>
									</div>
																	
									<div *ngIf="flowThresholdsShown" class="thresholds">
										<div *ngFor="let seuil of stationDetailsMetadata.seuils_deb" class="attribute">
											<p class="attribute-label">{{ ThresholdFlow[seuil.type] | translate }} :&nbsp;</p>
											<p class="attribute-value">{{ seuil.valeur | formatReading }} m³/s</p>
										</div>
									</div>
								</div>
				
								<div>
									<p class="reading-label">{{ 'stationsDetails.state.state' | translate }}</p>
									<div class="state-value">
										<img class="state-trend-img" [src]="getStationStateLegend(stationDetailsMetadata?.etat_max)" width="20" height="20">
										<p class="reading">{{ getStationState(stationDetailsMetadata?.etat_max) | translate }}</p>
										<div
											class="info-container"
											#tooltipState="matTooltip"
											[matTooltip]="getStationStateDescription(stationDetailsMetadata?.etat_max) | translate"
											[matTooltipPosition]="isMobile ? 'above' : 'right'"
											[matTooltipClass]="isMobile ? 'tooltip-above' : 'tooltip-right'"
											matTooltipShowDelay="100"
                    						matTooltipTouchGestures="auto">
											<mat-icon
												class="info"
												color="primary"
												svgIcon="information">
      										</mat-icon>
										</div>
									</div>
								</div>
							</div>
				
							<div class="height-trend">
								<div>
									<p class="reading-label">{{ 'stationsDetails.level' | translate }}</p>
									<div>
										<p class="reading">{{ stationDetailsMetadata?.dern_valeur_niv && stationDetailsMetadata?.etat_max !== null ? (stationDetailsMetadata.dern_valeur_niv | formatReading) + ' m' : 'stationsDetails.na' | translate }}</p>
									</div>
									<div *ngIf="stationDetailsMetadata?.seuils_niv" class="thresholds-clickable"
										(click)="heightThresholdsShown = !heightThresholdsShown">
										<p class="link">{{ heightThresholdsShown ? ('stationsDetails.thresholds.hide' | translate) : ('stationsDetails.thresholds.show' | translate) }}</p>
										<button mat-icon-button disableRipple>
											<mat-icon
												[svgIcon]="heightThresholdsShown ? 'chevron-up' : 'chevron-down'"
												color="primary">
											</mat-icon>
										</button>
									</div>
																	
									<div *ngIf="heightThresholdsShown" class="thresholds">
										<div *ngFor="let seuil of stationDetailsMetadata.seuils_niv" class="attribute">
											<p class="attribute-label">{{ ThresholdHeight[seuil.type] | translate }} :&nbsp;</p>
											<p class="attribute-value">{{ seuil.valeur | formatReading }} m</p>
										</div>
									</div>
								</div>
				
								<div>
									<p class="reading-label">{{ 'trend.trend' | translate }}</p>
									<div class="trend-value">
										<p class="reading">
											{{ stationDetailsMetadata?.trend_pct !== null && stationDetailsMetadata?.etat_max !== null ? (stationDetailsMetadata?.trend_pct | getStationTrend) : 'stationsDetails.na' | translate }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
			</ng-container>
	
			<ng-template #mobile>
				<h1>Station {{ stationDetailsMetadata?.label }}</h1>
	
				<div>
					<div class="station-metadata-mobile">
						<div class="attribute">
							<p class="attribute-label">{{ 'stationsDetails.location' | translate }} :&nbsp;</p>
							<p class="attribute-value">{{ stationDetailsMetadata?.description ? stationDetailsMetadata.description : 'stationsDetails.na' | translate }}</p>
						</div>
						<div class="attribute">
							<p class="attribute-label">{{ 'stationsDetails.body' | translate }} :&nbsp;</p>
							<p class="attribute-value">{{ stationDetailsMetadata?.plan_deau ? stationDetailsMetadata.plan_deau : 'stationsDetails.na' | translate }}</p>
						</div>
						<div class="attribute">
							<p class="attribute-label">{{ 'stationsDetails.municipality' | translate }} :&nbsp;</p>
							<p class="attribute-value">{{ stationDetailsMetadata?.mun ? stationDetailsMetadata.mun : 'stationsDetails.na' | translate }}</p>
						</div>
						<div class="attribute">
							<p class="attribute-label">{{ 'stationsDetails.region' | translate }} :&nbsp;</p>
							<p class="attribute-value">{{ stationDetailsMetadata?.regadmin ? stationDetailsMetadata.regadmin : 'stationsDetails.na' | translate }}</p>
						</div>
						<div class="attribute">
							<p class="attribute-label">Source :&nbsp;</p>
							<a class="link"
								target="_blank"
								[matTooltip]="'stationsDetails.source' | translate"
								[href]="getUrl()">{{ stationDetailsMetadata?.fournisseur_nom ? stationDetailsMetadata.fournisseur_nom : 'stationsDetails.na' | translate }}
								<mat-icon class="link-icon">open_in_new</mat-icon>
							</a>
						</div>
						<div class="map-subscription">
							<div
								class="button-link"
								(click)="goToMap()">
								<mat-icon class="button-link-icon">location_on</mat-icon>
								<p class="button-link-text">{{ 'stationsDetails.seeMap' | translate }}</p>
							</div>
							<div *ngIf="hasAuthService && authenticated"
								class="button-link"
								(click)="modifyAlerts()">
								<mat-icon class="button-link-icon">notifications</mat-icon>
								<p class="button-link-text">{{ 'stationsDetails.subscription' | translate }}</p>
							</div>
							<a class='link' href="https://www.quebec.ca/securite-situations-urgence/urgences-sinistres-risques-naturels/inondation" target="_blank">{{ 'home.tiles.more.title' | translate }}
								<mat-icon class="link-icon">open_in_new</mat-icon>
							</a>
						</div>
					</div>

					<div *ngIf="stationDetailsMetadata && stationDetailsMetadata?.etat_max === null" class="warning">
						<div class="warning-left">
							<mat-icon class="warning-icon" svgIcon="alert-outline"></mat-icon>
						</div>
						<div class="warning-right">
							<p class="warning-title">{{ 'stationsDetails.warning.title' | translate}}</p>
							<p class="warning-text">
								{{ 'stationsDetails.warning.text.part1' | translate }}
								<a [href]="getUrl()" target= "_blank">{{ 'stationsDetails.warning.text.part2' | translate }}
									<mat-icon class="link-icon">open_in_new</mat-icon>
								</a>
								{{ 'stationsDetails.warning.text.part3' | translate }}
							</p>
						</div>
					</div>
	
					<div class="readings">
						<div>
							<h3>{{ 'stationsDetails.readings' | translate }}</h3>
							<div class="attribute">
								<p class="attribute-label">{{ 'stationsDetails.update' | translate }} :&nbsp;</p>
								<p class="attribute-value">{{ dateIsValid(stationDetailsMetadata?.date_prise_valeur) ? (stationDetailsMetadata.date_prise_valeur | date:getDateFormat()) : 'stationsDetails.na' | translate }}</p>
							</div>
						</div>
	
						<div>
							<p class="reading-label">{{ 'stationsDetails.flow' | translate }}</p>
							<div class="reading-line">
								<p class="reading">{{ stationDetailsMetadata?.dern_valeur_deb && stationDetailsMetadata?.etat_max !== null ? (stationDetailsMetadata.dern_valeur_deb | formatReading) + ' m³/s' : 'stationsDetails.na' | translate }}</p>
								<p *ngIf="stationDetailsMetadata?.refoule_deb" class="reading-note">*</p>
							</div>
							<p *ngIf="stationDetailsMetadata?.refoule_deb" class="attribute-label attribute-label-mobile">* {{ 'stationsDetails.note' | translate }}</p>
							<div *ngIf="stationDetailsMetadata?.seuils_deb" class="thresholds-clickable"
								(click)="flowThresholdsShown = !flowThresholdsShown">
								<p class="link">{{ flowThresholdsShown ? ('stationsDetails.thresholds.hide' | translate) : ('stationsDetails.thresholds.show' | translate) }}</p>
								<button mat-icon-button disableRipple>
									<mat-icon
										[svgIcon]="flowThresholdsShown ? 'chevron-up' : 'chevron-down'"
										color="primary">
									</mat-icon>
								</button>
							</div>
															
							<div *ngIf="flowThresholdsShown" class="thresholds">
								<div *ngFor="let seuil of stationDetailsMetadata.seuils_deb" class="attribute">
									<p class="attribute-label">{{ ThresholdFlow[seuil.type] | translate }} :&nbsp;</p>
									<p class="attribute-value">{{ seuil.valeur | formatReading }} m³/s</p>
								</div>
							</div>
						</div>
	
						<div>
							<p class="reading-label">{{ 'stationsDetails.level' | translate }}</p>
							<div>
								<p class="reading">{{ stationDetailsMetadata?.dern_valeur_niv && stationDetailsMetadata?.etat_max !== null ? (stationDetailsMetadata.dern_valeur_niv | formatReading) + ' m' : 'stationsDetails.na' | translate }}</p>
							</div>
							<div *ngIf="stationDetailsMetadata?.seuils_niv" class="thresholds-clickable"
								(click)="heightThresholdsShown = !heightThresholdsShown">
								<p class="link">{{ heightThresholdsShown ? ('stationsDetails.thresholds.hide' | translate) : ('stationsDetails.thresholds.show' | translate) }}</p>
								<button mat-icon-button disableRipple>
									<mat-icon
										[svgIcon]="heightThresholdsShown ? 'chevron-up' : 'chevron-down'"
										color="primary">
									</mat-icon>
								</button>
							</div>
															
							<div *ngIf="heightThresholdsShown" class="thresholds">
								<div *ngFor="let seuil of stationDetailsMetadata.seuils_niv" class="attribute">
									<p class="attribute-label">{{ ThresholdHeight[seuil.type] | translate }} :&nbsp;</p>
									<p class="attribute-value">{{ seuil.valeur | formatReading }} m</p>
								</div>
							</div>
						</div>
	
						<div>
							<p class="reading-label">{{ 'stationsDetails.state.state' | translate }}</p>
							<div class="state-value" (click)="tooltipState.toggle()">
								<img class="state-trend-img" [src]="getStationStateLegend(stationDetailsMetadata?.etat_max)" width="20" height="20">
								<p class="reading">{{ getStationState(stationDetailsMetadata?.etat_max) | translate }}</p>
								<div
									class="info-container"
									#tooltipState="matTooltip"
									[matTooltip]="getStationStateDescription(stationDetailsMetadata?.etat_max) | translate"
									[matTooltipPosition]="isMobile ? 'above' : 'right'"
									[matTooltipClass]="isMobile ? 'tooltip-above' : 'tooltip-right'"
									matTooltipShowDelay="100"
                    				matTooltipTouchGestures="auto">
									<mat-icon
										class="info"
										color="primary"
										svgIcon="information">
      								</mat-icon>
								</div>
							</div>
						</div>
	
						<div>
							<p class="reading-label">{{ 'trend.trend' | translate }}</p>
							<div class="trend-value">
								<p class="reading">
									{{ stationDetailsMetadata?.trend_pct !== null && stationDetailsMetadata?.etat_max !== null ? (stationDetailsMetadata?.trend_pct | getStationTrend) : 'stationsDetails.na' | translate }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
	
			<div *ngIf="flowDataPresent">
				<h2>{{ 'stationsDetails.flow' | translate }}</h2>
				<mat-tab-group class="mat-tab-group">
					<mat-tab>
						<ng-template mat-tab-label>
							<p>{{ 'stationsDetails.hydrograph.hydrograph' | translate }}</p>
						</ng-template>
						<div class="tab-content">
							<div class="options-button">
								<button
									mat-icon-button
									[matMenuTriggerFor]="menuNiv"
									matTooltip="Options">
									<mat-icon svgIcon="menu"></mat-icon>
								</button>
							</div>
							<div class="hydro">
								<mat-menu #menuNiv="matMenu">
									<button *ngIf="!isMobile" mat-menu-item
										(click)="zoomExtent(hydroFlow)">
										<mat-icon svgIcon="magnify-scan"></mat-icon>
										<span>{{ 'stationsDetails.hydrograph.resetZoom' | translate }}</span>
									</button>
									<mat-divider></mat-divider>
									<button mat-menu-item
										(click)="downloadImage('hydro-deb', hydroFlow)">
										<mat-icon svgIcon="image"></mat-icon>
										<span>{{ 'stationsDetails.hydrograph.exportImage' | translate }}</span>
									</button>
									<button mat-menu-item
										(click)="downloadPDF('hydro-deb', hydroFlow)">
										<mat-icon svgIcon="file-pdf-box"></mat-icon>
										<span>{{ 'stationsDetails.hydrograph.exportPDF' | translate }}</span>
									</button>
								</mat-menu>
								<div class="hydro-deb" [ngStyle]="!isMobile ? {'position': 'relative', 'height': '450px', 'width': '100%'} : {'position': 'relative', 'height': '550px', 'width': '100%'}">
									<canvas id="hydro-deb"></canvas>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab *ngIf="stationDetailsReadings?.valeurs_deb">
						<ng-template mat-tab-label>
							<p>{{ ('stationsDetails.table' | translate) + " (observations)" }}</p>
						</ng-template>
						<div class="tab-content">
							<br>
							<app-stations-details-table
								[readings]="stationDetailsReadings.valeurs_deb"
								[flow]="true"
								[forecasts]="false">
							</app-stations-details-table>
						</div>
					</mat-tab>
					<mat-tab *ngIf="stationDetailsReadings?.valeurs_deb_prev">
						<ng-template mat-tab-label>
							<p>{{ ('stationsDetails.table' | translate) + " (" + ('stationsDetails.forecasts.forecasts' | translate) + ")" }}</p>
						</ng-template>
						<div class="tab-content">
							<br>
							<app-stations-details-table
								[readings]="stationDetailsReadings.valeurs_deb_prev"
								[flow]="true"
								[forecasts]="true">
							</app-stations-details-table>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
	
			<div *ngIf="heightDataPresent">
				<h2 >{{ 'stationsDetails.level' | translate }}</h2>
				<mat-tab-group class="mat-tab-group">
					<mat-tab>
						<ng-template mat-tab-label>
							<p>{{ 'stationsDetails.hydrograph.hydrograph' | translate }}</p>
						</ng-template>
						<div class="tab-content">
							<div class="options-button">
								<button
									mat-icon-button
									[matMenuTriggerFor]="menuNiv"
									matTooltip="Options">
									<mat-icon svgIcon="menu"></mat-icon>
								</button>
							</div>
							<div class="hydro">
								<mat-menu #menuNiv="matMenu">
									<button *ngIf="!isMobile" mat-menu-item
										(click)="zoomExtent(hydroHeight)">
										<mat-icon svgIcon="magnify-scan"></mat-icon>
										<span>{{ 'stationsDetails.hydrograph.resetZoom' | translate }}</span>
									</button>
									<mat-divider></mat-divider>
									<button mat-menu-item
										(click)="downloadImage('hydro-niv', hydroHeight)">
										<mat-icon svgIcon="image"></mat-icon>
										<span>{{ 'stationsDetails.hydrograph.exportImage' | translate }}</span>
									</button>
									<button mat-menu-item
										(click)="downloadPDF('hydro-niv', hydroHeight)">
										<mat-icon svgIcon="file-pdf-box"></mat-icon>
										<span>{{ 'stationsDetails.hydrograph.exportPDF' | translate }}</span>
									</button>
								</mat-menu>
								<div class="hydro-niv" [ngStyle]="!isMobile ? {'position': 'relative', 'height': '450px', 'width': '100%'} : {'position': 'relative', 'height': '550px', 'width': '100%'}">
									<canvas id="hydro-niv"></canvas>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab *ngIf="stationDetailsReadings?.valeurs_niv">
						<ng-template mat-tab-label>
							<p>{{ ('stationsDetails.table' | translate) + " (observations)" }}</p>
						</ng-template>
						<div class="tab-content">
							<br>
							<app-stations-details-table
								[readings]="stationDetailsReadings.valeurs_niv"
								[flow]="false"
								[forecasts]="false">
							</app-stations-details-table>
						</div>
					</mat-tab>
					<mat-tab *ngIf="stationDetailsReadings?.valeurs_niv_prev">
						<ng-template mat-tab-label>
							<p>{{ ('stationsDetails.table' | translate) + " (" + ('stationsDetails.forecasts.forecasts' | translate) + ")" }}</p>
						</ng-template>
						<div class="tab-content">
							<br>
							<app-stations-details-table
								[readings]="stationDetailsReadings.valeurs_niv_prev"
								[flow]="false"
								[forecasts]="true">
							</app-stations-details-table>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>

			<div class="more">
				<a class='link' [routerLink]="['/nous-joindre']" [queryParams]="{station: stationDetailsMetadata?.label}">{{ ('sideResult.problem.problem' | translate) + ('sideResult.problem.station' | translate) }}</a>
			</div>
		</ng-container>
	
		<ng-template #dataNotPresent>
			<p class="no-station">{{ 'stationsDetails.noDetails' | translate }}</p>
		</ng-template>
	
		<app-shared-footer *ngIf="stationDetailsMetadata" class="footer"></app-shared-footer>
	</div>
</div>
