<div class="container" >
    <mat-toolbar class="headline menu" color="accent">
        <div class="col-12 align-self-end flex" >
            <nav id="user-account" *ngIf="hasAuthService() && authenticated" [ngClass]="mobile? ['navbar', 'user-nav-mobile']: ['navbar', 'user-nav'] ">
                <a class="link"  routerLinkActive="active" [matMenuTriggerFor]="menu" [matTooltip]="'user.myAccount' | translate">
                    <mat-icon
                        svgIcon="account-outline"
                        class="contrast">
                    </mat-icon>
                    <mat-icon
                        svgIcon="chevron-down"
                        class="small-chevron"
                        >
                    </mat-icon>
                </a>
                <mat-menu class="user-menu" #menu="matMenu">
                    <div class="user-menu-list">{{'user.welcome' | translate}} <strong>{{user.firstName}}</strong></div>
                    <button class="user-menu-list" mat-menu-item color=primary style="padding:8px 0 0 0;height:fit-content;" [routerLink]="['mon-compte']" routerLinkActive="active">{{'user.myAccount' | translate}}</button>
                    <button class="user-menu-list" mat-menu-item color=primary style="padding:8px 0 0 0;height:fit-content;" (click)="logOut()">{{'header.logOut' | translate}}</button>
                </mat-menu>
            </nav>
            <nav id="pages" [ngClass]="mobile? ['navbar', 'pages-nav-mobile']: ['navbar', 'pages-nav'] ">
                <a class="nav-arrow left" href="#menu-left" routerLinkActive="active">❮</a>
                <div class="main-nav slick-track">
                    <a class="nav-link" routerLink="/" routerLinkActive="active" id="menu-left" [routerLinkActiveOptions]="{exact:true}">{{ 'menu.home' | translate }}</a>
                    <a class="nav-link" routerLink="/carte" routerLinkActive="active">{{ 'menu.map' | translate }}</a>
                    <a class="nav-link" routerLink="/stations" routerLinkActive="active">Stations</a>
                    <a class="nav-link" routerLink="/en-savoir-plus/carte" id="menu-right" routerLinkActive="active">{{ 'menu.more' | translate }}</a>
                </div>
                <a class="nav-arrow right" color="accent" href="#menu-right" routerLinkActive="active">❯</a>
            </nav>
        </div>
    </mat-toolbar>
</div>

<router-outlet></router-outlet>
