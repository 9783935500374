import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { IgoLanguageModule } from '@igo2/core';

@Component({
    selector: 'app-shared-sequential-links',
    templateUrl: './shared-sequential-links.component.html',
    styleUrls: ['./shared-sequential-links.component.scss'],
    standalone: true,
    imports: [MatIconModule, CommonModule, IgoLanguageModule]
})
export class SharedSequentialLinksComponent {
    @Input() showPreviousLink: boolean = true;
    @Input() previousLinkText: string = '';
    @Input() previousLinkUrl: string = '';
    @Input() showNextLink: boolean = true;
    @Input() nextLinkText: string = '';
    @Input() nextLinkUrl: string = '';

    constructor(private _router: Router) { }

    goTo(url: string): void {
        this._router.navigate([url]);
    }
}
