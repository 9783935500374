import { Component, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { MatSelectChange } from '@angular/material/select';

import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
	selector: 'app-stations-list-header',
	templateUrl: './stations-list-header.component.html',
	styleUrls: ['./stations-list-header.component.scss']
})
export class StationsListHeaderComponent implements OnInit, OnChanges, OnDestroy {
	@Input() numberOfStations: number; // current number of stations (displayed)
	@Input() currentPage: number; // current page
	@Input() selectedOptions: object; // selected filter options
	@Output() sortChange: EventEmitter<string> = new EventEmitter(); // emitted when new sort option is selected
	@Output() numberOfStationsPerPageChange: EventEmitter<number> = new EventEmitter(); // emitted when new number of stations per page is selected
	@Output() filterRemoved: EventEmitter<object> = new EventEmitter(); // emitted when a filter option is removed

	public lowerBound: number; // lower bound of current stations shown (displayed)
	public upperBound: number; // upper bound of current stations shown (displayed)

	public sortOptions: object[]; // object representing sort options displayed in respective selector
	public numberOfStationsPerPageOptions: number[]; // object representing number of stations per page options to be displayed in respective selector

	public currentSort$: BehaviorSubject<string> = new BehaviorSubject("a_etat_max.desc.nullslast,e_plan_deau"); // current sort (state descending by default)
	public currentSort$$: Subscription; // subscription to current sort

	public numberOfStationsPerPage$: BehaviorSubject<number> = new BehaviorSubject(10); // number of stations per page (10 by default)
	public numberOfStationsPerPage$$: Subscription; // subscription to number of stations per page

	public optionsAreSelected: boolean = false; // whether filter options are selected or not

	public queryParamMap$$: Subscription; // subscription to query params

	constructor(private _activatedRoute: ActivatedRoute, private _router: Router) { }

	ngOnInit(): void {
		// subscription to query params
		this.queryParamMap$$ = this._activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
			this.currentSort$.next(paramMap.get("sort") || "a_etat_max.desc.nullslast,e_plan_deau");
			this.numberOfStationsPerPage$.next(Number(paramMap.get("numberPerPage")) || 10);
		});

		this.sortOptions = [{
			value: "a_etat_max.desc.nullslast,e_plan_deau",
			label: "filters.state"
		},
		{
			value: "e_plan_deau,a_etat_max.desc.nullslast",
			label: "filters.body"
		}]; // sort options to be shown in selector

		this.numberOfStationsPerPageOptions = [10, 25, 50]; // number of stations per page to be shown in selector

		// subscription to current sort
		this.currentSort$$ = this.currentSort$.subscribe((currentSort: string) => {
			this._router.navigate([], {
				queryParams: {
					sort: currentSort,
					numberPerPage: this._getNumberOfStationsPerPage()
				},
				queryParamsHandling: "merge"
			});

			this.sortChange.emit(currentSort);
		});

		// subscription to number of stations per page
		this.numberOfStationsPerPage$$ = this.numberOfStationsPerPage$.subscribe((numberOfStationsPerPage: number) => {
			// calculate new lower and upper bounds
			this.lowerBound = this._getLowerBound(this.currentPage);
			this.upperBound = this._getUpperBound(this.currentPage, this.numberOfStations);

			this._router.navigate([], {
				queryParams: {
					sort: this.currentSort$.getValue(),
					numberPerPage: numberOfStationsPerPage
				},
				queryParamsHandling: "merge"
			});

			this.numberOfStationsPerPageChange.emit(numberOfStationsPerPage);
		});

		this._checkOptionsAreSelected();
	}

	ngOnChanges(changes: SimpleChanges): void {
		// if current page has changed...
		if (changes.currentPage) {
			// ...calculate new lower and upper bounds
			const currentPage = changes.currentPage.currentValue;
			this.lowerBound = this._getLowerBound(currentPage);
			this.upperBound = this._getUpperBound(currentPage, this.numberOfStations);
		}

		// if total number of stations has changed...
		if (changes.numberOfStations) {
			// ...calculate new lower and upper bounds
			const numberOfStations = changes.numberOfStations.currentValue;
			this.lowerBound = this._getLowerBound(this.currentPage);
			this.upperBound = this._getUpperBound(this.currentPage, numberOfStations);
		}

		// if selected options have changed...
		if (changes.selectedOptions) {
			this._checkOptionsAreSelected();
		}
	}

	ngOnDestroy(): void {
		this.currentSort$$.unsubscribe();
		this.numberOfStationsPerPage$$.unsubscribe();
		this.queryParamMap$$.unsubscribe();
	}

	/**
	 * @description get number of stations per page
	 * @returns number of stations per page
	 */
	private _getNumberOfStationsPerPage(): number {
		return this.numberOfStationsPerPage$.getValue();
	}

	/**
	 * @description get lower bound
	 * @param currentPage current page
	 * @returns lower bound
	 */
	private _getLowerBound(currentPage: number): number {
		return ((currentPage - 1) * this._getNumberOfStationsPerPage()) + 1;
	}

	/**
	 * @description get upper bound
	 * @param currentPage current page
	 * @param numberOfStations number of stations
	 * @returns upper bound
	 */
	private _getUpperBound(currentPage: number, numberOfStations: number): number {
		// calculate theoretical upper bound
		const upperBound: number = currentPage * this._getNumberOfStationsPerPage();

		return upperBound > numberOfStations ? numberOfStations : upperBound;
	}

	/**
	 * @description update current sort
	 * @param event event fired when new sort option is selected
	 */
	changeCurrentSort(event: MatSelectChange): void {
		this.currentSort$.next(event.value);
	}

	/**
	 * @description update number of stations per page
	 * @param event event fired when a new number of stations per page option is selected
	 */
	changeNumberOfStationsPerPage(event: MatSelectChange): void {
		this.numberOfStationsPerPage$.next(event.value);
	}

	/**
	 * @description check if filter options are selected
	 */
	private _checkOptionsAreSelected(): void {
		if (this.selectedOptions) {
		  	for (const selectedOption in this.selectedOptions) {
				if (this.selectedOptions[selectedOption].length) {
			  		this.optionsAreSelected = true;
			  		break;
				} else {
					this.optionsAreSelected = false;
				}
		  	}
		} else {
			this.optionsAreSelected = false;
		}
	}
}
