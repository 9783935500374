import { AboutTrainingComponent } from './about-training.component';
import { IgoLanguageModule } from '../../../../../language/language.module';//'@igo2/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { SharedComponentsModule } from './../../../../shared/shared-components.module';

@NgModule({
  declarations: [AboutTrainingComponent],
  imports: [
    CommonModule,
    IgoLanguageModule,
    RouterModule,
    MatIconModule,
    SharedComponentsModule
  ],
  exports: [AboutTrainingComponent]
})
export class AboutTrainingModule { }
