import { IgoLanguageModule } from '../../../../../language/language.module';//'@igo2/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { StationsDetailsComponent } from './stations-details.component';
import { StationsDetailsTableComponent } from './stations-details-table/stations-details-table.component';

import { SharedComponentsModule } from 'src/app/pages/shared/shared-components.module';

import { FormatReadingPipe } from './format-reading.pipe';
import { GetStationTrendPipe } from './get-station-trend.pipe';

@NgModule({
	declarations: [
		StationsDetailsComponent,
		StationsDetailsTableComponent,
  		FormatReadingPipe,
    	GetStationTrendPipe],
	imports: [
		IgoLanguageModule,
		SharedComponentsModule,
		CommonModule,
		RouterModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule
	],
	exports: [StationsDetailsComponent, FormatReadingPipe]
})
export class StationsDetailsModule { }
