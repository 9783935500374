<div *ngIf="!isMobile else mobile">
	<div class="header">
		<p class="header-label">{{ 'stationsFiltersList.stationsFilters.filtersLabel' | translate }}</p>
		<p class="header-reset" (click)="resetAllFilters()">{{ 'stationsFiltersList.stationsFilters.resetFilters' | translate }}</p>
	</div>

	<br>
	
	<form [formGroup]="filtersForm" class="filters">
		<app-stations-filters-filter *ngFor="let filterOptions of filtersOptions | keyvalue"
			class="filter"
			[form]="filtersForm"
			[filter]="filterOptions.key"
			[filterOptions]="filterOptions.value"
			[selectedCount]="getSelectedCount(filterOptions.value)"
			(optionChange)="navigate($event.filter, $event.filterOption)">
		</app-stations-filters-filter>
	</form>

	<mat-divider></mat-divider>

	<div class="more-filters">
		
		<div class="more-filters-header"
			(click)="moreFiltersShown = !moreFiltersShown">
			<p class="more-filters-header-label">{{ !moreFiltersShown ? ('stationsFiltersList.stationsFilters.moreFilters' | translate) : ('stationsFiltersList.stationsFilters.lessFilters' | translate) }}</p>
			<mat-icon [svgIcon]="!moreFiltersShown ? 'plus' : 'minus'"></mat-icon>
		</div>

		<br *ngIf="moreFiltersShown">

		<form [hidden]="!moreFiltersShown" [formGroup]="moreFiltersForm">
			<app-stations-filters-filter *ngFor="let moreFilterOptions of this.moreFiltersOptions | keyvalue"
				[form]="moreFiltersForm"
				[filter]="moreFilterOptions.key"
				[filterOptions]="moreFilterOptions.value"
				(optionChange)="navigateMore($event.filter, $event.filterOption)">
			</app-stations-filters-filter>
		</form>

		<mat-divider *ngIf="moreFiltersShown"></mat-divider>

		<br *ngIf="moreFiltersShown">

		<mat-checkbox *ngIf="moreFiltersShown"
			disableRipple="true"
			[checked]="isPrevOnly"
			(change)="togglePrev(!isPrevOnly)">{{ 'stationsFiltersList.stationsFilters.prevOnly' | translate }}
		</mat-checkbox>
	</div>

	<mat-divider></mat-divider>
</div>

<ng-template #mobile>
	<div class="header-mobile" (click)="filtersShown = !filtersShown">
		<p class="header-label header-label-mobile">{{ 'stationsFiltersList.stationsFilters.filtersLabel' | translate }}</p>
		<mat-icon
			class="filters-icon"
			color="primary"
			>{{ !filtersShown ? 'expand_more' : 'expand_less' }}</mat-icon>
	</div>
	
	<div *ngIf="filtersShown">
		<br>
		<mat-divider></mat-divider>
		<br>
		<p class="header-reset" (click)="resetAllFilters()">{{ 'stationsFiltersList.stationsFilters.resetFilters' | translate }}</p>
		<br>
		
		<form [formGroup]="filtersForm" class="filters">
			<app-stations-filters-filter *ngFor="let filterOptions of this.filtersOptions | keyvalue"
				class="filter"
				[form]="filtersForm"
				[filter]="filterOptions.key"
				[filterOptions]="filterOptions.value"
				[selectedCount]="getSelectedCount(filterOptions.value)"
				(optionChange)="navigate($event.filter, $event.filterOption)">
			</app-stations-filters-filter>
		</form>

		<mat-divider></mat-divider>

		<div class="more-filters">
			
			<div class="more-filters-header"
				(click)="moreFiltersShown = !moreFiltersShown">
				<p class="more-filters-header-label">{{ !moreFiltersShown ? ('stationsFiltersList.stationsFilters.moreFilters' | translate) : ('stationsFiltersList.stationsFilters.lessFilters' | translate) }}</p>
				<mat-icon [svgIcon]="!moreFiltersShown ? 'plus' : 'minus'"></mat-icon>
			</div>

			<br *ngIf="moreFiltersShown">

			<form [hidden]="!moreFiltersShown" [formGroup]="moreFiltersForm">
				<app-stations-filters-filter *ngFor="let moreFilterOptions of this.moreFiltersOptions | keyvalue"
					class="filter"
					[form]="moreFiltersForm"
					[filter]="moreFilterOptions.key"
					[filterOptions]="moreFilterOptions.value"
					(optionChange)="navigateMore($event.filter, $event.filterOption)">
				</app-stations-filters-filter>
			</form>

			<mat-divider *ngIf="moreFiltersShown"></mat-divider>

			<br *ngIf="moreFiltersShown">

			<mat-checkbox *ngIf="moreFiltersShown"
				disableRipple="true"
				[checked]="isPrevOnly"
				(change)="togglePrev(!isPrevOnly)">{{ 'stationsFiltersList.stationsFilters.prevOnly' | translate }}
			</mat-checkbox>
		</div>

		<mat-divider></mat-divider>
	</div>
</ng-template>