import { ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@igo2/auth';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HttpOptions, StationForDetailsMetadata, Threshold, StationForMyAccount, NotifItemPinPoint, CustomThreshold, GetCustomThreshold
 } from 'src/app/pages/shared/stations/stations.interface';
import { Subscription } from 'rxjs';
import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@igo2/core';
import { ThresholdNivDeb } from 'src/app/pages/shared/stations/stations.enum';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { MessageService } from '@igo2/core';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { FormModel } from '../alertForm.model';
import { NotifHttpOptionService } from 'src/app/services/notifHttpOption.service';
import { DecimalPipe } from '@angular/common';
import { SuccessError } from '../success-error';
export class CustomAlertForm {
  customAlertInedit: FormGroup = new FormGroup({
    fk_id_station: new FormControl(''),
    type: new FormControl(''),
    valeur: new FormControl(''),
    description: new FormControl(''),
    commentaire: new FormControl('')
  });
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']//,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit, OnDestroy {

  public user;
  public stationDetailsMetadata: StationForDetailsMetadata; // station details metadata
	public stationDetailsMetadata$$: Subscription;
  public stationName: string;
  public dataPresent: boolean = true; // whether data is present for a station or not
	public ThresholdNivDeb = ThresholdNivDeb; // enum for thresholds
  public noAlertType : string;
  public displayCustomForm = false;
  public customAlertForm: FormGroup;
  public customAlertID: string;
 // public customValue: string = (<HTMLInputElement>document.getElementById("customvalue")).value;
  //public customValue = (document.getElementById("customvalue") as HTMLInputElement).value;
  public noAlert: Threshold;
  public selectedThreshold;
  public customStationNameChecked = false;
  public submit$$: Subscription;
  public station: StationForMyAccount;
  public stationID : number;
  public displayThresholds : Promise<boolean>;
  public notificationSubscription: Subscription;
  public alertForm: FormGroup;
  public model: FormModel = new FormModel();
  public suppThreshold = false;
  public suppThresholdSelected = false;
  @Input()
  disabled = true;
  public suppThresholdTypes = ['smg', 'sim', 'simoy', 'simaj'];
  public alreadysubscribed : boolean;
  public userSubscriptionNumber: number = 0;
  public endPoint = '';
  public optOut = 'NONE';
  public subscribedSuppThreshold: Threshold = {
    type: '',
    valeur: undefined,
    debNiv: []
  };
  public channelTypeEmail: NotifItemPinPoint;
  public channelTypeSMS: NotifItemPinPoint;
  public regexComma = /\,/;
  hasDebit: boolean;
  hasNiveau: boolean;
  thresholdNivValues: number[] = [];
  thresholdDebValues: number[] = [];
  public numberRegex = /[0-9]/;
  public displaySpinner = false;
  public newUser = false;
  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  @HostListener('window:resize', ['$event'])
	onResize() {
    this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  constructor(
    public authService: AuthService,
    private _stationListService: StationListService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {stationId: string, shoudReload: boolean, toDo?: string, stationName?: string },
    private _languageService: LanguageService,
    private successError: SuccessError,
    private notificationsService: NotificationsService,
    public notifHttpOptionService: NotifHttpOptionService,
    private formBuilder: FormBuilder,
    private _messageService: MessageService,
    public decimalPipe: DecimalPipe,
    private cdRef: ChangeDetectorRef
    ) {
      this.authService = authService;
      const decodeToken = this.authService.decodeToken();
      this.user = decodeToken.user;
      this.noAlertType = this._languageService.translate.instant("user.pref.noMoreAlert");
      this.noAlert = {type: this.noAlertType, valeur: undefined};
    }

  ngOnInit() {
    this.stationID = Number(this.data.stationId);
    this.cdRef.detectChanges();
    this.getUserNotifications();
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    if (this.submit$$) {
      this.submit$$.unsubscribe();
    }
    this.cdRef.detectChanges();
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  hasAuthService() {
    return this.authService.hasAuthService;
  }

  buildAlertForm(): void {
      //let id = this.data.stationId;
      let attributes = this.subscribedSuppThreshold.type.length? ['spa', this.subscribedSuppThreshold.type] : ['spa'];
      this.alertForm = this.formBuilder.group({
        'EndpointRequest': this.formBuilder.group({
          'Address': this.user.email,
          'Attributes': this.formBuilder.group({ [this.data.stationId]: [attributes] }),
          'ChannelType': 'EMAIL', //[this.model.EndpointRequest.ChannelType], //'EMAIL', 'SMS'
          'OptOut': this.optOut //'NONE', 'ALL'
        })
      });
  }

  /**
	 * @description Get the current user station subscriptions
	 */
  private getUserNotifications() {
    this.notificationSubscription = this.notificationsService.getNotificationsList(this.notifHttpOptionService.httpOptions)
      .subscribe((response) => {
          if (response){
            let items = response.EndpointsResponse.Item;
            let list: number[] = [];
            for (let item of items) {
              this.optOut = item.OptOut;
              for ( const [key, value] of Object.entries(item.Attributes)){
                let thresholds = Array(value);
                if(String(thresholds[0]).length >= 1 && !list.includes(Number(key))) {
                  list.push(Number(key));
                  if(key === String(this.stationID)){
                    this.alreadysubscribed = true;
                    this.cdRef.detectChanges();
                    Object.values(value).forEach(threshold => {
                      let seuilType = threshold.split('-', 2);
                      if(seuilType[0] !== 'spa'){
                        this.subscribedSuppThreshold.type = seuilType[0];
                        this.cdRef.detectChanges();
                        this.suppThresholdSelected = true;
                        this.cdRef.detectChanges();
                      }
                    });
                  }
                }
              }
              if(item.ChannelType === 'SMS'){
                this.channelTypeSMS = item;
                this.channelTypeSMS.Address = item.Address;
                delete this.channelTypeSMS.Id;
              }
            }
            this.userSubscriptionNumber = (list.length);
          } else {
            this.newUser = true;
          }
          this.buildAlertForm();
          setTimeout(() => {
            if (this.numberRegex.test(this.subscribedSuppThreshold.type)){
              this.getINEDIT(this.subscribedSuppThreshold.type);
           }
            this.getStationDetails(this.stationID);
          }, 200);
        },
        error => { console.warn('Notification error', error); }
      );
      this.notificationSubscription.unsubscribe;
      this.cdRef.detectChanges();
  }

	/**
	 * @description get this station details metadata from a station id
	 * @param stationId station ID
	 */
  private getStationDetails(stationId: number): void {
		const httpOptionsStation: HttpOptions = {
			params: {
				id: "eq." + stationId
			}
		};

		this.stationDetailsMetadata$$ = this._stationListService.getStationDetailsMetadata(httpOptionsStation)
			.subscribe((stationList: StationForDetailsMetadata[]) => {
        stationList.length ? this.stationDetailsMetadata = stationList[0] : this.dataPresent = false;
        this.stationName = stationList[0].label;
        this.station = {
          id: this.stationID,
          baseThreshold: {},
          suppThreshold: {}
        };
        if (this.stationDetailsMetadata.seuils_niv || this.stationDetailsMetadata.dern_valeur_niv){
          this.hasNiveau = true;
          if (this.stationDetailsMetadata.seuils_niv) {
            for (let seuil of this.stationDetailsMetadata.seuils_niv){
              this.addThresholdtoCurrentStationDetails(seuil);
            };
          }
        }
        if (this.stationDetailsMetadata.seuils_deb || this.stationDetailsMetadata.dern_valeur_deb){
          this.hasDebit = true;
          if (this.stationDetailsMetadata.seuils_deb) {
            for (let seuil of this.stationDetailsMetadata.seuils_deb){
              this.addThresholdtoCurrentStationDetails(seuil);
            };
          }
        }
        this.cdRef.detectChanges();
        setTimeout(() => {
          this.displayThresholds = Promise.resolve(true);
          this.cdRef.detectChanges();
        }, 500);
      },
      error => { console.warn('Notification error'); }
    );
  }

  addThresholdtoCurrentStationDetails(seuil: Threshold){
    let seuilType = seuil.type.split('-', 2);
    let newThreshold = {
        valeur: seuil.valeur,
        debNiv: seuilType[1]
    };
    newThreshold.debNiv.includes('niv') ?
      this.thresholdNivValues.push(newThreshold.valeur) : this.thresholdDebValues.push(newThreshold.valeur) ; // to double check behavior here
    if (seuilType[0] === 'spa'){
      !this.station.baseThreshold.spa ?
        this.station.baseThreshold.spa = [newThreshold] : this.station.baseThreshold.spa.push(newThreshold);
        this.cdRef.detectChanges();
    } else {
      for (let type of this.suppThresholdTypes){
        if (type === seuilType[0]){
          !this.station.suppThreshold[type] ?
            this.station.suppThreshold[type] = [newThreshold] : this.station.suppThreshold[type].push(newThreshold);
            this.cdRef.detectChanges();
        } else if (this.subscribedSuppThreshold?.type === type) {
          this.subscribedSuppThreshold.valeur = seuil.valeur;
          this.cdRef.detectChanges();
          this.subscribedSuppThreshold.debNiv.push(seuilType[1]); /// will double the niv. to fix to add deb if causes bug
          this.cdRef.detectChanges();
        }
      }
    }
  }

  getCustomThresholdData(response: GetCustomThreshold[], customThresholdID: string){
    let index = response.findIndex(i => (i.seuil_id === Number(customThresholdID)));
    if (index !== -1){
      let value = Number(response[index].valeur);
      let twoDecimals = value.toFixed(2);
      this.subscribedSuppThreshold.debNiv = response[index].type;
      this.subscribedSuppThreshold.valeur = twoDecimals;
      this.subscribedSuppThreshold.name = response[index].description;
      this.subscribedSuppThreshold.comment = response[index].commentaire;
      this.cdRef.detectChanges();
      }
      setTimeout(() => {
        this.onDisplayCustomForm(true);
        this.cdRef.detectChanges();
        this.displayThresholds = Promise.resolve(true);
        this.cdRef.detectChanges();
      }, 500);
  }

  isCustomStationNameChecked(){
    if(this.customStationNameChecked === true){
      this.customStationNameChecked = true;
    }
    else{
      this.customStationNameChecked = false;
    }
  }

  onDisplayCustomForm($event: boolean){
    this.suppThresholdSelected = true;
    this.cdRef.detectChanges();
    this.displayCustomForm = $event;

    this.customAlertForm = new FormGroup({
      fk_id_station: new FormControl(this.stationID),
      type: new FormControl(this.getCustomType(), Validators.required),
      valeur: new FormControl(this.getValue(),
        [Validators.required, this.forbiddenValuesValidator(this.thresholdNivValues, this.thresholdDebValues)]),
      description: new FormControl(this.getName(), Validators.required),
      commentaire: new FormControl(this.getComment()),
    });
  }

  getName(){
    if(this.numberRegex.test(this.subscribedSuppThreshold.type)){
       return this.subscribedSuppThreshold.name;
    } else {
      return '';
    }
  }

  getComment(){
    if(this.numberRegex.test(this.subscribedSuppThreshold.type)){
       return this.subscribedSuppThreshold.comment;
    } else {
      return '';
    }
  }

  getValue(){
    if(this.numberRegex.test(this.subscribedSuppThreshold.type)){
       return this.subscribedSuppThreshold.valeur;
    } else {
      return '';
    }
  }

  getCustomType(){
    if(this.numberRegex.test(this.subscribedSuppThreshold.type)){
       return this.subscribedSuppThreshold.debNiv;
    } else {
      return '';
    }
  }

  isCheckedCustomType(value?: string){
    if(this.subscribedSuppThreshold.debNiv.length > 1){
       return true;
    } else {
      return false;
    }
  }

  forbiddenValuesValidator(valuesDeb: number[], valuesNiv: number[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.customAlertForm){
        const type = this.customAlertForm.get('type').value;
        const forbidden = type === 'custom-niveau'? valuesNiv.includes(Number(control.value)) : valuesDeb.includes(Number(control.value));
        return forbidden ? {forbiddenValue: {value: Number(control.value)}} : null;
      }
    };
  }

  addDecimals(value: string){
    const regex = /[\.,\,]/;
    let valeur = (this.customAlertForm.get('valeur') as FormControl).value;
    if (regex.test(value)){
      console.log('regex error');
    }
    this.cdRef.detectChanges();
  }

  getCustomAlertID(){
    return ['spa', this.customAlertID];
  }

  confirmDeleteUserStation(stationId: number, name: string){
    //this.dialogRef.close({data: {shouldReload: false}});
    this.openWarningDialog({ toDo: 'deleteStation', shouldReload: true, stationId: stationId, stationName: name});
  }

  openWarningDialog(dataToSend: {} ){ const dialogRef = this.dialog
    .open(WarningDialogComponent,
      {
        data: dataToSend,
        panelClass: 'confirmDialog',
        maxWidth: this.dialogWidth(),
        maxHeight: '80vh',
      } )
    .afterClosed().subscribe(response => {
      if (response.toDo === "deleteStation"){
        this.submitForm(response.toDo);
      }
        dialogRef.unsubscribe();
      });
  }

  dialogWidth(){
		if (this.isMobile){
			console.log('mobile');
			return '90vw';
		} else
		return '700px';
	}

  disabledSubmit(){
    if(this.userSubscriptionNumber >= 20) {
      return true;
    } if ((this.displayCustomForm && this.customAlertForm.errors) ||
          (!this.station.baseThreshold.spa && !this.suppThresholdSelected) ||
          (this.alertForm.errors && !this.displayCustomForm)
          ) {
      return true;
    } else {
      false;
    }
  }

  submitForm(action?: string) {
    if(action === 'deleteStation'){
      const attributes = this.alertForm.get('EndpointRequest.Attributes') as FormGroup;
      attributes.patchValue({
        [this.station.id]: []
      });
      this.submitAWSEachChannelType();
    }
    else {
      if(this.displayCustomForm === true){
        let customForm = Object(this.customAlertForm.value);
        let value = customForm.valeur.replace(this.regexComma,'.');
        customForm.valeur = value;
        this.submitINEDIT(JSON.stringify(customForm));
      } if(!this.suppThresholdSelected){
        const attributes = this.alertForm.get('EndpointRequest.Attributes') as FormGroup;
        attributes.patchValue({
          [this.station.id]: ['spa']
        });
        this.submitAWSEachChannelType();
      }
      else {
        this.submitAWSEachChannelType();
      }
    }
  }

  submitAWSEachChannelType(){
    if (this.channelTypeSMS){
      this.channelTypeSMS.Attributes = this.alertForm.get('EndpointRequest.Attributes').value;
      this.submitAWS(JSON.stringify({EndpointRequest: this.channelTypeSMS} ));
    }
    this.submitAWS(JSON.stringify(this.alertForm.value));
    if(this.newUser){
      this.openWarningDialog({ toDo: 'newUser'});
      }
  }

  submitAWS(bodyJSON: string){
    this.notificationsService
    .addUserStation(bodyJSON)
    .subscribe(response => {
        if (HttpEventType.Response) {
          console.log('submit success');
          this.successError.success();
          //this.displayCustomForm = false;
          setTimeout(() => {
            this.dialogRef.close({data: {shouldReload: true, IneditRequest: this.displayCustomForm}});
          },500);
        }
      },
      (error) => {
        this.successError.error();
        this.cancel();
        console.warn('submit error', error);
      }
    );
  }

  getINEDIT(customThresholdID: string){
    this.notificationsService
    .getCustomThresholds()
    .subscribe(response => {
        if (HttpEventType.Response) {
          console.log('getINEDIT');
          this.getCustomThresholdData(response, customThresholdID);
        }
      },
      (error) => {
        console.warn('submitINEDIT error', error);
      }
    );
  }

  submitINEDIT(bodyJSON: string){
    this.notificationsService
    .addCustomThreshold(bodyJSON)
    .subscribe(response => {
        if (HttpEventType.Response) {
          let ineditResonse: CustomThreshold = response[0];
          console.log('submitINEDIT success');
          if (ineditResonse.seuil_id) {
            this.customAlertID = String(ineditResonse.seuil_id);
            this.cdRef.detectChanges();
            const attributes = this.alertForm.get('EndpointRequest.Attributes') as FormGroup;
            attributes.patchValue({ [this.data.stationId]: ['spa', this.customAlertID] });
            this.successError.success();
            this.displaySpinner = true;
            setTimeout(() => {
              this.submitAWSEachChannelType();
            }, 300);
          };
        }
      },
      (error) => {
        error();
        this.cancel();
        console.warn('submitINEDIT error', error);
      }
    );
  }

  public cancel(): void {
    this.dialogRef.close({data: {shouldReload: false}});
  }

  private success(){
    this._messageService.success(
      this._languageService.translate.instant("user.pref.success"),
      this._languageService.translate.instant("user.pref.successTitle")
      );
  }

  private error(){
    this._messageService.error(
      this._languageService.translate.instant("user.pref.error"),
      this._languageService.translate.instant("user.pref.errorTitle")
    );
  }

}
