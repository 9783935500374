import { Component, OnInit, HostListener } from '@angular/core';
import { ConfigService, LanguageService } from '@igo2/core';
import { AuthService } from '@igo2/auth';
import { ContextService } from '@igo2/context';
import { Environment } from './header.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  public headerLogo: string;
  public headerLogoPrint: string;
  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  private authService: AuthService;

  constructor(
    private configService: ConfigService,
    private contextService: ContextService,
    protected languageService: LanguageService,
    authService: AuthService) {
      this.authService = authService;
    }

  ngOnInit(): void {
    this.headerLogo = this.configService.getConfig('header.logo');
    this.headerLogoPrint = this.configService.getConfig('header.logoPrint');
  }

  getEnvironment(): string {
    return this.contextService.defaultContextUri.replace("-partenaires", "");
  }

  getEnvironmentLabel(): string {
    return Environment[this.getEnvironment()];
  }

  hasAuthService() {
    return this.authService.hasAuthService;
  }

  logOut() {
    this.authService.logout();
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  // Future translation system
  /*changeLanguage() {
    if (this.languageService.getLanguage() === 'fr'){
      this.languageService.setLanguage('en');
    } else {
      this.languageService.setLanguage('fr');
    }
  }*/

}
