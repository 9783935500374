import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LanguageService } from '@igo2/core';

import { State, Trend } from 'src/app/pages/shared/stations/stations.enum';
import { FiltersOptions } from 'src/app/pages/shared/stations/stations.interface';

@Component({
  selector: 'app-stations-list-header-options',
  templateUrl: './stations-list-header-options.component.html',
  styleUrls: ['./stations-list-header-options.component.scss']
})
export class StationsListHeaderOptionsComponent {
  @Input() selectedOptions: FiltersOptions; // selected filter options
  @Output() filterRemoved: EventEmitter<object> = new EventEmitter(); // event emitted when a filter is removed

  constructor(private _languageService: LanguageService) { }

  /**
   * @description get value/label of filter option
   * @param filter filter
   * @param value value of filter option
   * @returns value/label to display
   */
  getLabel(filter: string, value: any) {
    if (filter === "a_etat_max") {
      return this._languageService.translate.instant(State[value]);
    } else if (filter === "trend_pct") {
      return this._languageService.translate.instant(Trend[value]);
    } else if (filter === "prev") {
      return this._languageService.translate.instant("stationsFiltersList.stationsList.stationsListHeader.stationsListHeaderOptions.prev");
    } else {
      return value;
    }
  }

  /**
   * @description remove filter and emit value
   * @param filter filter
   * @param value value of filter
   */
  removeFilter(filter: string, value: string): void {
    this.filterRemoved.emit({filter: filter, filterOption: value});
  }
}
