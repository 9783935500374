import { ActivatedRoute } from '@angular/router';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@igo2/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about-training',
  templateUrl: './about-training.component.html',
  styleUrls: ['./about-training.component.scss']
})
export class AboutTrainingComponent implements OnInit, OnDestroy {
  	@HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

	public isMobile: boolean = window.innerWidth >= 768 ? false : true;
	public fragment$$: Subscription;

  constructor(private _authService: AuthService, private _route: ActivatedRoute) { }

	get authenticated(): boolean {
		return this._authService.isAuthenticated();
	}

	ngOnInit(): void {
		this.fragment$$ = this._route.fragment.subscribe((fragment: string) => {
			const element: Element = document.querySelector("#" + fragment);
			if (element) {
				element.scrollIntoView();
			}
		});
	}

	ngOnDestroy(): void {
		this.fragment$$.unsubscribe();
	}
}
