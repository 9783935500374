import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { zip } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { userAgent } from '@igo2/utils';
import {
  LanguageService,
  ConfigService,
  MessageService
} from '@igo2/core';
import { AuthOptions, AuthService } from '@igo2/auth';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
//import { PwaService } from './services/pwa.service';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '@igo2/core';
import { WarningDialogComponent } from './pages/menu/menu-pages/my-account/warning-dialog/warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public authConfig: AuthOptions;
  private themeClass = 'qcca-theme';
  public hasHeader: boolean = true;
  public hasFooter: boolean = true;
  public hasMenu: boolean = false;
  private authenticate$$: Subscription;
  public showWarning: boolean = true;
  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  public authorizedProfile: boolean;

  dialogWidth(){
		if (this.isMobile){
			return '90vw';
		} else
		return '700px';
	}

  @ViewChild('searchBar', { read: ElementRef, static: true })
  searchBar: ElementRef;

  public _menuLock: string = "";

  sub: Subscription;
  get menuLock(): string {
      return this._menuLock;
  }
  set menuLock(value: string) {
      this._menuLock = value;
  }

  get publicUrl (): string {
    return environment.toPublic;
  }

  // mobile
  public mobileBreakPoint: string = '(min-width: 768px)';
  public Breakpoints = Breakpoints;
  public currentBreakpoint: string = '';
  public mobile: boolean;

  constructor(
    protected languageService: LanguageService,
    private configService: ConfigService,
    private renderer: Renderer2,
    private titleService: Title,
    private metaService: Meta,
    private messageService: MessageService,
    //private pwaService: PwaService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog
  ) {
      this.mobileBreakPoint = this.configService.getConfig('mobileBreakPoint') === undefined ? "'(min-width: 768px)'" :
        this.configService.getConfig('mobileBreakPoint');
        this.authConfig = this.configService.getConfig('auth');
      if (this.authService.hasAuthService) {
        this.authService.authenticate$.subscribe(() => {
          const tokenDecoded = this.authService.decodeToken() || {};
          if (tokenDecoded.user) {
            this.authService
              .getProfils()
              .subscribe(profils =>
                {
                  this.analyticsService.setUser(tokenDecoded.user, profils.profils);
                  for (let profil of profils.profils){
                    if (this.authConfig.profilsGuard.includes(profil)){
                      this.authorizedProfile = true;
                    }
                  }
                  if (this.authorizedProfile !== true){
                    this.openWarningDialog({ toDo: 'forbidden'});
                  }
                }
              );
          } else {
            this.analyticsService.setUser();
          }
        });
      } else {
        this.analyticsService.paq.push(['trackPageView']);
        this.analyticsService.paq.push(['enableLinkTracking']);
      }

      //this.pwaService.checkForUpdates();

      this.readTitleConfig();
      this.readThemeConfig();
      this.readDescriptionConfig();

      this.detectOldBrowser();

      this.hasHeader = this.configService.getConfig('header.hasHeader') === undefined ? false :
        this.configService.getConfig('header.hasHeader');

      this.hasFooter = this.configService.getConfig('hasFooter') === undefined ? false :
        this.configService.getConfig('hasFooter');

      this.hasMenu = this.configService.getConfig('hasMenu') === undefined ? false :
        this.configService.getConfig('hasMenu');

      if (this.hasAuthService) {
        this.authenticate$$ = this.authService.authenticate$.subscribe(auth => {
          this.menuLock = auth ? '' : 'none';
        });

        this.menuLock = this.authService.isAuthenticated() ? '' : 'none';
      }
    } // end constructor
  ngOnInit(): void {
    // RESPONSIVE BREAKPOINTS
    this.breakpoint$.subscribe(() =>
    this.breakpointChanged()
    );
  }

  get hasAuthService(): boolean {
    return this.authService.hasAuthService;
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  // Mobile
  public breakpointChanged() {
    if(this.breakpointObserver.isMatched('(min-width: 768px)')) { // this.mobileBreakPoint is used before its initialization
      this.currentBreakpoint = this.mobileBreakPoint;
      this.mobile = false;
    } else {
      this.mobile = true;
    }
  }

  readonly breakpoint$ = this.breakpointObserver
  .observe(this.mobileBreakPoint)
  .pipe(
    tap(() => {}),
    distinctUntilChanged()
  );

  private readTitleConfig() {
    this.languageService.translate.get(this.configService.getConfig('title')).subscribe(title => {
      if (title) {
        this.titleService.setTitle(title);
        this.metaService.addTag({ name: 'title', content: title });
      }
    });
  }

  private readThemeConfig() {
    const theme = this.configService.getConfig('theme') || this.themeClass;
    if (theme) {
      this.renderer.addClass(document.body, theme);
    }
  }

  private readDescriptionConfig() {
    const description = this.configService.getConfig('description');
    if (description) {
      this.metaService.addTag({ name: 'description', content: description });
    }
  }

  private detectOldBrowser() {
    const oldBrowser = userAgent.satisfies({
      ie: '<=11',
      chrome: '<64',
      firefox: '<60',
      safari: '<=11'
    });

    if (oldBrowser) {
      const translate = this.languageService.translate;
      const title$ = translate.get('oldBrowser.title');
      const message$ = translate.get('oldBrowser.message');
      zip(title$, message$)
        .pipe(
          map(([title, message]) => ({
            title,
            message
          }))
        )
        .subscribe((rep) =>
          this.messageService.alert(rep.message, rep.title, {
            timeOut: 15000
          })
        );
    }
  }

  openWarningDialog(dataToSend: {} ){ const dialogRef = this.dialog
    .open(WarningDialogComponent,
      {
        data: dataToSend,
        panelClass: 'confirmDialog',
        maxWidth: this.dialogWidth(),
        maxHeight: '80vh'
      } )
    .afterClosed().subscribe(response => {
        console.log('redirecting to ', this.publicUrl);
        this.authService.logout();
        window.location.href = this.publicUrl;
        dialogRef.unsubscribe();
      });
  }

  ngOnDestroy() {
    this.authService.logout();
    this.authenticate$$.unsubscribe();
  }

}
