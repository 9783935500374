<p *ngIf="station.e_plan_deau" class="attribute attribute-label">{{ station.e_plan_deau }}</p>
<a
    class="station-label"
    [matTooltip]="'stationsFiltersList.stationsList.stationsListList.stationsListListStation.details' | translate"
    matTooltipPosition="before"
    [routerLink]="station.id">Station {{ station.b_label }}
</a>
<div class="attribute">
    <p class="attribute-label">{{ 'stationsFiltersList.stationsList.stationsListList.stationsListListStation.location' | translate }} :&nbsp;</p>
    <p class="attribute attribute-value">{{ station.description ? station.description : 'stationsFiltersList.stationsList.stationsListList.stationsListListStation.na' | translate }}</p>
</div>
<div class="attribute">
    <p class="attribute-label">{{ 'stationsFiltersList.stationsList.stationsListList.stationsListListStation.state' | translate }} :&nbsp;</p>
    <img class="state-img" [src]="getStationStateLegend(station.a_etat_max)" width="15" height="15">
    <p class="attribute attribute-value">{{ getStationState(station.a_etat_max) | translate }}</p>
</div>
<div class="attribute">
    <p class="attribute-label">{{ 'trend.trend' | translate }} :&nbsp;</p>
    <p class="attribute-value">{{ station.trend_pct !== null && station.a_etat_max !== null ? getStationTrend(station.trend_pct) : 'stationsFiltersList.stationsList.stationsListList.stationsListListStation.na' | translate }}</p>
</div>
<div class="attribute">
    <p class="attribute-label" id="update">{{ 'stationsFiltersList.stationsList.stationsListList.stationsListListStation.update' | translate }} :&nbsp;</p>
    <p class="attribute-label" id="update">{{ dateIsValid(station.date_prise_valeur) ? (station.date_prise_valeur | date:getDateFormat()) : 'stationsFiltersList.stationsList.stationsListList.stationsListListStation.na' | translate }}</p>
</div>
