<div *ngIf="this.mapQueryClick && feature.properties.ids_station">
  <div class="query-content">
    <h3 class="igo-panel-title">{{ ('sideResult.title.station' | translate) + " " + (feature.properties.label ? feature.properties.label : 'sideResult.na' | translate) }}</h3>
    <div class="attribute">
      <p class="attribute-label">{{ 'sideResult.location' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.description ? feature.properties.description : 'sideResult.na' | translate }}</p>
    </div>
    <div class="attribute">
      <p class="attribute-label">{{ 'sideResult.body' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.plan_deau ? feature.properties.plan_deau : 'sideResult.na' | translate }}</p>
    </div>
    <div class="attribute" (click)="tooltipState.toggle()">
      <p class="attribute-label">{{ 'sideResult.state' | translate }} :&nbsp;</p>
      <img class="state-img" [src]="getStationStateLegend(feature.properties.etat)" width="15" height="15">
      <p class="attribute-value">{{ getStationState(feature.properties.etat) | translate }}</p>
      <div
        class="info-container"
        #tooltipState="matTooltip"
        [matTooltip]="getStationStateDescription(feature.properties.etat) | translate"
        [matTooltipPosition]="mobile ? 'above' : 'right'"
        [matTooltipClass]="mobile ? 'tooltip-above' : 'tooltip-right'"
        matTooltipShowDelay="100"
        matTooltipTouchGestures="auto">
        <mat-icon
          class="info"
          color="primary"
          svgIcon="information">
        </mat-icon>
      </div>
    </div>
    <div class="attribute">
      <p class="attribute-label">{{ 'trend.trend' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.etat !== -1 && feature.properties.tendance ? getStationTrend(feature.properties.tendance) : 'sideResult.na' | translate }}</p>
    </div>
    <div class="attribute">
      <p class="attribute-label">{{ 'sideResult.update' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.date_prise_valeur ? (changeTimeZone(feature.properties.date_prise_valeur) | date : getDateFormat()) : 'sideResult.na' | translate }}</p>
    </div>
    <div *ngIf="feature.properties.prev" style="display: inline-flex;align-items: center;">
        <span class="text" style="margin-right:8px; height:100%;">
          <img src="assets/legends/legend-stations/prevision.svg" style="height:1.25rem;margin: 0.5rem 0;">
        </span>
        <span class="text">{{ 'stationsDetails.withForecasts' | translate }}</span>
    </div>
  </div>
  <p>
    <a class='link' [routerLink]="['/stations', feature.properties.ids_station]">{{ 'sideResult.details' | translate }}</a>
  </p>
  <p>
    <a class='link' [routerLink]="['/nous-joindre']" [queryParams]="{station: feature.properties.label}">{{ ('sideResult.problem.problem' | translate) + ('sideResult.problem.station' | translate) }}</a>
  </p>
  <p>
    <a class='link' href="https://www.quebec.ca/securite-situations-urgence/urgences-sinistres-risques-naturels/inondation" target="_blank">{{ 'home.tiles.more.title' | translate }}
      <mat-icon class="arrow">open_in_new</mat-icon>
    </a>
  </p>
</div>

<div *ngIf="this.mapQueryClick && feature.properties.Troncon">
  <div class="query-content">
    <h3 class="igo-panel-title">{{ ('sideResult.title.section' | translate) + " " + feature.properties.Troncon }}</h3>
    <p style="margin:0">
      <span class="attribute-label">{{ ('sideResult.description.part1' | translate) }}</span>
      <span class="attribute-value">{{ timeofMaxPrevX ? (timeofMaxPrevX | date : getDateFormat()) : 'sideResult.na' | translate }}</span>
    </p> 
  </div>
  <div class="chart">
    <h6 class="chart-title" (click)="tooltipScenario.toggle()" >{{ (feature.properties.typePrevision === 'Q') ? ('sideResult.chart.q' | translate) : 'sideResult.chart.h' | translate }}
      <div 
        class="info-container"
        #tooltipScenario="matTooltip"
        [matTooltip]="feature.properties.typePrevision === 'Q' ? ('info.scenario-q' | translate) : ('info.scenario-h' | translate)"
        [matTooltipPosition]="mobile ? 'above' : 'right'"
        [matTooltipClass]="mobile ? 'tooltip-above' : 'tooltip-right'"
        matTooltipShowDelay="100"
        matTooltipTouchGestures="auto">
        <mat-icon
          class="info"
          color="primary"
          svgIcon="information">
        </mat-icon>
      </div>
    </h6>
    <div class="chart-container" style="position: relative; height:225px; width:270px; padding:0px!important;margin:auto!important">
      <canvas #waterChartCanvas [plugins]="waterChartPlugins"></canvas>
    </div>
  </div>

  <div class="query-content">
    <div class="attribute">
      <p class="attribute-label">{{ 'sideResult.body' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.planEau ? feature.properties.planEau : 'sideResult.na' | translate }}</p>
    </div>
    <div class="attribute">
      <p class="attribute-label">{{ 'sideResult.bassin' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.nomBassin ? feature.properties.nomBassin : 'sideResult.na' | translate }}</p>
    </div>
    <div class="attribute">
      <p class="attribute-label">{{ 'sideResult.update' | translate }} :&nbsp;</p>
      <p class="attribute-value">{{ feature.properties.DateHeureMAJPrevision ? (feature.properties.DateHeureMAJPrevision | date : getDateFormat()) : 'sideResult.na' | translate }}</p>
    </div>
  </div>
  <p>
    <a class='link' [routerLink]="['/nous-joindre']" [queryParams]="{section: feature.properties.Troncon}">{{ ('sideResult.problem.problem' | translate) + ('sideResult.problem.section' | translate) }}</a>
  </p>
  <p>
    <a class='link' href="https://www.quebec.ca/securite-situations-urgence/urgences-sinistres-risques-naturels/inondation" target="_blank">{{ 'home.tiles.more.title' | translate }}
      <mat-icon class="arrow">open_in_new</mat-icon>
    </a>
  </p>
  <p class="avis" *ngIf="feature.properties.messageParticulier">
    <span class="avis-icon avis-important">
      <mat-icon svgIcon="alert-outline"></mat-icon>
    </span>
    <span class="avis-content">
      <span class="avis-title">{{ 'sideResult.warning' | translate }}</span><br>
      <span class="avis-label">{{ feature.properties.messageParticulier }}</span>
    </span>
  </p>
</div>
