import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@igo2/auth';

@Injectable({
    providedIn: 'root'
})

export class NotifHttpOptionService {

  public httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Cache-Control': 'no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 3000 00:00:00 GMT'
    }),
    //responseType: 'application/json',
    withCredentials: true
    };

    public httpOptionsInedit = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 3000 00:00:00 GMT',
            'Access-Control-Allow-Methods': ['POST', 'GET', 'OPTIONS'],
            'Prefer': "return=representation"
        }),
        //responseType: 'application/json',
        //withCredentials: true
    };

    constructor(private http: HttpClient, public authService: AuthService,) { }

}
