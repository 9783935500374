import { Params } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  public queryParams: Params = {};

  constructor() { }

  getQueryParams(): Params {
    return this.queryParams;
  }

  setQueryParams(queryParams: Params): void {
    this.queryParams = queryParams;
  }
}
