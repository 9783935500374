import {
	Component,
	EventEmitter,
  	Input,
  	Output } from '@angular/core';

@Component({
  	selector: 'app-stations-list',
  	templateUrl: './stations-list.component.html',
  	styleUrls: ['./stations-list.component.scss']
})
export class StationsListComponent {
	@Input() selectedOptions: object;
  	@Input() filtersString: string;
	@Output() filterRemoved: EventEmitter<object> = new EventEmitter();
	@Output() currentSortChange: EventEmitter<string> = new EventEmitter();

	public currentSort: string; // current sort
	public numberOfStationsPerPage: number; // number of stations per page
	public numberOfStations: number; // number of stations
	public currentPage: number; // current page

	constructor() { }

	/**
	 * @description set number of stations
	 * @param numberOfStations number of stations
	 */
	setNumberOfStations(numberOfStations: number): void {
		this.numberOfStations = numberOfStations;
	}

	/**
	 * @description set current sort
	 * @param currentSort current sort
	 */
	setCurrentSort(currentSort: string): void {
		this.currentSort = currentSort;
		this.currentSortChange.emit(currentSort);
	}

	/**
	 * @description set number of stations per page
	 * @param numberOfStationsPerPage number of stations per page
	 */
	setNumberOfStationsPerPage(numberOfStationsPerPage: number): void {
		this.numberOfStationsPerPage = numberOfStationsPerPage;
	}

	/**
	 * @description set current page
	 * @param currentPage current page
	 */
	setCurrentPage(currentPage: number): void {
		this.currentPage = currentPage;
	}
}
