import { IgoLanguageModule } from '../../../language/language.module';//'@igo2/core';
import { LayerToggleComponent } from './layer-toggle.component';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion'; // mobile

import {
  IgoMapModule,
  IgoLayerModule,
  IgoFilterModule,
  IgoMetadataModule,
  IgoDownloadModule
} from '@igo2/geo';

@NgModule({
  imports: [
    IgoLanguageModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    IgoMapModule,
    IgoLayerModule,
    IgoFilterModule,
    IgoMetadataModule,
    IgoDownloadModule,
    CommonModule,
    MatExpansionModule
  ],
  exports: [LayerToggleComponent, MatButtonToggleModule],
  declarations: [LayerToggleComponent]
})
export class LayerToggleModule {}
