<div class="container">
	<div class="content">
		<ng-container *ngIf="!isMobile else mobileBreadcrumbs">
			<div class="breadcrumbs">
				<a class="breadcrumbs-element" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<p class="breadcrumbs-element-current">{{ 'about.title' | translate }}</p>
			</div>
		</ng-container>
		
		<ng-template #mobileBreadcrumbs>
			<div class="breadcrumbs">
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
				<a class="breadcrumbs-element-mobile" routerLink="">{{ 'breadcrumbs.home' | translate }}</a>
			</div>
		</ng-template>
	
		<div class="menu-about-map">
			<ng-container *ngIf="!isMobile else menuMobile">
				<div class="menu">
					<p class="menu-title">{{ 'about.menu.title' | translate }}</p>
					<a class="menu-option" routerLink="../../carte">{{ 'about.menu.map' | translate }}</a>
					<a class="menu-option" routerLink="../../stations">Stations</a>
                    <div class="training-header-no-border menu-selected"
						(click)="trainingOptionsShown = !trainingOptionsShown">
						<p class="training-header-title">{{ 'about.menu.training.title' | translate }}</p>
						<mat-icon color="primary">{{ !trainingOptionsShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="trainingOptionsShown"
						class="training-options">
						<a class="training-option" routerLink="../../formations/module-1">{{ 'about.menu.training.module1' | translate }}</a>
						<a class="training-option" routerLink="../../formations/module-2">{{ 'about.menu.training.module2' | translate }}</a>
						<a class="training-option" routerLink="../../formations/module-3">{{ 'about.menu.training.module3' | translate }}</a>
						<a class="training-option" routerLink="../../formations/module-4">{{ 'about.menu.training.module4' | translate }}</a>
						<p class="training-option-selected">{{ 'about.menu.training.module5' | translate }}</p>
					</div>
				</div>
			</ng-container>
			<ng-template #menuMobile>
				<div class="menu-mobile">
					<div class="menu-mobile-header"
						(click)="menuShown = !menuShown">
						<p class="menu-mobile-header-label">{{ 'about.menu.title' | translate }}</p>
						<mat-icon color="primary">{{ !menuShown ? 'expand_more' : 'expand_less' }}</mat-icon>
					</div>
					<div *ngIf="menuShown" class="menu-mobile-options">
                        <a class="menu-mobile-option" routerLink="../../carte">{{ 'about.menu.map' | translate }}</a>
						<a class="menu-mobile-option" routerLink="../../stations">Stations</a>
                        <div class="training-header"
							(click)="trainingOptionsShown = !trainingOptionsShown">
							<p class="menu-mobile-option-selected">{{ 'about.menu.training.title' | translate }}</p>
							<mat-icon color="primary">{{ !trainingOptionsShown ? 'expand_more' : 'expand_less' }}</mat-icon>
						</div>
						<div *ngIf="trainingOptionsShown"
							class="training-options">
							<a class="training-option" routerLink="../../formations/module-1">{{ 'about.menu.training.module1' | translate }}</a>
							<a class="training-option" routerLink="../../formations/module-2">{{ 'about.menu.training.module2' | translate }}</a>
							<a class="training-option" routerLink="../../formations/module-3">{{ 'about.menu.training.module3' | translate }}</a>
							<a class="training-option" routerLink="../../formations/module-4">{{ 'about.menu.training.module4' | translate }}</a>
							<p class="training-option-selected training-option-selected-mobile">{{ 'about.menu.training.module5' | translate }}</p>
						</div>
					</div>
				</div>
			</ng-template>
			<div class="about-map">
				<h1>{{ 'about.training.module5.title' | translate }}</h1>
				<p>{{ 'about.training.module5.intro.part1' | translate }}</p>
				<div class="info">
					<div class="info-left">
						<mat-icon class="info-icon" svgIcon="information-outline"></mat-icon>
					</div>
					<div class="info-right">
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.listTitle' | translate }}</b></p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point1.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point1.definition' | translate }}</p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point2.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point2.definition' | translate }}</p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point3.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point3.definition' | translate }}</p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point4.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point4.definition' | translate }}</p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point5.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point5.definition' | translate }}</p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point6.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point6.definition' | translate }}</p>
						<p class="info-text"><b>{{ 'about.training.module5.intro.part2.point7.term' | translate }} : </b>{{ 'about.training.module5.intro.part2.point7.definition' | translate }}</p>
					</div>
				</div>
				<p>{{ 'about.training.module5.intro.part3.subpart1' | translate }}
					<a class="link" href="https://youtu.be/NzoKBf_pjvI" target="_blank">{{ 'about.training.module5.intro.part3.subpart2' | translate }}
						<mat-icon class="link-icon">open_in_new</mat-icon>
					</a>
					{{ 'about.training.module5.intro.part3.subpart3' | translate }}
				</p>
				<iframe [width]="getIFrameWidth()" [height]="getIFrameHeight()" src="https://www.youtube.com/embed/NzoKBf_pjvI?si=B6LsI102ooItG6_e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				<div id="evaluation">
					<h2 class="evaluation-title">{{ 'about.training.evaluation.title' | translate }}</h2>
					<div class="question">
						<p class="question-question">{{ 'about.training.module5.evaluation.question1.question' | translate }}</p>
						<div class="question-options">
							<mat-checkbox class="question-option" value="a">{{ 'about.training.module5.evaluation.question1.choices.a' | translate }}</mat-checkbox>
							<mat-checkbox class="question-option" value="b">{{ 'about.training.module5.evaluation.question1.choices.b' | translate }}</mat-checkbox>
							<mat-checkbox class="question-option" value="c">{{ 'about.training.module5.evaluation.question1.choices.c' | translate }}</mat-checkbox>
						</div>
						<div *ngIf="showAnswers" class="answer-box">
							<div class="answer">
								<p class="answer-answer">{{ 'about.training.evaluation.answer' | translate }} :&nbsp;
									<span class="answer-answer-true">{{ 'about.training.module5.evaluation.question1.choices.ac' | translate }}</span>
								</p>
							</div>
							<p class="answer-description">{{ 'about.training.module5.evaluation.question1.answer' | translate }}</p>
						</div>
					</div>
					<div class="question">
						<p class="question-question">{{ 'about.training.module5.evaluation.question2.question' | translate }}</p>
						<mat-radio-group class="question-options">
							<mat-radio-button value="a">{{ 'about.training.module5.evaluation.question2.choices.a' | translate }}</mat-radio-button>
							<mat-radio-button value="b">{{ 'about.training.module5.evaluation.question2.choices.b' | translate }}</mat-radio-button>
							<mat-radio-button value="c">{{ 'about.training.module5.evaluation.question2.choices.c' | translate }}</mat-radio-button>
						</mat-radio-group>
						<div *ngIf="showAnswers" class="answer-box">
							<div class="answer">
								<p class="answer-answer">{{ 'about.training.evaluation.answer' | translate }} :&nbsp;
									<span class="answer-answer-true">{{ 'about.training.module5.evaluation.question2.choices.b' | translate }}</span>
								</p>
							</div>
							<p class="answer-description">{{ 'about.training.module5.evaluation.question2.answer' | translate }}</p>
						</div>
					</div>
					<div class="question">
						<p class="question-question">{{ 'about.training.module5.evaluation.question3.question' | translate }}</p>
						<mat-radio-group class="question-options">
							<mat-radio-button value="a">{{ 'about.training.module5.evaluation.question3.choices.a' | translate }}</mat-radio-button>
							<mat-radio-button value="b">{{ 'about.training.module5.evaluation.question3.choices.b' | translate }}</mat-radio-button>
						</mat-radio-group>
						<div *ngIf="showAnswers" class="answer-box">
							<div class="answer">
								<p class="answer-answer">{{ 'about.training.evaluation.answer' | translate }} :&nbsp;
									<span class="answer-answer-false">{{ 'about.training.module5.evaluation.question3.choices.b' | translate }}</span>
								</p>
							</div>
							<p class="answer-description">{{ 'about.training.module5.evaluation.question3.answer' | translate }}</p>
						</div>
					</div>
					<button *ngIf="!showAnswers" class="show-answer" (click)="showAnswers = true">{{ 'about.training.evaluation.submit' | translate }}</button>
				</div>
				<br>
				<app-shared-sequential-links
					[showNextLink]="false"
					[previousLinkText]="'about.training.module4.title' | translate"
					previousLinkUrl="/en-savoir-plus/formations/module-4">
				</app-shared-sequential-links>
			</div>
		</div>
		<app-shared-footer class="footer"></app-shared-footer>
	</div>
</div>
