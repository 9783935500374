<div class="tile" (mouseenter)="isHover = true" (mouseleave)="isHover = false">
    <div class="icon-title">
        <div class="icon-container">
            <img [attr.src]="icon" width="40" height="40">
        </div>
        <p class="title">{{ title }}</p>
    </div>
    <p class="description">{{ description }}</p>
    <ng-container *ngIf="isMobile || isHover else noArrow">
        <div class="arrow-container">
            <mat-icon class="arrow">arrow_forward</mat-icon>
        </div>
    </ng-container>
    <ng-template #noArrow>
        <div class="arrow-container-empty"></div>
    </ng-template>
</div>
