import { Subscription } from 'rxjs';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { emailConfirmValidator, defaultObjectOptionValidator } from './form-validation.directive';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthService } from '@igo2/auth';
import { HttpClient } from '@angular/common/http';
import { Object } from './contact.enum';
import { LanguageService } from '@igo2/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
	onResize() {
    this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  public formSubmitted: boolean = false;
  public formSubmitError: boolean = false;

  public contactForm: FormGroup;

  public recaptcha$$: Subscription;
  public submit$$: Subscription;

  public stationParam: string;
  public sectionParam: string;

  constructor(
    private _authService: AuthService,
    private _fb: FormBuilder,
    private _http: HttpClient,
    private _languageService: LanguageService,
    private _recaptchaV3Service: ReCaptchaV3Service,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.contactForm = this._fb.group({
      object: ['', [Validators.required, defaultObjectOptionValidator()]],
      station: [''],
      section: [''],
      message: ['', Validators.required],
      name: [''],
      email: ['', [Validators.required, Validators.email]],
      emailConfirm: ['', Validators.required]
    },
    {
      validators: emailConfirmValidator
    });

    const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
    if (element) {
      element.style.visibility = "visible";
    }

    this.stationParam = this._route.snapshot.queryParamMap.get("station");
    this.sectionParam = this._route.snapshot.queryParamMap.get("section");

    if (this.stationParam && !this.sectionParam) {
      this.object.setValue("1");
      this.station.setValue(this.stationParam);
    }

    if (this.sectionParam && !this.stationParam) {
      this.object.setValue("2");
      this.section.setValue(this.sectionParam);
    }
  }

  ngOnDestroy(): void {
    if (this.recaptcha$$) {
      this.recaptcha$$.unsubscribe();
    }

    if (this.submit$$) {
      this.submit$$.unsubscribe();
    }

    const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
    if (element) {
      element.style.visibility = "hidden";
    }
  }

  get object(): AbstractControl {
    return this.contactForm.get("object");
  }

  get station(): AbstractControl {
    return this.contactForm.get("station");
  }

  get section(): AbstractControl {
    return this.contactForm.get("section");
  }

  get message(): AbstractControl {
    return this.contactForm.get("message");
  }

  get name(): AbstractControl {
    return this.contactForm.get("name");
  }

  get email(): AbstractControl {
    return this.contactForm.get("email");
  }

  get emailConfirm(): AbstractControl {
    return this.contactForm.get("emailConfirm");
  }

  get authenticated(): boolean {
    return this._authService.isAuthenticated();
  }

  submit(): void {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.recaptcha$$ = this._recaptchaV3Service.execute("submitForm").subscribe((token: string) => {
        const body: object = {
          captchaResponse: token,
          object: this._languageService.translate.instant(Object[parseInt(this.object.value)]),
          objectId: this.object.value,
          station: this.station.value,
          section: this.section.value,
          message: this.message.value,
          name: this.name.value,
          email: this.emailConfirm.value
        };

        const bodyJSON: string = JSON.stringify(body);

        this.submit$$ = this._http.post(environment.recaptcha.urlEmail, bodyJSON).subscribe(response => {
          if (response["success"]) {
            this.formSubmitted = true;
            this.contactForm.reset();
          } else {
            this.formSubmitError = true;
          }
        });
      });
    }
  }
}
