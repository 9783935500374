import { HttpClient } from '@angular/common/http';
import { ParamsService } from './../../../../shared/params/params.service';
import { LanguageService } from '@igo2/core';
import { AuthService } from '@igo2/auth';
import { Component, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { State,
	StateDescription,
	StateLegend,
	ThresholdFlow,
	ThresholdFlowColor,
	ThresholdHeight,
	ThresholdHeightColor } from 'src/app/pages/shared/stations/stations.enum';
import { HttpOptions,
	Reading,
	StationForDetailsMetadata,
	StationForDetailsReadings,
	Threshold } from 'src/app/pages/shared/stations/stations.interface';
import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { dateIsValid } from 'src/app/pages/shared/stations/stations.utils';
import Chart from 'chart.js/auto';
import { ChartConfiguration, ChartDataset, Scale, Tooltip } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import jsPDF from 'jspdf';
import { Point } from 'geojson';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../../my-account/alert/alert.component';

Chart.register(zoomPlugin);

@Component({
	selector: 'app-stations-details',
	templateUrl: './stations-details.component.html',
	styleUrls: ['./stations-details.component.scss']
})
export class StationsDetailsComponent implements AfterViewInit, OnDestroy {
	@HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

	public isMobile: boolean = window.innerWidth >= 768 ? false : true;
	public stationId: string; // station id
	public stationDetailsMetadata: StationForDetailsMetadata; // station details metadata
	public stationDetailsMetadata$$: Subscription;
	public stationDetailsReadings: StationForDetailsReadings; // station details readings
	public stationDetailsReadings$$: Subscription;

	public maxValeurNiv: number;
	public minValeurNiv: number;
	public minMaxLT: boolean;

	public dataPresent: boolean = true; // whether data is present for a station or not

	public flowThresholdsShown: boolean = false; // whether the flow thresholds are shown or not
	public heightThresholdsShown: boolean = false; // whether the height thresholds are shown or not

	public ThresholdFlow = ThresholdFlow; // enum for flow thresholds
	public ThresholdHeight = ThresholdHeight; // enum for height thresholds
	public ThresholdFlowColor = ThresholdFlowColor; // enum for flow colors
	public ThresholdHeightColor = ThresholdHeightColor; //enum for height colors

	public dateIsValid = dateIsValid;

	public hydroFlow; // flow hydrograph
	public hydroHeight; // height hydrograph

	public stateNotNull: boolean = true;

	public flowDataPresent: boolean = true;
	public heightDataPresent: boolean = true;

	public coordinates$$: Subscription;

	get authenticated(): boolean {
		return this.authService.isAuthenticated();
	  }

	  hasAuthService() {
		return this.authService.hasAuthService;
	  }

	constructor(private _route: ActivatedRoute,
		private _router: Router,
		private _stationListService: StationListService,
		private _languageService: LanguageService,
		private _params: ParamsService,
		private _http: HttpClient,
		public authService: AuthService,
		public dialog: MatDialog) {
			this.authService = authService;
			const decodeToken = this.authService.decodeToken();
		}

	ngAfterViewInit(): void {
		// get station ID from route
		this.stationId = this._route.snapshot.paramMap.get("id");
		// get station details from station label
		this._getStationDetailsMetadata(this.stationId);

		// set position for hydrograph tooltip
		Tooltip.positioners.average = function(items, eventPosition) {
			if (eventPosition.y > this.chart.chartArea.bottom) {
				this.chart.tooltip.setActiveElements([], {x: -1000, y: -1000});
				this.chart.setActiveElements([]);
			}
			return {
				x: eventPosition.x,
				y: eventPosition.y
			};
		};
	}

	ngOnDestroy(): void {
		this.stationDetailsMetadata$$.unsubscribe();
		this.stationDetailsReadings$$.unsubscribe();

		if (this.coordinates$$) {
			this.coordinates$$.unsubscribe();
		}
	}

	/**
	 * @description get the station details metadata from a station label
	 * @param stationId station ID
	 */
	 private _getStationDetailsMetadata(stationId: string): void {
		const httpOptions: HttpOptions = {
			params: {
				id: "eq." + stationId
			}
		};

		this.stationDetailsMetadata$$ = this._stationListService.getStationDetailsMetadata(httpOptions)
			.subscribe((stationList: StationForDetailsMetadata[]) => {
			stationList.length ? this.stationDetailsMetadata = stationList[0] : this.dataPresent = false;

			this.stateNotNull = this.stationDetailsMetadata.etat_max !== null ? true : false;

			this._getStationDetailsReadings(this.stationId);
		});
	}

	/**
	 * @description get the station details readings from a station label
	 * @param stationId station ID
	 */
	 private _getStationDetailsReadings(stationId: string): void {
		const httpOptions: HttpOptions = {
			params: {
				id: "eq." + stationId
			}
		};

		this.stationDetailsReadings$$ = this._stationListService.getStationDetailsReadings(httpOptions)
			.subscribe((stationList: StationForDetailsReadings[]) => {
			stationList.length ? this.stationDetailsReadings = stationList[0] : this.dataPresent = false;

			if (this.stationDetailsReadings?.valeurs_deb || this.stationDetailsReadings?.valeurs_deb_prev) {
				const configHydroFlow: ChartConfiguration<"line", Reading[]> = this._createConfig("débit");
				const element: HTMLCanvasElement = document.getElementById("hydro-deb") as HTMLCanvasElement;
				this.hydroFlow = new Chart(element.getContext("2d"), configHydroFlow);

				const dataFlowObservations: Reading[] = this.stationDetailsReadings.valeurs_deb;
				const dataFlowForecasts: Reading[] = this.stationDetailsReadings.valeurs_deb_prev;
				const dataFlowForecastsMin: Reading[] = this.stationDetailsReadings.valeurs_deb_prev_min;
				const dataFlowForecastsMax: Reading[] = this.stationDetailsReadings.valeurs_deb_prev_max;
				const thresholdsFlow: Threshold[] = this.stationDetailsMetadata.seuils_deb;

				if (dataFlowObservations && this.stateNotNull) {
					const datasetFlowObservations: ChartDataset<"line", Reading[]> = this.createDataset(
						"débit", dataFlowObservations, true);

					this.hydroFlow.data.datasets.push(datasetFlowObservations);
				}

				if (dataFlowForecasts) {
					if (dataFlowForecastsMax) {
						const datasetFlowForecastsMax: ChartDataset<"line", Reading[]> = this.createDataset(
							"débit", dataFlowForecastsMax, false, false, true);

						this.hydroFlow.data.datasets.push(datasetFlowForecastsMax);
					}

					const datasetFlowForecasts: ChartDataset<"line", Reading[]> = this.createDataset("débit", dataFlowForecasts, false);

					this.hydroFlow.data.datasets.push(datasetFlowForecasts);

					if (dataFlowForecastsMin) {
						const datasetFlowForecastsMin: ChartDataset<"line", Reading[]> = this.createDataset(
							"débit", dataFlowForecastsMin, false, true);

						this.hydroFlow.data.datasets.push(datasetFlowForecastsMin);
					}
				}

				if (thresholdsFlow && (dataFlowObservations || dataFlowForecasts)) {
					for (const thresholdFlow of thresholdsFlow) {
						const dataThresholdsFlow: Reading[] = [];

						if (dataFlowObservations) {
							for (const reading of dataFlowObservations) {
								const r: Reading = {
									date_prise_valeur: reading.date_prise_valeur,
									valeur: thresholdFlow.valeur,
								};
								dataThresholdsFlow.push(r);
							}
						}

						if (dataFlowForecasts) {
							for (const reading of dataFlowForecasts) {
								const r: Reading = {
									date_prise_valeur: reading.date_prise_valeur,
									valeur: thresholdFlow.valeur
								};
								dataThresholdsFlow.push(r);
							}
						}

						const datasetThresholdsFlow = this.createDataset(thresholdFlow.type, dataThresholdsFlow);

						this.hydroFlow.data.datasets.push(datasetThresholdsFlow);
					}
				}

				this.hydroFlow.update();
			} else {
				this.flowDataPresent = false;
			}

			if (this.hydroFlow && this.hydroFlow["data"] && !this.hydroFlow["data"]["datasets"]?.length) {
				this.flowDataPresent = false;
			}

			if (this.stationDetailsReadings?.valeurs_niv || this.stationDetailsReadings?.valeurs_niv_prev) {
				this.maxValeurNiv = Math.max(...this.stationDetailsReadings.valeurs_niv.map(reading => reading.valeur));
				this.minValeurNiv = Math.min(...this.stationDetailsReadings.valeurs_niv.map(reading => reading.valeur));

				this.minMaxLT = this.maxValeurNiv - this.minValeurNiv < 0.3 ? true : false;

				const configHydroHeight: ChartConfiguration<"line", Reading[]> = this._createConfig("niveau");
				const element: HTMLCanvasElement = document.getElementById("hydro-niv") as HTMLCanvasElement;
				this.hydroHeight = new Chart(element.getContext("2d"), configHydroHeight);

				const dataHeightObservations: Reading[] = this.stationDetailsReadings.valeurs_niv;
				const dataHeightForecasts: Reading[] = this.stationDetailsReadings.valeurs_niv_prev;
				const dataHeightForecastsMin: Reading[] = this.stationDetailsReadings.valeurs_niv_prev_min;
				const dataHeightForecastsMax: Reading[] = this.stationDetailsReadings.valeurs_niv_prev_max;
				const thresholdsHeight: Threshold[] = this.stationDetailsMetadata.seuils_niv;

				if (dataHeightObservations && this.stateNotNull) {
					const datasetHeight: ChartDataset<"line", Reading[]> = this.createDataset("niveau", dataHeightObservations, true);

					this.hydroHeight.data.datasets.push(datasetHeight);
				}

				if (dataHeightForecasts) {
					if (dataHeightForecastsMax) {
						const datasetHeightForecastsMax: ChartDataset<"line", Reading[]> = this.createDataset(
							"niveau", dataHeightForecastsMax, false, false, false, false, true);

						this.hydroHeight.data.datasets.push(datasetHeightForecastsMax);
					}

					const datasetHeightForecasts: ChartDataset<"line", Reading[]> = this.createDataset(
						"niveau", dataHeightForecasts, false);

					this.hydroHeight.data.datasets.push(datasetHeightForecasts);

					if (dataHeightForecastsMin) {
						const datasetHeightForecastsMin: ChartDataset<"line", Reading[]> = this.createDataset(
							"niveau", dataHeightForecastsMin, false, false, false, true);

						this.hydroHeight.data.datasets.push(datasetHeightForecastsMin);
					}
				}

				if (thresholdsHeight && (dataHeightObservations || dataHeightForecasts)) {
					for (const thresholdHeight of thresholdsHeight) {
						const dataThresholdsHeight: Reading[] = [];

						if (dataHeightObservations) {
							for (const reading of dataHeightObservations) {
								const r: Reading = {
									date_prise_valeur: reading.date_prise_valeur,
									valeur: thresholdHeight.valeur
								};
								dataThresholdsHeight.push(r);
							}
						}

						if (dataHeightForecasts) {
							for (const reading of dataHeightForecasts) {
								const r: Reading = {
									date_prise_valeur: reading.date_prise_valeur,
									valeur: thresholdHeight.valeur
								};
								dataThresholdsHeight.push(r);
							}
						}

						const datasetThresholdsHeight = this.createDataset(thresholdHeight.type, dataThresholdsHeight);

						this.hydroHeight.data.datasets.push(datasetThresholdsHeight);
					}
				}

				this.hydroHeight.update();
			} else {
				this.heightDataPresent = false;
			}

			if (this.hydroHeight && this.hydroHeight["data"] && !this.hydroHeight["data"]["datasets"]?.length) {
				this.heightDataPresent = false;
			}
		});
	}

	/**
	 * @description get the state legend image
	 * @param stateValue numeric value of a state
	 * @returns corresponding legend of the state
	 */
	getStationStateLegend(stateValue: number): string {
		return StateLegend[stateValue];
	}

	/**
	 * @description get the state label
	 * @param stateValue numeric value of a state
	 * @returns corresponding label of the state
	 */
	getStationState(stateValue: number): string {
		return State[stateValue];
	}

	/**
	 * @description get the state description
	 * @param stateValue numeric value of a state
	 * @returns corresponding description of the state
	 */
	getStationStateDescription(stateValue: number) {
		if (stateValue === null || (stateValue >= 0 && stateValue <= 3)) {
		  return StateDescription[stateValue];
		} else if (stateValue === 4) {
		  return this._languageService.translate.instant("about.stations.state.minor.description.intro") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.minor.description.roads") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.minor.description.inhabitedAreas") + "\n";
		} else if (stateValue === 5) {
		  return this._languageService.translate.instant("about.stations.state.medium.description.intro") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.medium.description.inhabitedAreas") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.medium.description.sewers") + "\n";
		} else if (stateValue === 6) {
		  return this._languageService.translate.instant("about.stations.state.major.description.intro") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.major.description.inhabitedAreas") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.major.description.largeAreas") + "\n" +
			"• " + this._languageService.translate.instant("about.stations.state.major.description.roads") + "\n";
		}
	  }

	/**
	 * @description get the external URL for a station
	 * @returns string representing the URL
	 */
	getUrl(): string {
		const provider: string = this.stationDetailsMetadata?.fournisseur_nom;
		const url: string = this.stationDetailsMetadata?.fournisseur_url;
		const label: string = this.stationDetailsMetadata?.label;
		if (provider !== "Hydro Météo" && provider !== "Hydro-Québec" && provider !== "Ville de Montréal") {
			return url + label;
		}
		return url;
	}

	/**
	 * @description go to map on click and center on station
	 */
	goToMap(): void {
		const center_3857: string = this.stationDetailsMetadata.geom_p.coordinates[0] +
			"," + this.stationDetailsMetadata.geom_p.coordinates[1];
		let center_4326: string;
		this.coordinates$$ = this._http.get("https://geoegl.msp.gouv.qc.ca/apis/terrapi/geospatial/project", {
			params: {
				loc: center_3857,
				from: "EPSG:3857",
				to: "EPSG:4326"
			}
		}).subscribe((point_4326: Point) => {
			center_4326 = point_4326.coordinates[0].toFixed(5) + "," + point_4326.coordinates[1].toFixed(5);
			this._router.navigate(["/carte"], {
				queryParams: {
					center: center_4326,
					zoom: 17
				}
			});
		});
	}

	/**
	 * @description get query params from service
	 */
	getQueryParams(): Params {
		return this._params.getQueryParams();
	}

	/**
	 * @description create the configs for an hydrograph
	 * @param type type of data (debit, niveau, smg-debit, etc.)
	 * @returns configs of the hydrograph
	 */
	private _createConfig(type: string): ChartConfiguration<"line", Reading[]> {
		const config: ChartConfiguration<"line", Reading[]> = {
			data: {
				datasets: []
			},
			options: {
				animation: {
					duration: 0
				},
				elements: {
					line: {
						borderCapStyle: "round",
						borderJoinStyle: "round",
						borderWidth: 2
					},
					point: {
						radius: 0
					}
				},
				events: ['mousemove', 'mouseout', 'click', 'touchstart'],
				interaction: {
					axis: "x",
					intersect: false,
					mode: "nearest"
				},
				locale: this.getLanguage(),
				maintainAspectRatio: false,
				parsing: {
					xAxisKey: "date_prise_valeur",
					yAxisKey: "valeur"
				},
				plugins: {
					legend: {
						align: "start",
						labels: {
							boxHeight: 18,
							color: "#223654",
							filter: (item, data) => {
								return item.text.includes("75") || item.text.includes("97,5") ? false : true;
							},
							font: {
								family: "'Roboto', sans-serif"
							},
							padding: 16,
							pointStyle: "rect",
							usePointStyle: false
						},
						onClick: (event, legendItem, legend) => {
							legendItem.hidden = !legendItem.hidden;

							if (legendItem.text === this._languageService.translate.instant("stationsDetails.forecasts.label.flow") ||
								legendItem.text === this._languageService.translate.instant("stationsDetails.forecasts.label.level")) {
								for (const dataset of legend.chart.data.datasets) {
									if (dataset.label === this._languageService.translate.instant("stationsDetails.forecasts.label.flow") ||
									dataset.label === this._languageService.translate.instant("stationsDetails.forecasts.label.level") ||
									dataset.label.includes("75") ||
									dataset.label.includes("97,5") || legendItem.text === dataset.label) {
										dataset.hidden = !dataset.hidden;
									}
								}
							} else {
								for (const dataset of legend.chart.data.datasets) {
									if (legendItem.text === dataset.label) {
										dataset.hidden = !dataset.hidden;
									}
								}
							}
							legend.chart.update();
						},
						position: "bottom"
					},
					subtitle: {
						color: "#223654",
						font: {
							family: "'Roboto', sans-serif",
							size: 12
						},
						text: this.getLanguage().includes("fr") ? format(new Date(), "yyyy-MM-dd H:mm") :
						format(new Date(), "yyyy-MM-dd h:mm a"),
					},
					title: {
						color: "#223654",
						font: {
							family: "'Roboto', sans-serif",
							size: 14
						},
						text: "Station " + this.stationDetailsMetadata?.label + " - " + this.stationDetailsMetadata?.description
					},
					tooltip: {
						backgroundColor: "rgba(255,255,255,0.75)",
						bodyFont: {
							family: "'Open Sans', sans-serif"
						},
						borderColor: "#063665",
						borderWidth: 1,
						callbacks: {
							label: tooltipItem => {
								let label: string = tooltipItem.dataset.label + ": " +
									tooltipItem.raw["valeur"].toFixed(2).replace(".", ",");
								if (tooltipItem.dataset.label === this._languageService.translate
									.instant("stationsDetails.forecasts.label.flow")) {
									label = this._languageService.translate.instant("stationsDetails.forecasts.25") +
										tooltipItem.raw["valeur"].toFixed(2).replace(".", ",");
								}
								if (tooltipItem.dataset.label === this._languageService.translate
									.instant("stationsDetails.forecasts.label.level")) {
									label = this._languageService.translate.instant("stationsDetails.forecasts.95.lower") +
										tooltipItem.raw["valeur"].toFixed(2).replace(".", ",");
								}
								if (tooltipItem.chart.canvas.id === "hydro-niv") {
									return label + " m";
								} else {
									return label + " m³/s";
								}
							},
							labelTextColor: tooltipItem => {
								if (tooltipItem.dataset.label.includes(this._languageService.translate.instant("stationsDetails.flow")) ||
									tooltipItem.dataset.label.includes(this._languageService.translate.instant("stationsDetails.level")) ||
									tooltipItem.dataset.label === this._languageService.translate.instant("stationsDetails.forecasts.75") ||
									tooltipItem.dataset.label === this._languageService.translate
										.instant("stationsDetails.forecasts.95.upper") ||
									tooltipItem.dataset.label === this._languageService.translate
										.instant("stationsDetails.forecasts.label.flow") ||
									tooltipItem.dataset.label === this._languageService.translate
										.instant("stationsDetails.forecasts.label.level")) {
									return "#063665";
								} else {
									return "#6194BF";
								}
							}
						},
						cornerRadius: 0,
						displayColors: false,
						titleColor: "#063665",
						titleFont: {
							family: "'Roboto', sans-serif"
						},
						yAlign: "center"
					},
					zoom: {
						limits: {
							x: {
								min: "original",
								max: "original"
							},
							y: {
								min: "original",
								max: "original"
							}
						},
						pan: {
							enabled: this.isMobile ? false : true
						},
						zoom: {
							pinch: {
								enabled: this.isMobile ? false : true
							},
							wheel: {
								enabled: this.isMobile ? false : true,
								modifierKey: "ctrl"
							}
						}
					}
				},
				scales: {
					x: {
						adapters: {
							date: {
								locale: this.getLanguage().includes("fr") ? frCA : enCA
							}
						},
						ticks: {
							font: {
								family: "'Roboto', sans-serif"
							}
						},
						time: {
							displayFormats: {
								day: this.getLanguage().includes("fr") ? "d MMM" : "MMM d"
							},
							tooltipFormat: this.getLanguage().includes("fr") ? "d MMM yyyy, H:mm" : "MMM d yyyy, h:mm a",
							unit: "day"
						},
						title: {
							display: true,
							font: {
								family: "'Roboto', sans-serif"
							},
							text: "Date"
						},
						type: "time"
					},
					y: {
						beginAtZero: type === "débit" ? true : false,
						max: type === "niveau" && !this.stationDetailsMetadata?.seuils_niv ?
							(this.minMaxLT ? this.minValeurNiv + 0.3 : undefined) : undefined,
						ticks: {
							font: {
								family: "'Roboto', sans-serif"
							}
						},
						title: {
							display: true,
							font: {
								family: "'Roboto', sans-serif"
							},
							text: type === "débit" ? this._languageService.translate.instant("stationsDetails.flow") + " (m³/s)" :
							this._languageService.translate.instant("stationsDetails.level") + " (m)"
						}
					}
				}
			},
			plugins: [{
				id: "1",
				afterDraw: (chart, args, options) => {
					const caretX: number = chart.tooltip.caretX;
					const yAxis: Scale = chart.scales.y;
					let ctx: CanvasRenderingContext2D = chart.ctx;

					if (caretX <= chart.chartArea.right &&
						caretX >= chart.chartArea.left &&
						chart.tooltip.caretY >= chart.chartArea.top &&
						chart.tooltip.caretY <= chart.chartArea.bottom) {
						if (chart.tooltip?.dataPoints[0]?.element?.x) {
							const x: number = chart.tooltip.dataPoints[0].element.x;
							ctx.save();
							ctx.beginPath();
							ctx.moveTo(x, yAxis.top);
							ctx.lineTo(x, yAxis.bottom);
							ctx.lineWidth = 1;
							ctx.strokeStyle = "rgba(100,100,100,1)";
							ctx.stroke();
							ctx.restore();
						}
					}
				}
			},
			{
				id: "2",
				beforeDraw: (chart, args, options) => {
					const {ctx} = chart;
					ctx.save();
					ctx.globalCompositeOperation = "destination-over";
					ctx.fillStyle = "white";
					ctx.fillRect(0, 0, chart.width, chart.height);
					ctx.restore();
				}
			}],
			type: "line",
		};

		return config;
	}

	/**
	 * @description create a dataset for an hydrograph
	 * @param type type of data (debit, niveau, smg-debit, etc.)
	 * @param data data
	 * @param areObservations boolean representing if the data represents observations (true) or forecasts (false)
	 * @param areMinPercentiles boolean representing if the data represents 25th centile forecasts
	 * @param areMaxPercentiles boolean representing if the data represents 75th centile forecasts
	 * @param areMinIntervals boolean representing if the data represent the lower limit of forecasts, at 95%
	 * @param areMaxIntervals boolean representing if the data represent the upper limit of forecasts, at 95%
	 * @returns an object representing the dataset
	 */
	createDataset(
		type: string,
		data: Reading[],
		areObservations?: boolean,
		areMinPercentiles?: boolean,
		areMaxPercentiles?: boolean,
		areMinIntervals?: boolean,
		areMaxIntervals?: boolean): ChartDataset<"line", Reading[]> {
		const seuilsDebit: string[] = ["simaj-debit", "simoy-debit", "sim-deb", "spa-deb", "smg-debit"];

		let label: string;

		if (type === "débit") {
			label = this._languageService.translate.instant("stationsDetails.flow");
		} else if (type === "niveau") {
			label = this._languageService.translate.instant("stationsDetails.level");
		} else if (seuilsDebit.includes(type)) {
			label = this._languageService.translate.instant(ThresholdFlow[type]);
		} else {
			label = this._languageService.translate.instant(ThresholdHeight[type]);
		}

		if (areObservations) {
			label = label + " (observations)";
		} else if (areObservations === false) {
			label = label + " (" + this._languageService.translate.instant("stationsDetails.forecasts.forecasts") + ")";
		}

		if (areMinPercentiles) {
			label = this._languageService.translate.instant("stationsDetails.forecasts.label.flow");
		} else if (areMaxPercentiles) {
			label = this._languageService.translate.instant("stationsDetails.forecasts.75");
		}

		if (areMinIntervals) {
			label = this._languageService.translate.instant("stationsDetails.forecasts.label.level");
		} else if (areMaxIntervals) {
			label = this._languageService.translate.instant("stationsDetails.forecasts.95.upper");
		}

		let borderColor: string;

		if (type === "débit" || type === "niveau") {
			if (areObservations) {
				borderColor = "#063665";
			} else if (!areMinPercentiles && !areMaxPercentiles && !areMinIntervals && !areMaxIntervals) {
				borderColor = "#095797";
			} else {
				borderColor = "#00000000";
			}
		} else {
			if (seuilsDebit.includes(type)) {
				borderColor = ThresholdFlowColor[type];
			} else {
				borderColor = ThresholdHeightColor[type];
			}
		}

		let backgroundColor: string;

		if (!areMinPercentiles && !areMaxPercentiles && !areMinIntervals && !areMaxIntervals) {
			backgroundColor = "#FFFFFF00";
		} else {
			backgroundColor = "#09579715";
		}

		let fill: any;

		if (areMinPercentiles || areMinIntervals) {
			fill = "-2";
		} else if (areMaxPercentiles || areMaxIntervals) {
			fill = "+2";
		}

		const dataset: ChartDataset<"line", Reading[]> = {
			borderColor: borderColor,
			backgroundColor: backgroundColor,
			data: data,
			fill: fill,
			label: label
		};

		if (areObservations === false) {
			dataset["borderDash"] = [3, 3];
		} else if (type !== "débit" && type !== "niveau") {
			dataset["borderDash"] = [8, 5];
		}

		return dataset;
	}

	/**
	 * @description reset zoom of a hydrograph
	 * @param hydrograph hydrograph
	 */
	zoomExtent(hydrograph: Chart) {
		hydrograph.resetZoom();
	}

	/**
	 * @description download an image of a specified canvas
	 * @param canvasId string representing the id of the canvas
	 * @param hydrograph hydrograph
	 */
	downloadImage(canvasId: string, hydrograph: Chart): void {
		// show title and subtitle for export
		this._showHideTitleSubtitle(hydrograph);

		setTimeout(() => {
			const imageLink: HTMLAnchorElement = document.createElement("a");
			const canvas: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement;
			imageLink.download = this._createFileName(canvasId, ".jpeg");
			imageLink.href = canvas.toDataURL("image/jpeg", 1);
			imageLink.click();

			// hide title and subtitle for export
			this._showHideTitleSubtitle(hydrograph);
		}, 0);
	}

	/**
	 * @description download a PDF of a specified canvas
	 * @param canvasId string representing the id of the canvas
	 * @param hydrograph hydrograph
	 */
	downloadPDF(canvasId: string, hydrograph: Chart): void {
		// show title and subtitle for export
		this._showHideTitleSubtitle(hydrograph);

		setTimeout(() => {
			const canvas: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement;
			const canvasURL: string = canvas.toDataURL();

			const canvasWidth: number = canvas.width;
			const canvasHeight: number = canvas.height;

			let pdf: jsPDF;

			if (canvasWidth >= canvasHeight) {
				pdf = new jsPDF("l", "px", [canvasWidth, canvasHeight]);
			} else {
				pdf = new jsPDF("p", "px", [canvasWidth, canvasHeight]);
			}

			pdf.addImage(canvasURL, "PNG", 0, 0, canvasWidth, canvasHeight);
			pdf.save(this._createFileName(canvasId, ".pdf"));

			// hide title and subtitle for export
			this._showHideTitleSubtitle(hydrograph);
		}, 0);
	}

	/**
	 * @description show or hide title and subtitle of a hydrograph
	 * @param hydrograph hydrograph
	 */
	private _showHideTitleSubtitle(hydrograph: Chart): void {
		hydrograph.options.plugins.title.display = !hydrograph.options.plugins.title.display;
		hydrograph.options.plugins.subtitle.display = !hydrograph.options.plugins.subtitle.display;
		hydrograph.update();
	}

	/**
	 * @description create file name for JPEG or PDF file export;
	 * @param canvasId string representing the id of the canvas
	 * @param exportFormat format of file (.jpeg or .pdf)
	 * @returns string representing the file's name
	 */
	private _createFileName(canvasId: string, exportFormat: ".jpeg" | ".pdf"): string {
		return canvasId + "_" + this.stationDetailsMetadata.label + "_" + format(new Date(), "yyyyMMdd_HHmm") + exportFormat;
	}

	/**
	 * @description get date format
	 * @returns date format
	 */
	getDateFormat(): string {
		if (this.getLanguage().includes("fr")) {
			return "d MMMM y, H:mm";
		} else {
			return "MMMM d y, h:mm a";
		}
	}

	/**
	 * @description get browser language
	 * @returns language
	 */
	getLanguage(): string {
		// return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language
		return "fr";
	}

	modifyAlerts() {
		let dialogRef = this.dialog.open(AlertComponent, {
			data: { stationId: this.stationId, shouldReload: false },
			panelClass: 'alertDialog',
        	position: this.dialogPosition(),
			maxWidth: this.dialogWidth(),
			maxHeight: '80vh',
			disableClose: true
		  });
		dialogRef.afterClosed().subscribe(result => {
			this.ngOnDestroy();
			//this._router.navigateByUrl('/DummyComponent', { skipLocationChange: true });
			setTimeout(() => {
				//this.ngAfterViewInit();
				this._router.navigate(["/stations/" + this.stationId]);
			  }, 1000);
		});
	}

	dialogPosition(){
		if (this.isMobile){
			return {top: '10vh'};
		} else
		return {top: '15vh'};
	}

	dialogWidth(){
		if (this.isMobile){
			console.log('mobile');
			return '90vw';
		} else
		return '700px';
	}

}
