import { Component, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { HttpOptions, StationForList } from 'src/app/pages/shared/stations/stations.interface';

import { StationListService } from 'src/app/pages/shared/stations/stations.service';

@Component({
  selector: 'app-stations-list-list',
  templateUrl: './stations-list-list.component.html',
  styleUrls: ['./stations-list-list.component.scss']
})
export class StationsListListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentSort: string; // current sort
  @Input() currentPage: number; // current page
  @Input() numberOfStationsPerPage: number; // number of stations per page
  @Input() filtersString: string; // filters string
  @Output() numberOfStationsChange: EventEmitter<number> = new EventEmitter(); // emitted when number of stations has changed

  public stationList: StationForList[] = []; // station list

  public numberOfStations$: BehaviorSubject<number> = new BehaviorSubject(undefined); // number of stations
  public numberOfStations$$: Subscription; // subscription to number of stations

  public stationList$$: Subscription;
  public stationListCount$$: Subscription;

  constructor(private _stationListService: StationListService) { }

  ngOnInit(): void {
    // subscription to number of stations
    this.numberOfStations$$ = this.numberOfStations$.subscribe((numberOfStations: number) => {
      this.numberOfStationsChange.emit(numberOfStations);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if current sort / number of stations per page / current page / filters string has changed...
    if (changes.currentSort) {
      // ...update station list
      this.getStationList(changes.currentSort.currentValue, this.numberOfStationsPerPage,
        this.currentPage, this.filtersString);
    } else if (changes.numberOfStationsPerPage) {
      this.getStationList(this.currentSort, changes.numberOfStationsPerPage.currentValue,
        this.currentPage, this.filtersString);
    } else if (changes.currentPage) {
      this.getStationList(this.currentSort, this.numberOfStationsPerPage,
        changes.currentPage.currentValue, this.filtersString);
    } else if (changes.filtersString) {
      this.getStationList(this.currentSort, this.numberOfStationsPerPage,
        this.currentPage, changes.filtersString.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.numberOfStations$$.unsubscribe();
    if (this.stationListCount$$) {
      this.stationListCount$$.unsubscribe();
    }
    this.stationList$$.unsubscribe();
  }

  /**
   * @description get number of stations
   * @param filtersString filters string
   */
  getNumberOfStations(filtersString?: string): void {
    const options: HttpOptions = {
      params: {}
    };

    if (filtersString !== "()" && filtersString !== undefined) {
      options.params["and"] = filtersString;

      this.stationListCount$$ = this._stationListService.getStationList(options).subscribe((stationList: StationForList[]) => {
        this.numberOfStations$.next(stationList.length);
      });
    } else {
      this.stationListCount$$ = this._stationListService.getStationCount().subscribe((count: any[]) => {
        this.numberOfStations$.next(count[0].count);
      });
    }
  }

  /**
   * @description get station list from API
   * @param currentSort current sort
   * @param numberOfStationsPerPage number of stations per page
   * @param currentPage current page
   * @param filtersString filters string
   */
  getStationList(currentSort: string, numberOfStationsPerPage: number, currentPage: number, filtersString: string): void {
    // set options
    const options: HttpOptions = {
      params: {
        order: currentSort,
        limit: numberOfStationsPerPage,
        offset: (currentPage - 1) * numberOfStationsPerPage
      }
    };

    // set filters string if there are any selected filters
    if (filtersString !== "()" && filtersString !== undefined) {
      options.params["and"] = filtersString;
    }

    // make call to API
    this.stationList$$ = this._stationListService.getStationList(options).subscribe((stationList: StationForList[]) => {
      this.stationList = stationList;

      this.getNumberOfStations(filtersString);
    });
  }
}

