export class FormModel {
    EndpointRequest: {
        Address: string;
        Attributes?: {
            stationIDField: string[];
            };
        ChannelType: string;
        OptOut: string;
    } = {
        Address: '',
        Attributes: {
            stationIDField: []
        },
        ChannelType: '',
        OptOut: ''
    };
}

export class FormAttributes {
    Attributes: {
        stationIDField: string[];
    } = {
        stationIDField: ['']
    };
}
