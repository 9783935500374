import { HomeTileComponent } from './home-tile/home-tile.component';
import { HomeComponent } from './home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { IgoLanguageModule } from '../../../../language/language.module';//'@igo2/core';

import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

import { SharedComponentsModule } from 'src/app/pages/shared/shared-components.module';

@NgModule({
  declarations: [HomeComponent,
    HomeTileComponent],
  imports: [
    CommonModule,
    RouterModule,
    IgoLanguageModule,
    MatIconModule,
    MatDividerModule,
    SharedComponentsModule
  ],
  exports: [HomeComponent]
})
export class HomeModule { }
