<div id="sideresultsDesktop" class="sideresult">
  <mat-sidenav 
    #sidenav
    mode="side"
    [autoFocus]="false"
    [(opened)]="panelOpenState"
    (closed)= "closePanelLegend()"
    (legendPanelOpened)="legendPanelOpened"
    (closeLegend)="closePanelLegend()">

    <div #content class="app-sidenav-content">

      <div id="legend" [mapQueryClick]="mapQueryClick" [legendPanelOpened]="legendPanelOpened" [panelOpenState]="panelOpenState"  *ngIf="legendPanelOpened === true">
        <button
          mat-icon-button
          [matTooltip]="'legend.legendClose' | translate"
          color="primary"
          (click)="closePanelLegend()"><mat-icon svgIcon="close"></mat-icon>
        </button>
        <h4 mat-dialog-title>{{ 'legend.legendButton' | translate }}</h4>
        <igo-layer-legend-list
          [layers]="mapLayersShownInLegend"
          [excludeBaseLayers]="true"
          [allowShowAllLegends]="false"
          [updateLegendOnResolutionChange]="true"
          [showAllLegendsValue]="false"
          [layers]="layers">{{layers}}
        </igo-layer-legend-list>
      </div>

      <div style="margin: 24px;" *ngIf="searchInit === false && (queryStore.empty$ | async)===true && legendPanelOpened === false">
        <section class="mat-typography">
          <h5><strong>{{ 'igo.integration.searchResultsTool.noResults' | translate }}</strong></h5>
          <h6><strong>{{ 'igo.integration.searchResultsTool.doSearch' | translate }}</strong></h6>
          <p [innerHTML]="'igo.integration.searchResultsTool.examples' | translate"></p>
      </section>
      </div>

      <div #getInfo *ngIf="mapQueryClick === true">

        <app-feature-info
          [map]="map"
          [mobile]="mobile"
          [store]="queryStore"
          [mapQueryClick]="mapQueryClick"
          [searchInit]="searchInit"
          (closeQuery)="closePanelOnCloseQuery($event)"
          (sidenavClosed)="$event"
          (pleaseCloseSidenav)="yesICloseSidenav($event)"
          [hasFeatureEmphasisOnSelection]="hasFeatureEmphasisOnSelection"
          [scenarioDateToggle]="scenarioDateToggle">
        </app-feature-info>

      </div>

      <div #search *ngIf="searchInit === true">
          <app-search-results-tool 
            #searchresults
            [showIcons]="true"
            placeholder="false"
            [mapQueryClick]="mapQueryClick"
            [searchInit]="searchInit"
            (resultFocus)="onResultFocus($event)"
            (resultSelect)="onResultFocus($event)"
            (moreResults)="onSearch($event)">
          </app-search-results-tool>
    </div></div>

  </mat-sidenav>
  <button
    [ngClass]="sidenav.opened ? 'sideresult-opened' : 'sideresult-closed'"
    #sidenavbutton
    mat-raised-button
    color="primary"
    id="sideresult-button"
    isRoundButton=false
    isIconButton=true
    MatDrawerMode="push"
    [matTooltip]="sidenav.opened ? ('sideResult.close' | translate) : 'sideResult.open' | translate"
    matTooltipPosition="after"
    (click)="sidenav.toggle()">
    <mat-icon
      class="icon"
      [svgIcon]="sidenav.opened ? 'chevron-left' : 'chevron-right'">
    </mat-icon>
  </button>
</div>