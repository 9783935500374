import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { StationForList } from 'src/app/pages/shared/stations/stations.interface';
import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { State, StateLegend } from 'src/app/pages/shared/stations/stations.enum';
import { format } from 'date-fns';
import { frCA, enCA } from 'date-fns/locale';
import { AuthService } from '@igo2/auth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  public isHover: boolean = false;
  public stationList: StationForList[] = [];
  public stationCounts: number[] = [];

  public stationList$$: Subscription;

  constructor(private _authService: AuthService, private _stationListService: StationListService) { }

  get authenticated(): boolean {
    return this._authService.isAuthenticated();
  }

  ngOnInit(): void {
    this.stationList$$ = this._stationListService.getStationList().subscribe((stationList: StationForList[]) => {
      this.stationList = stationList;

      for (let i = 0; i < 7; i++) {
        this.stationCounts.push(this.stationList.filter(station => station.a_etat_max === i).length);
      }
      this.stationCounts.unshift(this.stationList.filter(station => station.a_etat_max === null).length);
      this.stationCounts.reverse();
    });
  }

  ngOnDestroy(): void {
    this.stationList$$.unsubscribe();
  }

  /**
	 * @description get the state legend image
	 * @param stateValue numeric value of a state
	 * @returns corresponding legend of the state
	 */
	getStationStateLegend(stateValue: number): string {
		return StateLegend[stateValue];
	}

	/**
	 * @description get the state label
	 * @param stateValue numeric value of a state
	 * @returns corresponding label of the state
	 */
	getStationState(stateValue: number): string {
		return State[stateValue];
	}

  /**
	 * @description get browser language
	 * @returns language
	 */
  getLanguage(): string {
		// return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
    return "fr";
	}

  getDateTime(): string {
    let dateTime: string;
    this.getLanguage().includes("fr") ? dateTime = format(new Date(), "d MMMM yyyy, H:mm", {locale: frCA}) :
		  dateTime = format(new Date(), "MMMM d yyyy, h:mm a", {locale: enCA});

    return dateTime;
  }
}
