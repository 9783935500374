import { IgoLanguageModule } from '../../../../../language/language.module';//'@igo2/core';
import { RouterModule } from '@angular/router';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StationsFiltersListComponent } from './stations-filters-list.component';

import { StationsFiltersComponent } from './../stations-filters-list/stations-filters/stations-filters.component';
import { StationsFiltersFilterComponent } from './stations-filters/stations-filters-filter/stations-filters-filter.component';

import { StationsListComponent } from './stations-list/stations-list.component';
import {
	StationsListListStationComponent }
	from './stations-list/stations-list-list/stations-list-list-station/stations-list-list-station.component';
import { StationsListHeaderComponent } from './stations-list/stations-list-header/stations-list-header.component';
import { StationsListHeaderOptionsComponent } from
	'./stations-list/stations-list-header/stations-list-header-options/stations-list-header-options.component';
import {
	StationsListPaginatorComponent } from './stations-list/stations-list-paginator/stations-list-paginator.component';
import { StationsListListComponent } from './stations-list/stations-list-list/stations-list-list.component';

import { SharedComponentsModule } from 'src/app/pages/shared/shared-components.module';

import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	declarations: [
		StationsFiltersListComponent,
		StationsListComponent,
		StationsListListStationComponent,
		StationsListHeaderComponent,
		StationsListPaginatorComponent,
		StationsFiltersComponent,
		StationsFiltersFilterComponent,
  		StationsListListComponent,
  		StationsListHeaderOptionsComponent],
	imports: [
		CommonModule,
		SharedComponentsModule,
		ScrollingModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatDividerModule,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		MatRippleModule,
		MatSelectModule,
		MatFormFieldModule,
		MatInputModule,
		MatAutocompleteModule,
		MatCheckboxModule,
		IgoLanguageModule,
		RouterModule
	],
	exports: [StationsFiltersListComponent]
})
export class StationsFiltersListModule { }
