<div *ngIf="((hasAuthService && authenticated && this.authorizedProfile) || !hasAuthService) && showWarning" class="warning-modal">
    <div class="warning-modal-content">
        <h4>{{ 'portal.warning.title' | translate }}</h4>
        <p>{{ 'portal.warning.warning' | translate }}</p>
        <button class="submit" (click)="showWarning = false">{{ 'portal.warning.accept' | translate }}</button>
    </div>
</div>

<igo-spinner igoSpinnerActivity></igo-spinner>

<div class="header-container">
    <app-header *ngIf="hasHeader" class="header" #header [mobile]="mobile"></app-header>
</div>

<igo-auth-form backgroundDisable="false"></igo-auth-form>

<app-menu *ngIf="hasMenu && ((hasAuthService && authenticated) || !hasAuthService)" [style.pointer-events]="menuLock" [mobile]="mobile"></app-menu>

<app-portal *ngIf="!hasMenu" [mobile]="mobile" [ngClass]="hasHeader? 'portal-hasHeader': 'portal'" ></app-portal>
