<div class="container" id="list-scroll" cdkScrollable>
	<div *ngIf="reportWindowOpened" class="report-window">
		<div class="report-window-content">
			<button 
				mat-icon-button
				class="report-window-close"
				[matTooltip]="'stationsFiltersList.report.close' | translate"
				(click)="reportWindowOpened = false">
				<mat-icon>close</mat-icon>
			</button>
			<div class="report-window-actions">
				<button
					class="copy-table"
					[matTooltip]="'stationsFiltersList.report.copy.tooltip' | translate"
					(click)="copyTable()">
					<mat-icon>content_copy</mat-icon>
					{{ 'stationsFiltersList.report.copy.label' | translate }}
				</button>
				<button class="copy-table" (click)="printTable()">
					<mat-icon>print</mat-icon>
					{{ 'stationsFiltersList.report.print.label' | translate }}
				</button>
			</div>
			<h3 class="report-title" id="report-title">{{ 'stationsFiltersList.report.title' | translate }} {{ reportDateTime | date:getDateFormat() }}</h3>
			<ng-container *ngIf="optionsAreSelected">
				<h4 class="report-subtitle" id="report-subtitle">{{ 'stationsFiltersList.report.subtitle' | translate }}</h4>
				<div id="report-filters">
					<ng-container *ngFor="let filter of selectedOptions | keyvalue">
						<div class="filter" *ngIf="filter.value.length">
							<p *ngIf="filter.key !== 'prev'" class="filter-label">{{ FilterLabel[filter.key] | translate }} :&nbsp;</p>
							<p class="filter-options">{{ constructSelectedOptionsString(filter.key, filter.value) }}</p>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<br>
			<div class="table-container">
				<table class="table" id="report-table">
					<thead class="table-header">
						<tr>
							<th class="table-header-header-cell">{{ 'filters.body' | translate }}</th>
							<th class="table-header-header-cell">{{ 'filters.state' | translate }}</th>
							<th class="table-header-header-cell">{{ 'trend.trend' | translate }}</th>
							<th class="table-header-header-cell">{{ 'filters.municipality' | translate }}</th>
							<th class="table-header-header-cell">{{ 'filters.description' | translate }}</th>
							<th class="table-header-header-cell">{{ 'stationsDetails.flow' | translate }} (m³/s)</th>
							<th class="table-header-header-cell">{{ 'stationsDetails.level' | translate }} (m)</th>
							<th class="table-header-header-cell">{{ 'filters.region' | translate }}</th>
							<th class="table-header-header-cell">{{ 'filters.bassin' | translate }}</th>
							<th class="table-header-header-cell">Station</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let station of stationListReport"
							class="table-body-row">
							<td class="table-body-data-cell">{{ station.e_plan_deau }}</td>
							<td class="table-body-data-cell">
								<p class="state-text"><span [ngStyle]="{'color': getStationStateColor(station.a_etat_max), 'margin': '0', 'font-size': '30px'}">●</span>{{ getStationState(station.a_etat_max) | translate }}</p>
							</td>
							<td class="table-body-data-cell">{{ getStationTrend(station.trend_pct) }}</td>
							<td class="table-body-data-cell">{{ station.c_mun }}</td>
							<td class="table-body-data-cell">{{ station.description }}</td>
							<td class="table-body-data-cell">{{ station.dern_valeur_deb ? formatReading(station.dern_valeur_deb) : ('stationsFiltersList.report.na' | translate) }}</td>
							<td class="table-body-data-cell">{{ station.dern_valeur_niv ? formatReading(station.dern_valeur_niv) : ('stationsFiltersList.report.na' | translate) }}</td>
							<td class="table-body-data-cell">{{ station.d_regadmin }}</td>
							<td class="table-body-data-cell">{{ station.g_bassin_versant }}</td>
							<td class="table-body-data-cell" id="station-id-cell"><a [attr.href]="hasAuthService ? 'https://vigilance-partenaires.geo.msp.gouv.qc.ca/stations/' + station.id : 'https://vigilance.geo.msp.gouv.qc.ca/stations/' + station.id" target="_blank">{{ station.b_label }}</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="content">
		<ng-container *ngIf="!isMobile else mobileBreadcrumbs">
			<div class="breadcrumbs">
				<a class="breadcrumbs-element" routerLink="/">{{ 'breadcrumbs.home' | translate }}</a>
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
				<p class="breadcrumbs-element-current">Stations</p>
			</div>
		</ng-container>
	
		<ng-template #mobileBreadcrumbs>
			<div class="breadcrumbs">
				<mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
				<a class="breadcrumbs-element-mobile" routerLink="/">{{ 'breadcrumbs.home' | translate }}</a>
			</div>
		</ng-template>
	
		<div class="title">	
			<h1>Stations</h1>
			<button *ngIf="!isMobile"
				class="report"
				[matTooltip]="'stationsFiltersList.report.create.tooltip' | translate"
				(click)="openDialog()">{{ 'stationsFiltersList.report.create.label' | translate }}
			</button>
		</div>
				
		<div class="filters-list">
			<app-stations-filters [ngClass]="isMobile ? 'filters-mobile' : 'filters-desktop'"
				[isMobile]="isMobile"
				[removedFilter]="removedFilter"
				(selectedOptionsChange)="setSelectedOptions($event)"
				(filtersStringChange)="setFiltersString($event)">
			</app-stations-filters>
	
			<app-stations-list class="list"
				[selectedOptions]="selectedOptions"
				[filtersString]="filtersString"
				(filterRemoved)="setRemovedFilter($event)"
				(currentSortChange)="setCurrentSort($event)">
			</app-stations-list>
		</div>
	
		<app-shared-footer class="footer"></app-shared-footer>
	</div>
</div>
