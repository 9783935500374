import { AboutMapComponent } from './about-map.component';
import { IgoLanguageModule } from '../../../../../language/language.module';//'@igo2/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { SharedComponentsModule } from 'src/app/pages/shared/shared-components.module';

@NgModule({
  declarations: [AboutMapComponent],
  imports: [
    CommonModule,
    IgoLanguageModule,
    RouterModule,
    MatIconModule,
    SharedComponentsModule
  ],
  exports: [AboutMapComponent]
})
export class AboutMapModule { }
