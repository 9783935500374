import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StationCacheService {

  private _cache = new Map<string, [Date | null, HttpResponse<any>]>();

  constructor() { }

  setCache(key: string, response: HttpResponse<any>, timeToReset: number | null = null): void {
    if (timeToReset) {
      const expiresIn = new Date();
      expiresIn.setSeconds(expiresIn.getSeconds() + timeToReset);
      this._cache.set(key, [expiresIn, response]);
    } else {
      this._cache.set(key, [null, response]);
    }
  }

  getCache(key: string): null | HttpResponse<any> {
    // get the cache of corresponding URL
    const tuple: [Date | null, HttpResponse<any>] = this._cache.get(key);

    // if no cache is available, return null
    if (!tuple) {
      return null;
    }

    // get the cache expiration date and the cached response/value
    const expiresIn: Date | null = tuple[0];
    const httpSavedResponse: HttpResponse<any> = tuple[1];

    // if the cache is expired, delete cache and return null
    if (expiresIn && expiresIn?.getTime() < new Date().getTime()) {
      this._cache.delete(key);
      return null;
    }

    // if there is a cache available and it is not expired, return cached response/value
    return httpSavedResponse;
  }
}
