import { isValid } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

/**
 * @description check if a datetime is valid or not
 * @param dateTime string or Date object
 * @returns boolean representing whether datetime is valid or not
 */
export function dateIsValid(dateTime: Date | string): boolean {
    return isValid(dateTime);
}

/**
 * @description convert a datetime to local datetime
 * @param dateTime string or Date object
 * @returns local datetime Date object
 */
export function toZonedDateTime(dateTime: Date | string): Date {
    if (typeof dateTime !== "object") {
        return toZonedTime(dateTime + ".000Z", Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
    return dateTime;
}
