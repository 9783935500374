<div *ngIf="hasAuthService() && authenticated" class="container">
  <div class="content" >
      <div class="list">

        <h1>{{'user.myAccount' | translate}}</h1>
        <h2>{{'user.info.title' | translate}}</h2>
        <h3>{{'user.email' | translate}}</h3>
            <div>{{user.email}}</div>
        <div id="cellinfo" *ngIf="!emptyStationList else noStation">
          <h3>{{'user.info.cell' | translate}}</h3>
          <div *ngIf="displayStationList | async">
              <div *ngIf="userCellPhone.length > 1" >{{ userCellPhone | phoneFormat }}</div> 
              <button mat-stroked-button color="primary" *ngIf="!displayPhoneForm" mat-button class="button-compact" style="margin-left:0;" (click)="changePhoneNumber()"
                matTooltipPosition="above" [matTooltipClass]="'tooltip-above'" [matTooltip]="userCellPhone.length > 1? ('user.info.modifyCellTooltip' | translate) : 'user.info.modifyCellTooltip' | translate" ><mat-icon svgIcon="pencil-outline"></mat-icon> 
                {{userCellPhone.length > 1? ('user.info.modifyCell' | translate) : 'user.info.addCell' | translate}}</button>
              <button *ngIf="userCellPhone.length > 1 && !displayPhoneForm" mat-stroked-button color="primary" mat-button class="button-compact" style="margin-left:0;" (click)="deletePhoneNumber()" matTooltipPosition="above" [matTooltip]="'user.info.deletePhoneTooltip' | translate" [matTooltipClass]="'tooltip-above'" ><mat-icon svgIcon="cellphone-remove"></mat-icon> 
                {{'user.info.delete' | translate}}</button>
          </div>
          <div *ngIf="displayPhoneForm" >
                <h5 style="font-weight: bold; margin-bottom:8px;">{{userCellPhone.length > 1? ('user.info.modifyCellTooltip' | translate) : 'user.info.addCellTooltip' | translate}}</h5>
                <div role="group" [formGroup]="phoneParts">
                  <mat-form-field appearance="outline" class="tel-input-container" style="width: 4rem;">
                    <input matInput class="form-input-text" style="height: 1rem;"
                          formControlName="area" size="3"
                          maxLength="3"
                          aria-label="Area code"
                          (keyup)="onCellInput($event)"
                          #area>
                  </mat-form-field>
                  <span> &ndash; </span>
                <mat-form-field appearance="outline" class="tel-input-container" style="width: 4rem;">
                  <input matInput style="height: 1rem;"
                        formControlName="exchange"
                        maxLength="3"
                        size="3"
                        aria-label="Exchange code"
                        (keyup)="onCellInput($event)"
                        #exchange>
                  </mat-form-field>
                  <span> &ndash; </span>
                <mat-form-field appearance="outline" class="tel-input-container" style="width: 5rem;">
                  <input matInput style="height: 1rem;"
                        formControlName="subscriber"
                        maxLength="4"
                        size="4"
                        aria-label="Subscriber number"
                        (keyup)="onCellInput($event)"
                        #subscriber>
                </mat-form-field>
                <mat-icon style="margin-right:1rem;cursor:default;vertical-align: middle;" svgIcon="cellphone" matSuffix></mat-icon> 
                <button mat-stroked-button color="primary" class="button-compact mat-stroked-button" (click)="displayPhoneForm = false"
                  matTooltipPosition="above" [matTooltipClass]="'tooltip-above'" [matTooltip]="'user.info.cancelCellTooltip' | translate">
                  {{'user.info.cancel' | translate}}
                </button>
                <button color="primary" mat-raised-button class="button-compact" (click)="modifyPhoneNumber()" [disabled]="!phoneParts.valid "
                  matTooltipPosition="above" [matTooltipClass]="'tooltip-above'" [matTooltip]="phoneParts.valid? ('user.info.submitCellTooltip' | translate) : ('user.info.invalidFormPhone' | translate)">
                  {{'user.info.submitCell' | translate}}
                </button>
              </div>
          </div>
      </div>
      <div *ngIf="!emptyStationList">
        <div *ngIf="displayStationList | async" id="alter-preferences">
          <h2>{{'user.pref.disableNotifs.title' | translate}}</h2>
          <p>{{'user.pref.disableNotifs.desc' | translate}}</p>
          <div><mat-slide-toggle [disabled]="!channelTypeEmail" [(ngModel)]="isCheckedEmail" checked (ngModelChange)="optInOutAlerts('email')" matTooltipPosition="right" [matTooltipClass]="'tooltip-right'" [matTooltip]="!isCheckedEmail ? ('user.pref.disableNotifs.email.enable' | translate) : 'user.pref.disableNotifs.email.disable' | translate" >{{'user.pref.chooseAlertType.email' | translate}}</mat-slide-toggle></div>
          <div><mat-slide-toggle [disabled]="userCellPhone.length < 1" [(ngModel)]="isCheckedSMS" checked (ngModelChange)="optInOutAlerts('sms')" [matTooltipClass]="'tooltip-right'" [matTooltip]="!isCheckedSMS ? ('user.pref.disableNotifs.sms.enable' | translate) : 'user.pref.disableNotifs.sms.disable' | translate" matTooltipPosition="right">{{'user.pref.chooseAlertType.sms' | translate}}</mat-slide-toggle></div>
        </div>
      </div>
      <h2>{{'user.pref.fav' | translate}}</h2>
      <div *ngIf="!emptyStationList else noStation">
        <div *ngIf="displayStationList | async">
          <div>{{this.userStationList.length}} {{ this.userStationList.length > 1? ('user.pref.stationsCount' | translate) : ('user.pref.stationCount' | translate) }}</div>
          <hr class="title">
          <div *ngIf="userStationList.length else noStation">
              <div class="stations-fav-list" *ngFor="let userStation of userStationList">
                <div *ngIf="userStation.id">
                  <h3 class="station-title"><a [routerLink]="'/stations/' + userStation.id">{{'user.pref.stationList.station' | translate}} : {{userStation?.name}}</a></h3>
                  <ul>
                    <li>{{'user.pref.stationList.water' | translate}} : <span class="value">{{userStation.planDeau? userStation.planDeau : 'sideResult.na' | translate}}</span></li>
                    <li>{{'user.pref.stationList.location' | translate}} : <span class="value">{{userStation.location? userStation.location : 'sideResult.na' | translate}}</span></li>
                    <li *ngIf="userStation.baseThreshold?.spa?.length > 0">{{'user.pref.stationList.baseNotif' | translate}} : <span class="value">{{'user.pref.stationList.surveillance' | translate}}</span> 
                      (<span *ngFor="let baseThreshold of userStation.baseThreshold?.spa; let last = last">
                        <span [color]="primary" class="value">{{baseThreshold?.valeur | formatReading }} {{baseThreshold?.debNiv === 'niv'? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span> 
                        <span *ngIf="!last"> {{'user.pref.or' | translate}} </span>
                      </span>)
                    </li>

                    <li *ngIf="userStation.suppThreshold">
                      <span *ngFor="let threshold of userStation.suppThreshold | keyvalue">
                      {{'user.pref.stationList.suppNotif' | translate}} : <span class="value">{{!suppThresholdTypes.includes(threshold.key)? threshold.value[0]?.name: (ThresholdNivDeb[threshold.key] | translate)}}</span>
                      (<span *ngFor="let values of threshold.value; let last = last">
                        <span [color]="primary" class="value">{{values?.valeur | formatReading }} {{values?.debNiv === 'niv'? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span> 
                        <span *ngIf="!last"> {{'user.pref.or' | translate}} </span>
                        <span *ngIf="last">)</span>
                        <span class="value" *ngIf="threshold.value[0].comment">, {{threshold.value[0].comment}}</span>
                      </span>
                    </span>
                    </li>
                  </ul>

                    <div *ngIf="userStation.disabled">
                      <p class="avis">
                        <span class="avis-icon avis-complementaire"><mat-icon svgIcon="bell-off-outline"></mat-icon></span>
                        <span class="avis-content">
                            <span class="avis-title">{{'user.pref.stationList.disabledTitle' | translate}}</span><br>
                            <span class="avis-label">{{'user.pref.stationList.disabledDesc' | translate}}</span>
                        </span>
                      </p>
                    </div>

                    <button mat-stroked-button color="primary" class="button-compact" (click)="modifyAlerts(userStation.id)"
                      matTooltipPosition="above" [matTooltipClass]="'tooltip-above'" [matTooltip]="'user.pref.stationList.modifTooltip' | translate">
                      <mat-icon svgIcon="pencil-outline" class="icon"></mat-icon>
                      {{'user.pref.stationList.modif' | translate}}</button>
                    <button *ngIf="isCheckedEmail || isCheckedSMS || userStation.disabled" mat-stroked-button color="primary" threshold class="button-compact" (click)="onDisableUserStation(userStation.id, userStation.disabled, userStation.suppThreshold)"
                      matTooltipPosition="above" [matTooltipClass]="'tooltip-above'"
                      [matTooltip]="userStation.disabled? ('user.pref.stationList.disable.tooltipDisabled' | translate) : 'user.pref.stationList.disable.tooltipEnabled' | translate">
                      <mat-icon svgIcon="timer-pause-outline" class="icon"></mat-icon>
                      {{userStation.disabled? ('user.pref.stationList.disable.buttonDisabled' | translate) : 'user.pref.stationList.disable.buttonEnabled' | translate}}</button>
                    <button mat-stroked-button color="primary" class="button-compact" (click)="confirmDeleteUserStation(userStation.id, userStation?.name)"
                      matTooltipPosition="above" [matTooltipClass]="'tooltip-above'" [matTooltip]="'user.pref.stationList.delete.tooltip' | translate">
                      <mat-icon svgIcon="trash-can-outline" class="icon"></mat-icon>
                      {{'user.pref.stationList.delete.button' | translate}}</button>
                  
                  <hr class="station">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="emptyStationList" #noStation>
        <p class="avis">
          <span class="avis-icon avis-complementaire"><mat-icon svgIcon="bell-outline"></mat-icon></span>
          <span class="avis-content">
              <span class="avis-title">{{'user.pref.emptyStationListTitle' | translate}}</span><br>
              <span class="avis-label">{{'user.pref.emptyStationListContent' | translate}}</span>
          </span>
        </p>
      </div>
      <ng-template #spinner>
        {{'spinner' | translate}}
        <div class="app-loading">
          <svg class="spinner" viewBox="15 15 30 30">
            <circle class="path" cx="10" cy="10" r="5" fill="none" stroke-width="2" stroke-miterlimit="5"/>
          </svg>
        </div>
      </ng-template>

      <app-shared-footer class="footer"></app-shared-footer>
  </div>

</div>

