import { Component, Input } from '@angular/core';

import { State, StateLegend } from 'src/app/pages/shared/stations/stations.enum';
import { StationForList } from 'src/app/pages/shared/stations/stations.interface';
import { dateIsValid } from 'src/app/pages/shared/stations/stations.utils';

import { LanguageService } from '@igo2/core';

@Component({
	selector: 'app-stations-list-list-station',
	templateUrl: './stations-list-list-station.component.html',
	styleUrls: ['./stations-list-list-station.component.scss']
})
export class StationsListListStationComponent {
	@Input() station: StationForList; // current station

	public dateIsValid = dateIsValid;

	constructor(private _languageService: LanguageService) { }

	/**
	 * @description get state legend
	 * @param value numeric equivalent of state
	 * @returns corresponding legend of state
	 */
	 getStationStateLegend(value: number): string {
		return StateLegend[value];
	}

	/**
	 * @description get state label
	 * @param value numeric equivalent of state
	 * @returns corresponding label of state
	 */
	 getStationState(value: number): string {
		return State[value];
	}

	/**
	 * @description get trend label
	 * @param trendPct numeric trend
	 * @returns corresponding trend
	 */
	getStationTrend(trendPct: number | null): string {
		if (trendPct < 0) {
			return this._languageService.translate.instant("trend.down");
		} else if (trendPct > 0) {
			return this._languageService.translate.instant("trend.up");
		} else {
			return this._languageService.translate.instant("trend.stable");;
		}
	}

	/**
	 * @description get date format
	 * @returns date format
	 */
	getDateFormat(): string {
		if (this.getLanguage().includes("fr")) {
			return "d MMMM y, H:mm";
		} else {
			return "MMMM d y, h:mm a";
		}
	}

	/**
	 * @description get browser language
	 * @returns language
	 */
	getLanguage(): string {
		// return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
		return "fr";
	}
}
