

import { Injectable } from '@angular/core';
import { LanguageService, MessageService } from '@igo2/core';

@Injectable({
    providedIn: 'root'
  })

export class SuccessError {

    constructor(
        private _messageService: MessageService,
        private _languageService: LanguageService
        ) {}

    success(){
        this._messageService.success(
        this._languageService.translate.instant("user.pref.success"),
        this._languageService.translate.instant("user.pref.successTitle")
        );
    }

    error(){
        this._messageService.error(
        this._languageService.translate.instant("user.pref.error"),
        this._languageService.translate.instant("user.pref.errorTitle")
        );
    }
}
