import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatReading'
})
export class FormatReadingPipe implements PipeTransform {

  /**
	 * @description format numbers (replace . by , and round to two decimals)
	 * @param reading number to format
	 * @returns string representingformatted number
	 */
  transform(reading: number): string {
    return reading.toFixed(2).replace(".", ",");
  }
}
