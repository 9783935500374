import { MyAccountModule } from './pages/menu/menu-pages/my-account/my-account.module';
import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { StationCacheInterceptor } from './pages/shared/stations/station-cache-interceptor';
import { StationCacheService } from './pages/shared/stations/station-cache.service';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common'; //???
import { APP_INITIALIZER, ApplicationRef, Injector, NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr'; //???
import { IgoLanguageModule } from './language/language.module'; /// ???
registerLocaleData(localeFr); ///////// ?????
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MenuModule } from './pages/menu/menu.module';
import { StationsFiltersListModule } from './pages/menu/menu-pages/stations/stations-filters-list/stations-filters-list.module';
import { StationsDetailsModule } from './pages/menu/menu-pages/stations/stations-details/stations-details.module';

import {
  provideConfigOptions,
  IgoMessageModule,
  IgoGestureModule,
  RouteService,
  LanguageService,
} from '@igo2/core';
import { IgoSpinnerModule, IgoStopPropagationModule } from '@igo2/common';
import {
  provideIChercheSearchSource,
  provideIChercheReverseSearchSource,
  provideNominatimSearchSource,
  provideCoordinatesReverseSearchSource,
  provideILayerSearchSource,
  provideStoredQueriesSearchSource,
  provideOsrmDirectionsSource,
  provideOptionsApi,
  provideStyleListOptions
} from '@igo2/geo';


import { environment } from '../environments/environment';
import { MenuRoutingModule, PortalModule } from './pages';
import { AppComponent } from './app.component';
import { HeaderModule } from './pages/header/header.module';
import { FooterModule } from './pages/footer/footer.module';
import { ServiceWorkerModule } from '@angular/service-worker';

import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { concatMap, first } from 'rxjs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IgoAuthModule } from '@igo2/auth';

export const defaultTooltipOptions: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: false
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IgoAuthModule.forRoot(),
    IgoGestureModule.forRoot(),
    IgoMessageModule,
    IgoSpinnerModule,
    IgoStopPropagationModule,
    IgoLanguageModule, /// ???
    PortalModule,
    HammerModule,
    HeaderModule,
    FooterModule,
    MatButtonToggleModule,
    StationsFiltersListModule,
    StationsDetailsModule,
    IgoAuthModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.igo.app.pwa.enabled,
      registrationStrategy: 'registerWithDelay:5000'
    }),
    MyAccountModule,
    MenuModule,
    RouterModule.forRoot([]), // this must be last
    MenuRoutingModule // this must be last
  ],
  providers: [
    // {provide: LOCALE_ID, useValue: (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language},
    {provide: LOCALE_ID, useValue: "fr"},
    provideConfigOptions({
      default: environment.igo,
      path: './config/config.json'
    }),
    RouteService,
    provideNominatimSearchSource(),
    provideIChercheSearchSource(),
    provideIChercheReverseSearchSource(),
    provideCoordinatesReverseSearchSource(),
    provideILayerSearchSource(),
    provideStoredQueriesSearchSource(),
    provideOsrmDirectionsSource(),
    provideOptionsApi(),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector, ApplicationRef],
      multi: true
    },
    provideStyleListOptions({
      path: './assets/list-style.json'
    }),
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: defaultTooltipOptions },
    StationListService,
    StationCacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StationCacheInterceptor,
      multi: true
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

function appInitializerFactory(
  injector: Injector,
  applicationRef: ApplicationRef
) {
  // ensure to have the proper translations loaded once, when the app is stable.
  return () => new Promise<any>((resolve: any) => {
    applicationRef.isStable.pipe(
      first(isStable => isStable === true),
      concatMap(() => {
        const languageService = injector.get(LanguageService);
        const lang = languageService.getLanguage();
        return languageService.translate.getTranslation(lang);
      }))
      .subscribe((translations) => {
        const languageService = injector.get(LanguageService);
        const lang = languageService.getLanguage();
        languageService.translate.setTranslation(lang, translations);
        resolve();
      });
  });
}
