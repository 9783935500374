import { Component, Input } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { AuthService } from '@igo2/auth';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  public color = 'accent';

  @Input()
  get mobile(): boolean {
    return this._mobile;
  }
  set mobile(value: boolean) {
    this._mobile = value;
  }
  private _mobile: boolean;

  public user;

  constructor(
    private matDialog: MatDialog,
    public authService: AuthService
  ) {
    this.authService = authService;
    const decodeToken = this.authService.decodeToken();
    this.user = decodeToken.user;
  }

  closeLegend(){
    this.matDialog.closeAll;
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  hasAuthService() {
    return this.authService.hasAuthService;
  }

  logOut() {
    this.authService.logout();
  }

}
