import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ConfigService } from '@igo2/core';
import { IgoMap } from '@igo2/geo';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layer-toggle',
  templateUrl: './layer-toggle.component.html',
  styleUrls: ['./layer-toggle.component.scss']
})
export class LayerToggleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() map: IgoMap;
  @Input() zoom: number;
  @Input() minZoom: number;

  public dateToggle: string = "j1";
  public scenarioToggle: string = "median";
  @Output() scenarioSelected = new EventEmitter<string>();
  public date = new Date();
  public today = Date.now();
  public tomorrow = this.date.setDate(this.date.getDate() + 1);

  public indexId = this.configService.getConfig('vigilanceLayers.indexTronconsLayerId');

  public layerIds: object = {
    "j1median": this.configService.getConfig('vigilanceLayers.tronconsM1LayerId'),
    "j1fort": this.configService.getConfig('vigilanceLayers.tronconsF1LayerId'),
    "j2median": this.configService.getConfig('vigilanceLayers.tronconsM2LayerId'),
    "j2fort": this.configService.getConfig('vigilanceLayers.tronconsF2LayerId')
  };

  public layers$$: Subscription;

  constructor(private configService: ConfigService) { }

  ngOnInit(): void {
    this.layers$$ = this.map.layers$.subscribe(() => {
      for (const layer of this.map.layers) {
        if (Object.values(this.layerIds).includes(layer.id)) {
          layer.visible = false;
        }
      }

      if (this.zoom >= this.minZoom) {
        if (this.map.getLayerById(this.indexId) && this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle])) {
          this.map.getLayerById(this.indexId).visible = false;
          this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible = true;
          if (this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible === true){
            this.scenarioSelected.emit(this.layerIds[this.dateToggle + this.scenarioToggle]);
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.zoom) {
      const zoom: number = changes.zoom.currentValue;

      if (this.map.getLayerById(this.indexId)) {
        if (zoom >= this.minZoom) {
          this.map.getLayerById(this.indexId).visible = false;
          this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible = true;
        } else {
          this.map.getLayerById(this.indexId).visible = true;
          this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible = false;
        }
        if (this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible) {
          this.scenarioSelected.emit(this.layerIds[this.dateToggle + this.scenarioToggle]);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.layers$$.unsubscribe();
  }

  toggleLayer(): void {
    for (const layerId of Object.values(this.layerIds)) {
      this.map.getLayerById(layerId).visible = false;
    }

    this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible = true;
    if (this.map.getLayerById(this.layerIds[this.dateToggle + this.scenarioToggle]).visible === true){
      this.scenarioSelected.emit(this.layerIds[this.dateToggle + this.scenarioToggle]);
    }

  }

  getDateFormat(): string {
		if (this.getLanguage().includes("fr")) {
			return "d MMM";
		} else {
			return "MMM d";
		}
	}

	getLanguage(): string {
    // return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
		return "fr";
	}
}
