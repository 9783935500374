<div class="selected-options">
    <p class="option-label">{{ 'stationsFiltersList.stationsList.stationsListHeader.stationsListHeaderOptions.filters' | translate }}</p>
    <div>
        <ng-container *ngFor="let filter of selectedOptions | keyvalue">
            <div *ngIf="filter.value.length"
                class="filter">
                <div *ngFor="let value of filter.value"
                    class="option"
                    [matTooltip]="'stationsFiltersList.stationsList.stationsListHeader.stationsListHeaderOptions.remove' | translate"
                    (click)="removeFilter(filter.key, value)">
                    <p class="option-value">{{ getLabel(filter.key, value) }}</p>
                    <mat-icon class="remove-icon" color="primary">close</mat-icon>
                </div>
            </div>
        </ng-container>
    </div>
</div>
