import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-home-tile',
  templateUrl: './home-tile.component.html',
  styleUrls: ['./home-tile.component.scss']
})
export class HomeTileComponent {
  @HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;

  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  public isHover: boolean = false;

  constructor() { }
}
