<app-stations-list-header class="header"
	[numberOfStations]="numberOfStations"
	[currentPage]="currentPage"
	[selectedOptions]="selectedOptions"
	(sortChange)="setCurrentSort($event)"
	(numberOfStationsPerPageChange)="setNumberOfStationsPerPage($event)"
	(filterRemoved)="filterRemoved.emit($event)">
</app-stations-list-header>

<app-stations-list-list class="list"
	[currentSort]="currentSort"
	[currentPage]="currentPage"
	[numberOfStationsPerPage]="numberOfStationsPerPage"
	[filtersString]="filtersString"
	(numberOfStationsChange)="setNumberOfStations($event)">
</app-stations-list-list>

<app-stations-list-paginator *ngIf="numberOfStations > 0" class="paginator"
	[numberOfStations]="numberOfStations"
	[numberOfStationsPerPage]="numberOfStationsPerPage"
	(pageNumberChange)="setCurrentPage($event)">
</app-stations-list-paginator>  
