import { Injectable } from '@angular/core';
import { View } from 'ol';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  lastMapView: View;

  constructor() {}
}
