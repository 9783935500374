import { BreadcrumbComponent } from 'src/app/breadcrumb/breadcrumb.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IgoLanguageModule } from '../language/language.module';//'@igo2/core';


@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    MatIconModule,
    IgoLanguageModule
  ],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule { }
