<div class="container">
    <div class="content">
        <ng-container *ngIf="!isMobile else mobileBreadcrumbs">
            <div class="breadcrumbs">
                <a class="breadcrumbs-element" routerLink="/">{{ 'breadcrumbs.home' | translate }}</a>
                <mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left"></mat-icon>
                <p class="breadcrumbs-element-current">{{ 'breadcrumbs.contact' | translate }}</p>
            </div>
        </ng-container>
    
        <ng-template #mobileBreadcrumbs>
            <div class="breadcrumbs">
                <mat-icon class="breadcrumbs-chevron" svgIcon="chevron-left" color="accent"></mat-icon>
                <a class="breadcrumbs-element-mobile" routerLink="/">{{ 'breadcrumbs.home' | translate }}</a>
            </div>
        </ng-template>
    
        <h1>{{ 'contact.title' | translate }}</h1>
    
        <ng-container *ngIf="formSubmitted">
            <div class="submit-box">
                <div class="submit-left">
                    <mat-icon class="submit-icon" svgIcon="check-circle-outline"></mat-icon>
                </div>
                <div class="submit-right">
                    <p class="submit-text"><b>{{ 'contact.submit.message.part1' | translate }}</b></p>
                    <p class="submit-text">{{ 'contact.submit.message.part2' | translate }}</p>
                    <p class="submit-text">{{ 'contact.submit.message.part3' | translate }}</p>
                    <div>
                        <p class="submit-text">{{ 'contact.submit.message.signature.part1' | translate }}</p>
                        <p class="submit-text">{{ 'contact.submit.message.signature.part2' | translate }}</p>
                        <p class="submit-text">{{ 'contact.submit.message.signature.part3' | translate }}</p>
                        <p class="submit-text">{{ 'contact.submit.message.signature.part4' | translate }}</p>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <ng-container *ngIf="!formSubmitted">
            <div class="info">
                <div class="info-left">
                    <mat-icon class="info-icon" svgIcon="information-outline"></mat-icon>
                </div>
                <div class="info-right">
                    <p class="info-text">{{ 'contact.info.emergency' | translate }}</p>
                    <p class="info-text">{{ 'contact.info.warning' | translate }}</p>
                </div>
            </div>

            <a
                class="link"
                href="https://www.quebec.ca/securite-situations-urgence/urgences-sinistres-risques-naturels/inondation"
                target="_blank">{{ 'contact.link' | translate }}
                <mat-icon class="link-icon">open_in_new</mat-icon>
            </a>

            <div class="required-label">
                <p class="required-label-text">{{ 'contact.requiredLabel.part1' | translate }} <span class="mandatory-field"> </span></p>
                <p class="required-label-text" style="margin-left: 4px;"> {{ 'contact.requiredLabel.part2' | translate }}</p>
            </div>
    
            <form
                [formGroup]="contactForm"
                class="contactForm"
                (ngSubmit)="submit()">
                <div class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">{{ 'contact.object.title' | translate }} <span class="mandatory-field"></span></p>
                    </div>
                    <mat-form-field class="form-field-height-select" appearance="outline">
                        <mat-icon matSuffix color="primary">expand_more</mat-icon>
                        <mat-select formControlName="object" [placeholder]="'contact.object.select' | translate" floatLabel="never">
                            <mat-option value="1">{{ 'contact.object.station' | translate }}</mat-option>
                            <mat-option *ngIf="authenticated" value="2">{{ 'contact.object.forecast' | translate }}</mat-option>
                            <mat-option value="3">{{ 'contact.object.problem' | translate }}</mat-option>
                            <mat-option value="4">{{ 'contact.object.experience' | translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="(object.errors?.required || object.errors?.defaultValue) && (object.touched || object.dirty)" class="form-error">
                            {{ ('contact.error.required.part1' | translate) + " " + ('contact.object.title' | translate) + " " + ('contact.error.required.part2' | translate) }}
                        </mat-error>
                    </mat-form-field>
                </div>
    
                <div [ngSwitch]="object.value">
                    <div *ngSwitchCase="1">
                        <p class="instructions">{{ 'contact.instructions.station' | translate }}</p>
                    </div>
                    <div *ngSwitchCase="2">
                        <p class="instructions">{{ 'contact.instructions.forecast' | translate }}</p>
                    </div>
                    <div *ngSwitchCase="3">
                        <p class="instructions">{{ 'contact.instructions.problem' | translate }}</p>
                    </div>
                    <div *ngSwitchCase="4">
                        <p class="instructions">{{ 'contact.instructions.experience' | translate }}</p>
                    </div>
                </div>

                <div *ngIf="object.value === '1'" class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">Station</p>
                    </div>
                    <mat-form-field class="form-field-height" appearance="outline">
                        <input type="text" matInput formControlName="station">
                    </mat-form-field>
                </div>

                <div *ngIf="object.value === '2'" class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">{{ 'contact.section.title' | translate }}</p>
                    </div>
                    <mat-form-field class="form-field-height" appearance="outline">
                        <input type="text" matInput formControlName="section">
                    </mat-form-field>
                </div>
    
                <div class="warning">
                    <div class="warning-left">
                        <mat-icon class="warning-icon" svgIcon="alert-outline"></mat-icon>
                    </div>
                    <div class="warning-right">
                        <p class="warning-text">{{ 'contact.warning.confidentiality' | translate }}</p>
                    </div>
                </div>
                
                <div class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">Message <span class="mandatory-field"></span></p>
                    </div>
                    <p class="hint">{{ 'contact.message.hint' | translate }}</p>
                    <mat-form-field appearance="outline">
                        <textarea matInput maxlength="500" rows="10" formControlName="message"></textarea>
                        <mat-error *ngIf="message.errors?.required && (message.touched || message.dirty)" class="form-error">
                            {{ ('contact.error.required.part1' | translate) + " Message " + ('contact.error.required.part2' | translate) }}
                        </mat-error>
                    </mat-form-field>
                </div>
    
                <div class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">{{ 'contact.name.title' | translate }}</p>
                    </div>
                    <mat-form-field class="form-field-height" appearance="outline">
                        <input type="text" matInput formControlName="name">
                    </mat-form-field>
                </div>
    
                <div class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">{{ 'contact.email.title' | translate }} <span class="mandatory-field"></span></p>
                    </div>
                    <mat-form-field class="form-field-height" appearance="outline">
                        <input type="email" matInput formControlName="email">
                        <mat-error *ngIf="email.errors?.required && (email.touched || email.dirty)" class="form-error">
                            {{ ('contact.error.required.part1' | translate) + " " + ('contact.email.title' | translate) + " " + ('contact.error.required.part2' | translate) }}
                        </mat-error>
                        <mat-error *ngIf="email.errors?.email && (email.touched || email.dirty)" class="form-error">{{ 'contact.error.email' | translate }}</mat-error>
                    </mat-form-field>
                </div>
    
                <div class="form-field">
                    <div class="form-field-label">
                        <p class="form-field-label-title">{{ 'contact.emailConfirm.title' | translate }} <span class="mandatory-field"></span></p>
                    </div>
                    <mat-form-field class="form-field-height" appearance="outline">
                        <input type="email" matInput formControlName="emailConfirm">
                        <mat-error *ngIf="emailConfirm.errors?.required && (emailConfirm.touched || emailConfirm.dirty)" class="form-error">
                            {{ ('contact.error.required.part1' | translate) + " " + ('contact.emailConfirm.title' | translate) + " " + ('contact.error.required.part2' | translate) }}
                        </mat-error>
                        <mat-error *ngIf="emailConfirm.errors?.email && (emailConfirm.touched || emailConfirm.dirty)" class="form-error">{{ 'contact.error.emailConfirm' | translate }}</mat-error>
                    </mat-form-field>
                </div>
    
                <button class="submit" type="submit">{{ 'contact.submit.button' | translate }}</button>
                <p *ngIf="formSubmitError" class="form-error">{{ 'contact.submit.error' | translate }}</p>
            </form>
        </ng-container>
    
        <app-shared-footer class="footer"></app-shared-footer>
    </div>
</div>
