<div class="stations">
    <p *ngIf="numberOfStations > 1" class="total-number-stations">{{ numberOfStations }} stations</p>
    <p *ngIf="numberOfStations === 1" class="total-number-stations">{{ numberOfStations }} station</p>
    <p *ngIf="numberOfStations === 0" class="total-number-stations">{{ 'stationsFiltersList.stationsList.stationsListHeader.no' | translate }} station</p>
    <p *ngIf="upperBound > 0" class="stations-bounds">Stations {{ lowerBound }} {{ 'stationsFiltersList.stationsList.stationsListHeader.to' | translate }} {{ upperBound }}</p>
</div>

<div class="options-order-stations-per-page">
    <app-stations-list-header-options *ngIf="optionsAreSelected"
        [selectedOptions]="selectedOptions"
        (filterRemoved)="filterRemoved.emit($event)">
    </app-stations-list-header-options>

    <div class="order-stations-per-page">
        <div class="order">
            <p class="option-label">{{ 'stationsFiltersList.stationsList.stationsListHeader.sort' | translate }}</p>
            <mat-form-field class="station-header" id="order-select" appearance="outline">
                <mat-select disableOptionCentering [value]="currentSort$ | async"
                    (selectionChange)="changeCurrentSort($event)">
                    <mat-option *ngFor="let sortOption of sortOptions"
                        [value]="sortOption.value">
                        {{ sortOption.label | translate }}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix color="primary" svgIcon="chevron-down"></mat-icon>
            </mat-form-field>
        </div>
        <div class="stations-per-page">
            <p class="option-label">{{ 'stationsFiltersList.stationsList.stationsListHeader.numberOfStations' | translate }}</p>
            <mat-form-field class="station-header" id="stations-per-page-select" appearance="outline">
                <mat-select [value]="numberOfStationsPerPage$ | async"
                    (selectionChange)="changeNumberOfStationsPerPage($event)">
                    <mat-option *ngFor="let numberOfStationsPerPageOption of numberOfStationsPerPageOptions"
                        [value]="numberOfStationsPerPageOption">
                        {{ numberOfStationsPerPageOption }}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix color="primary" svgIcon="chevron-down"></mat-icon>
            </mat-form-field>
        </div>
    </div>
</div>
