<div class="dialog" *ngIf="hasAuthService() && authenticated" (stationIdAlerts)="getStationId($event)">
    <div mat-dialog-actions style="width:fit-content;justify-content: flex-end;">
        <button
            mat-icon-button
            mat-dialog-close
            [matTooltip]="'legend.legendClose' | translate">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>

    <h1 mat-dialog-title *ngIf="data.toDo === 'deletePhone'">{{'user.info.deletePhoneConfirmTitle' | translate}}</h1>
    <h1 mat-dialog-title *ngIf="data.toDo === 'modifyPhone'">{{'user.info.modifyPhoneConfirmTitle' | translate}}</h1>
    <h1 mat-dialog-title *ngIf="data.toDo === 'deleteStation'">
        {{'user.pref.stationList.delete.dialogTitle1' | translate}} 
        {{data.stationName}} {{'user.pref.stationList.delete.dialogTitle2' | translate}}</h1>
    <h1 mat-dialog-title *ngIf="data.toDo === 'newUser'">{{'user.pref.newUserWarning.title' | translate}}</h1>
    <h1 mat-dialog-title *ngIf="data.toDo === 'forbidden'">{{'user.info.forbiddenUserWarningTitle' | translate}}</h1>
    
    <mat-dialog-content>

        <p *ngIf="data.toDo === 'deletePhone' ">{{'user.info.deletePhoneWarning' | translate}}</p>
        <p *ngIf="data.toDo === 'modifyPhone' ">{{'user.info.modifyPhoneWarning' | translate}}</p>
        <p *ngIf="data.toDo === 'deleteStation' ">{{'user.pref.stationList.delete.successDesc' | translate}}</p>
        <p *ngIf="data.toDo === 'forbidden' " [innerHtml]="'user.info.forbiddenUserWarning' | translate"></p>
        <span *ngIf="data.toDo === 'newUser' ">
            <div>{{'user.pref.newUserWarning.p1' | translate}}</div>
            <p>{{'user.pref.newUserWarning.p2a' | translate}} <a href="/mon-compte">{{'user.pref.newUserWarning.p2b' | translate}}</a> {{'user.pref.newUserWarning.p2c' | translate}}</p>
            <img  class="center" src="assets/images/mon-compte-nav.png" width="150">
        </span>

        <mat-dialog-actions align="end" style="margin-bottom:0;">
            <button *ngIf="data.toDo !== 'newUser' && data.toDo !== 'forbidden' " mat-raised-button mat-dialog-close [mat-dialog-close]="!data.shouldReload" cdkFocusInitial click="cancel()">{{'user.info.cancel' | translate}}</button>
            <button color="primary" mat-raised-button [mat-dialog-close]="data"
                matTooltipPosition="above" [matTooltipClass]="'tooltip-above'"
                [disabled]="" type="submit" click="data.toDo === 'newUser' ? submitNewUser()">{{data.toDo === 'newUser' ? ('user.pref.newUserWarning.confirm' | translate) : ('user.pref.alertsDialog.confirm' | translate)}}</button>
        </mat-dialog-actions>

    </mat-dialog-content>

</div>
