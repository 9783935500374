import { Component, EventEmitter, Input, OnInit, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, Params, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup } from '@angular/forms';

import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { ParamsService } from 'src/app/pages/shared/params/params.service';

import { FiltersOptions, FilterOption, HttpOptions, StationForFilters } from 'src/app/pages/shared/stations/stations.interface';
import { State, Trend } from 'src/app/pages/shared/stations/stations.enum';

@Component({
	selector: 'app-stations-filters',
	templateUrl: './stations-filters.component.html',
	styleUrls: ['./stations-filters.component.scss']
})
export class StationsFiltersComponent implements OnInit, OnChanges, OnDestroy {
	@Input() isMobile: boolean; // whether device is mobile or not
	@Input() removedFilter: object; // filter option removed in the list
	@Output() selectedOptionsChange: EventEmitter<object> = new EventEmitter(); // selected options emitted when the user checks/unchecks a filter option
	@Output() filtersStringChange: EventEmitter<string> = new EventEmitter(); // filters string emitted when the user checks/unchecks a filter option

	public filtersForm: FormGroup; // filters form
	public moreFiltersForm: FormGroup; // more filters form

	public filtersOptions: FiltersOptions = {a_etat_max: [], e_plan_deau: [], b_label: [], d_regadmin: [], c_mun: []}; // filter options to display in respective selectors
	public moreFiltersOptions: FiltersOptions = {g_bassin_versant: [], f_mrc: [], trend_pct: []}; // more filters options to display in respective selectors

	public selectedOptions: object = {a_etat_max: [], e_plan_deau: [], b_label: [], d_regadmin: [], c_mun: []}; // selected filter options
	public moreSelectedOptions: object = {g_bassin_versant: [], f_mrc: [], trend_pct: []}; // more selected filter options

	public filtersShown: boolean = false; // whether the filters are shown or not
	public moreFiltersShown: boolean = false; // whether the More filters section is shown or not
	public isPrevOnly: boolean = false; // whether only stations with forecasts are shown or not

	public queryParams$$: Subscription; // subscription to query params

	public State: State;

	constructor(
		private _fb: FormBuilder,
		private _stationListService: StationListService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _paramsService: ParamsService
	) { }

	async ngOnInit() {
		// construct form groups (control names must be same as attribute names in DB)
		this.filtersForm = this._fb.group({
			a_etat_max: [],
			e_plan_deau: [],
			b_label: [],
			d_regadmin: [],
			c_mun: []
		});

		this.moreFiltersForm = this._fb.group({
			g_bassin_versant: [],
			f_mrc: [],
			trend_pct: []
		});

		// get all possible filters options
		await this.getFiltersOptions("");
		await this.getMoreFiltersOptions("()");

		this._router.navigate([], {
			queryParams: {
				a_etat_max: "",
				b_label: "",
				c_mun: "",
				d_regadmin: "",
				e_plan_deau: "",
				f_mrc: "",
				g_bassin_versant: "",
				trend_pct: "",
				prev: this.isPrevOnly
			},
			queryParamsHandling: "merge"
		});

		this.queryParams$$ = this._activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
			this._paramsService.setQueryParams(this._activatedRoute.snapshot.queryParams);
			if (paramMap.keys.filter(filter => Object.keys(this.selectedOptions).includes(filter))) {
				const primaryFilters: string[] = paramMap.keys.filter(filter => Object.keys(this.selectedOptions).includes(filter));

				for (const primaryFilter of primaryFilters) {
					const primaryFilterValues = [];
					const primaryFilterNewValues = paramMap.get(primaryFilter).split(",");
					primaryFilterValues.push(...primaryFilterNewValues);

					for (const primaryFilterValue of primaryFilterValues) {
						if (primaryFilter === "a_etat_max") {
							let filterOption: FilterOption;
							if (primaryFilterValue === "null") {
								filterOption = this.filtersOptions[primaryFilter].find(selectedFilterOption =>
									selectedFilterOption.value === null);
							} else {
								filterOption = this.filtersOptions[primaryFilter].find(selectedFilterOption =>
									selectedFilterOption.value === parseInt(primaryFilterValue));
							}

							if (filterOption) {
								if (primaryFilterValue === "null") {
									if (!this.selectedOptions[primaryFilter].includes(null) &&
									!this.selectedOptions[primaryFilter].length) {
										this.toggleSelection(primaryFilter, filterOption);
									}
								} else if (!this.selectedOptions[primaryFilter].includes(parseInt(primaryFilterValue))) {
									this.toggleSelection(primaryFilter, filterOption);
								}
							}
						} else {
							const filterOption: FilterOption = this.filtersOptions[primaryFilter].find(selectedFilterOption =>
								selectedFilterOption.string === primaryFilterValue);
							if (!this.selectedOptions[primaryFilter].includes(primaryFilterValue) &&
							!this.selectedOptions[primaryFilter].includes(null)) {
								if (filterOption) {
									this.toggleSelection(primaryFilter, filterOption);
								}
							}
						}
					}
				}
			}

			if (paramMap.keys.filter(filter => Object.keys(this.moreSelectedOptions).includes(filter))) {
				const secFilters: string[] = paramMap.keys.filter(filter => Object.keys(this.moreSelectedOptions).includes(filter));
				for (const secFilter of secFilters) {
					const secFilterValues = [];
					const secFilterNewValues = paramMap.get(secFilter).split(",");
					secFilterValues.push(...secFilterNewValues);
					for (const secFilterValue of secFilterValues) {
						if (secFilter === "trend_pct") {
							let filterOption: FilterOption;
							if (secFilterValue === "null") {
								filterOption = this.moreFiltersOptions[secFilter].find(selectedFilterOption =>
									selectedFilterOption.value === null);
							} else {
								filterOption = this.moreFiltersOptions[secFilter].find(selectedFilterOption =>
									selectedFilterOption.value === parseInt(secFilterValue));
							}

							if (filterOption) {
								if (secFilterValue === "null") {
									if (!this.moreSelectedOptions[secFilter].includes(null)
									) {
										this.toggleMoreSelection(secFilter, filterOption);
									}
								} else if (!this.moreSelectedOptions[secFilter].includes(parseInt(secFilterValue))) {
									this.toggleMoreSelection(secFilter, filterOption);
								}
							}
						}
						const filterOption: FilterOption = this.moreFiltersOptions[secFilter].find(selectedFilterOption =>
							selectedFilterOption.string === secFilterValue);
						if (!this.moreSelectedOptions[secFilter].includes(secFilterValue)) {
							if (filterOption) {
								this.toggleMoreSelection(secFilter, filterOption);
							}
						}
					}
				}
			}

			if (paramMap.keys.includes("prev")) {
				if (paramMap.get("prev") === "false") {
					this.togglePrev(false);
				} else {
					this.togglePrev(Boolean(paramMap.get("prev")));
				}
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// if a filter has been removed from list
		if (changes.removedFilter.currentValue) {
			const removedFilter: object = changes.removedFilter.currentValue;
			const filter: string = removedFilter["filter"];
			// toggle option according to filter
			if (Object.keys(this.filtersOptions).includes(filter)) {
				for (const filterOption in this.filtersOptions[filter]) {
					if (filter === "a_etat_max") {
						if (this.filtersOptions[filter][filterOption].value === removedFilter["filterOption"]) {
							this.navigate(filter, this.filtersOptions[filter][filterOption]);
						}
					} else {
						if (this.filtersOptions[filter][filterOption].string === removedFilter["filterOption"]) {
							this.navigate(filter, this.filtersOptions[filter][filterOption]);
						}
					}
				}
			} else if (Object.keys(this.moreFiltersOptions).includes(filter)){
				for (const filterOption in this.moreFiltersOptions[filter]) {
					if (filter === "trend_pct") {
						if (this.moreFiltersOptions[filter][filterOption].value === removedFilter["filterOption"]) {
							this.navigateMore(filter, this.moreFiltersOptions[filter][filterOption]);
						}
					} else {
						if (this.moreFiltersOptions[filter][filterOption].string === removedFilter["filterOption"]) {
							this.navigateMore(filter, this.moreFiltersOptions[filter][filterOption]);
						}
					}
				}
			} else {
				this.togglePrev(false);
			}
		}
	}

	ngOnDestroy(): void {
		if (this.queryParams$$){
			this.queryParams$$.unsubscribe();
		}
	}

	/**
	 * @description get all possible filters options
	 * @param currentFiltersString current filters string
	 */
	async getFiltersOptions(currentFiltersString?: string): Promise<void> {
		const optionsAreSelected: boolean = this.checkOptionsAreSelected(this.selectedOptions);
		const moreOptionsAreSelected: boolean = this.checkOptionsAreSelected(this.moreSelectedOptions);

		let httpOptions: HttpOptions = {
			params: {
				and: null
			}
		};

		if (optionsAreSelected || moreOptionsAreSelected) {
			httpOptions.params["and"] = currentFiltersString;
		}

		const stationList = await this._stationListService
		.getStationFilters(optionsAreSelected || moreOptionsAreSelected ? httpOptions : null)
		.toPromise() as StationForFilters[];

		if (stationList) {
			const listOfAlreadyPresent: object = {a_etat_max: [], e_plan_deau: [], b_label: [], d_regadmin: [], c_mun: []};

			if (!optionsAreSelected && !moreOptionsAreSelected) {
				this.filtersOptions = {a_etat_max: [], e_plan_deau: [], b_label: [], d_regadmin: [], c_mun: []};
				for (const filter in this.filtersOptions) {
					if (filter === "a_etat_max") {
						for (const state of Object.keys(State).filter(state => isNaN(Number(state)) &&
							state !== "null" && State[state] !== 2)) {
							let filterOption: FilterOption = {value: undefined, string: "", count: 0, selected: false};

							filterOption.value = State[state];
							filterOption.string = state;

							listOfAlreadyPresent[filter].push(State[state]);
							this.filtersOptions[filter].push(filterOption);
						}
					}

					for (const station of stationList) {
						let filterOption: FilterOption = {value: undefined, string: "", count: 0, selected: false};

						if (filter === "a_etat_max") {
							filterOption.string = State[station[filter]];
							filterOption.value = station[filter];
						} else {
							filterOption.string = station[filter];
						}

						if (!listOfAlreadyPresent[filter].includes(station[filter])) {
							listOfAlreadyPresent[filter].push(station[filter]);
							filterOption.count += 1;

							this.filtersOptions[filter].push(filterOption);
						} else {
							let filterOptionIndex: number;
							if (filter === "a_etat_max") {
								filterOptionIndex = this.filtersOptions[filter].findIndex((filterOption: FilterOption) =>
									filterOption.value === station[filter]);
							} else {
								filterOptionIndex = this.filtersOptions[filter].findIndex((filterOption: FilterOption) =>
									filterOption.string === station[filter]);
							}

							this.filtersOptions[filter][filterOptionIndex].count += 1;
						}
					}
				}

				for (const filter in this.filtersOptions) {
					if (filter === "a_etat_max") {
						this.filtersOptions[filter].sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
					} else {
						this.filtersOptions[filter].sort((a,b) => ('' + a.string).localeCompare(b.string));
					}
				}
			} else {
				for (const filter in this.filtersOptions) {
					for (const filterOption of this.filtersOptions[filter]) {
						filterOption.count = 0;
					}
				}

				for (const station of stationList) {
					for (const filter in this.filtersOptions) {
						let filterOptionIndex: number;
						if (filter === "a_etat_max") {
							filterOptionIndex = this.filtersOptions[filter]
							.findIndex((filterOption: FilterOption) => filterOption.value === station[filter]);
						} else {
							filterOptionIndex = this.filtersOptions[filter]
							.findIndex((filterOption: FilterOption) => filterOption.string === station[filter]);
						}

						this.filtersOptions[filter][filterOptionIndex].count += 1;
					}
				}
			}
		}
	}

	/**
	 * @description get all possible more filters options
	 * @param currentFiltersString current filters string
	 */
	 async getMoreFiltersOptions(currentFiltersString?: string): Promise<void> {
		let optionsAreSelected: boolean = this.checkOptionsAreSelected(this.selectedOptions);
		let moreOptionsAreSelected: boolean = this.checkOptionsAreSelected(this.moreSelectedOptions);

		let httpOptions: HttpOptions = {
			params: {
				and: null
			}
		};

		if (optionsAreSelected || moreOptionsAreSelected) {
			httpOptions.params["and"] = currentFiltersString;
		}

		const stationList = await this._stationListService
		.getStationFilters(optionsAreSelected || moreOptionsAreSelected ? httpOptions : null)
		.toPromise() as StationForFilters[];

		if (stationList) {
			const listOfAlreadyPresent: object = {f_mrc: [], g_bassin_versant: [], trend_pct: []};

			if (!optionsAreSelected && !moreOptionsAreSelected) {
				this.moreFiltersOptions = {f_mrc: [], g_bassin_versant: [], trend_pct: []};
				for (const filter in this.moreFiltersOptions) {
					if (filter === "trend_pct") {
						for (const trend of Object.keys(Trend).filter(trend => isNaN(Number(trend)) &&
							trend !== "null")) {
							let filterOption: FilterOption = {value: undefined, string: "", count: 0, selected: false};

							filterOption.value = Trend[trend];
							filterOption.string = trend;

							listOfAlreadyPresent[filter].push(Trend[trend]);
							this.moreFiltersOptions[filter].push(filterOption);
						}
					}

					for (const station of stationList) {
						let filterOption: FilterOption = {value: undefined, string: "", count: 0, selected: false};

						if (filter === "trend_pct") {
							filterOption.string = Trend[station[filter]];
							filterOption.value = station[filter];
						} else {
							filterOption.string = station[filter];
						}

						if (!listOfAlreadyPresent[filter].includes(station[filter])) {
							listOfAlreadyPresent[filter].push(station[filter]);
							filterOption.count += 1;

							this.moreFiltersOptions[filter].push(filterOption);
						} else {
							let filterOptionIndex: number;
							if (filter === "trend_pct") {
								filterOptionIndex = this.moreFiltersOptions[filter].findIndex((filterOption: FilterOption) =>
									filterOption.value === station[filter]);
							} else {
								filterOptionIndex = this.moreFiltersOptions[filter].findIndex((filterOption: FilterOption) =>
									filterOption.string === station[filter]);
							}

							this.moreFiltersOptions[filter][filterOptionIndex].count += 1;
						}
					}
				}

				for (const filter in this.moreFiltersOptions) {
					if (filter === "trend_pct") {
						this.moreFiltersOptions[filter].sort((a,b) => {
							if (a.value === null) return 1; // Null values come last
							if (b.value === null) return -1; // Null values come last
							return a.value < b.value ? 1 : a.value > b.value ? -1 : 0;
						});
					} else {
						this.moreFiltersOptions[filter].sort((a,b) => ('' + a.string).localeCompare(b.string));
					}
				}
			} else {
				for (const filter in this.moreFiltersOptions) {
					for (const filterOption of this.moreFiltersOptions[filter]) {
						filterOption.count = 0;
					}
				}
				for (const station of stationList) {
					for (const filter in this.moreFiltersOptions) {
						let filterOptionIndex: number;
						if (filter === "trend_pct") {
							filterOptionIndex = this.moreFiltersOptions[filter]
							.findIndex((filterOption: FilterOption) => filterOption.value === station[filter]);
						} else {
							filterOptionIndex = this.moreFiltersOptions[filter]
							.findIndex((filterOption: FilterOption) => filterOption.string === station[filter]);
						}

						this.moreFiltersOptions[filter][filterOptionIndex].count += 1;
					}
				}
			}
		}
	}

	/**
	 * @description construct filters string for API call
	 * @param selectedOptions selected options in filters
	 */
	constructFiltersString(selectedOptions: object): void {
		let filtersString: string = "(";
		for (const [filter, selectedFilterOptions] of Object.entries(selectedOptions)) {
			if (selectedFilterOptions?.length >= 1) {
				filtersString += "or(";
				for (const selectedFilterOption of selectedFilterOptions) {
					if (typeof selectedFilterOption === "string") {
						filtersString += filter + ".like." + selectedFilterOption;
					} else if (typeof selectedFilterOption === "number") {
						filtersString += filter + ".eq." + selectedFilterOption;
					} else if (typeof selectedFilterOption === "boolean") {
						filtersString += filter + ".is." + selectedFilterOption;
					} else {
						filtersString += filter + ".is.null";
					}

					if (selectedFilterOption === selectedFilterOptions.at(-1)) {
						filtersString += ")";
						const filters: string[] = Object.keys(selectedOptions);
						if(filter !== filters[filters.length - 1]) {
							filtersString += ",";
						}
					} else {
						filtersString += ",";
					}
				}
			}
		}
		filtersString += ")";
		filtersString = filtersString.replace("),)", "))");

		this.getFiltersOptions(filtersString);
		this.getMoreFiltersOptions(filtersString);
		this.selectedOptionsChange.emit(selectedOptions);
		this.filtersStringChange.emit(filtersString);
	}

	/**
	 * @description reset all filters
	 */
	resetAllFilters(): void {
		this.resetFormGroup(this.filtersOptions);
		this.resetFormGroup(this.moreFiltersOptions, true);

		this._router.navigate([], {
			queryParams: {
				a_etat_max: "",
				b_label: "",
				c_mun: "",
				d_regadmin: "",
				e_plan_deau: "",
				f_mrc: "",
				g_bassin_versant: "",
				trend_pct: "",
				prev: false
			},
			queryParamsHandling: "merge"
		});

		this.filtersStringChange.emit();
	}

	/**
	 * @description reset all options to unselected
	 * @param options options for all filters
	 * @param isMore boolean representing if options are in More filters section or not
	 */
	resetFormGroup(options: FiltersOptions, isMore: boolean = false): void {
		for (const [filter, filterOptions] of Object.entries(options)) {
			for (const filterOption of filterOptions) {
				if (filterOption.selected) {
					!isMore ? this.toggleSelection(filter, filterOption) : this.toggleMoreSelection(filter, filterOption);
				}
			}
		}
	}

	/**
	 * @description toggle a filter option/selection
	 * @param filter string representing the filter
	 * @param selectedOption the option to toggle
	 */
	toggleSelection(filter: string, selectedOption: FilterOption): void {
		const selectedFilterIndex: number = this.filtersOptions[filter].findIndex(selectedFilterOption =>
			selectedFilterOption.string === selectedOption.string);
		const selectedFilterOption: FilterOption = this.filtersOptions[filter][selectedFilterIndex];

		selectedFilterOption.selected = !selectedFilterOption.selected;

		const selectedOptionFilter: any[] = this.selectedOptions[filter];

		if (selectedFilterOption.selected) {
			if (filter === "a_etat_max") {
				selectedOptionFilter.push(selectedFilterOption.value);
			} else {
				selectedOptionFilter.push(selectedFilterOption.string);
			}
		} else {
			let selectedOptionIndex: number;
			if (filter === "a_etat_max") {
				selectedOptionIndex = selectedOptionFilter.findIndex(option => option === selectedOption.value);
			} else {
				selectedOptionIndex = selectedOptionFilter.findIndex(option => option === selectedOption.string);
			}
			this.selectedOptions[filter].splice(selectedOptionIndex, 1);
		}

		let selectedOptions: object = {};

		if (this.isPrevOnly) {
			selectedOptions = {...this.selectedOptions, ...this.moreSelectedOptions, ...{prev: [this.isPrevOnly]}};
		} else {
			selectedOptions = {...this.selectedOptions, ...this.moreSelectedOptions};
		}

		this.constructFiltersString(selectedOptions);
	}

	/**
	 * @description toggle a filter option/selection in the More filters section
	 * @param filter string representing the filter
	 * @param selectedOption the option to toggle
	 */
	toggleMoreSelection(filter: string, selectedOption: FilterOption): void {
		const selectedFilterIndex: number = this.moreFiltersOptions[filter].findIndex(selectedFilterOption =>
			selectedFilterOption.string === selectedOption.string);
		const selectedFilterOption: FilterOption = this.moreFiltersOptions[filter][selectedFilterIndex];

		selectedFilterOption.selected = !selectedFilterOption.selected;

		const selectedOptionFilter: any = this.moreSelectedOptions[filter];

		if (selectedFilterOption.selected) {
			if (filter === "trend_pct") {
				selectedOptionFilter.push(selectedFilterOption.value);
			} else {
				selectedOptionFilter.push(selectedFilterOption.string);
			}
		} else {
			let selectedOptionIndex: number;
			if (filter === "trend_pct") {
				selectedOptionIndex = selectedOptionFilter.findIndex(option => option === selectedOption.value);
			} else {
				selectedOptionIndex = selectedOptionFilter.findIndex(option => option === selectedOption.string);
			}
			this.moreSelectedOptions[filter].splice(selectedOptionIndex, 1);
		}

		let selectedOptions: object = {};

		if (this.isPrevOnly) {
			selectedOptions = {...this.selectedOptions, ...this.moreSelectedOptions, ...{prev: [this.isPrevOnly]}};
		} else {
			selectedOptions = {...this.selectedOptions, ...this.moreSelectedOptions};
		}
		this.constructFiltersString(selectedOptions);
	}

	/**
	 * @description navigate to route for primary filters
	 * @param filter string representing the filter
	 * @param selectedOption the option to toggle
	 */
	navigate(filter: string, selectedOption: FilterOption): void {
		const queryParamValues = [];
		let queryParamNewValues: string[] = [];

		if (this._activatedRoute.snapshot.queryParamMap.get(filter)) {
			queryParamNewValues = this._activatedRoute.snapshot.queryParamMap.get(filter).split(",");
		}

		queryParamValues.push(...queryParamNewValues);

		let queryParamString: string;

		const queryParams: Params = {};

		if (filter === "a_etat_max") {
			if (queryParamValues.includes("null") && selectedOption.value === null) {
				this.toggleSelection(filter, selectedOption);
				const indexToRemove: number = queryParamValues.indexOf("null");
				queryParamValues.splice(indexToRemove, 1);
				queryParamString = queryParamValues.toString();
			} else if (queryParamValues.includes(selectedOption.value?.toString())) {
				this.toggleSelection(filter, selectedOption);
				const indexToRemove: number = queryParamValues.indexOf(selectedOption.value.toString());
				queryParamValues.splice(indexToRemove, 1);
				queryParamString = queryParamValues.toString();
			} else {
				if (queryParamValues.length) {
					if (selectedOption.value !== null) {
						queryParamString = queryParamValues.toString() + "," + selectedOption.value;
					} else {
						queryParamString = queryParamValues.toString() + ",null";
					}
				} else if (selectedOption.value === null) {
					queryParamString = "null";
				} else {
					queryParamString = selectedOption.value.toString();
				}
			}
		} else {
			if (queryParamValues.includes(selectedOption.string)) {
				this.toggleSelection(filter, selectedOption);
				const indexToRemove: number = queryParamValues.indexOf(selectedOption.string);
				queryParamValues.splice(indexToRemove, 1);
				queryParamString = queryParamValues.toString();
			} else {
				if (queryParamValues.length) {
					queryParamString = queryParamValues.toString() + "," + selectedOption.string;
				} else {
					queryParamString = selectedOption.string;
				}
			}
		}
		this.setQueryParams(filter, queryParamString, queryParams);
	}

	/**
	 * @description navigate to route for secondary filters
	 * @param filter string representing the filter
	 * @param selectedOption the option to toggle
	 */
	 navigateMore(filter: string, selectedOption: FilterOption): void {
		const queryParamValues = [];
		let queryParamNewValues: string[] = [];

		if (this._activatedRoute.snapshot.queryParamMap.get(filter)) {
			queryParamNewValues = this._activatedRoute.snapshot.queryParamMap.get(filter).split(",");
		}

		queryParamValues.push(...queryParamNewValues);

		let queryParamString: string;

		const queryParams: Params = {};

		if (filter === "trend_pct") {
			if (queryParamValues.includes("null") && selectedOption.value === null) {
				this.toggleMoreSelection(filter, selectedOption);
				const indexToRemove: number = queryParamValues.indexOf("null");
				queryParamValues.splice(indexToRemove, 1);
				queryParamString = queryParamValues.toString();
			} else if (queryParamValues.includes(selectedOption.value?.toString())) {
				this.toggleMoreSelection(filter, selectedOption);
				const indexToRemove: number = queryParamValues.indexOf(selectedOption.value.toString());
				queryParamValues.splice(indexToRemove, 1);
				queryParamString = queryParamValues.toString();
			} else {
				if (queryParamValues.length) {
					if (selectedOption.value !== null) {
						queryParamString = queryParamValues.toString() + "," + selectedOption.value;
					} else {
						queryParamString = queryParamValues.toString() + ",null";
					}
				} else if (selectedOption.value === null) {
					queryParamString = "null";
				} else {
					queryParamString = selectedOption.value.toString();
				}
			}
		} else {
			if (queryParamValues.includes(selectedOption.string)) {
				this.toggleMoreSelection(filter, selectedOption);
				const indexToRemove: number = queryParamValues.indexOf(selectedOption.string);
				queryParamValues.splice(indexToRemove, 1);
				queryParamString = queryParamValues.toString();
			} else {
				if (queryParamValues.length) {
					queryParamString = queryParamValues.toString() + "," + selectedOption.string;
				} else {
					queryParamString = selectedOption.string;
				}
			}
		}
		this.setQueryParams(filter, queryParamString, queryParams);
	}

	/**
	 * @description toggle stations with forecasts only
	 * @param isPrevOnly boolean repesenting if only stations with forecasts are shown
	 */
	togglePrev(isPrevOnly: boolean): void {
		this.isPrevOnly = isPrevOnly;

		let selectedOptions: object = {};

		if (this.isPrevOnly) {
			selectedOptions = {...this.selectedOptions, ...this.moreSelectedOptions, ...{prev: [this.isPrevOnly]}};
		} else {
			selectedOptions = {...this.selectedOptions, ...this.moreSelectedOptions};
		}

		this._router.navigate([], {
			queryParams: {
				a_etat_max: this._activatedRoute.snapshot.queryParamMap.get("a_etat_max"),
				b_label: this._activatedRoute.snapshot.queryParamMap.get("b_label"),
				c_mun: this._activatedRoute.snapshot.queryParamMap.get("c_mun"),
				d_regadmin: this._activatedRoute.snapshot.queryParamMap.get("d_regadmin"),
				e_plan_deau: this._activatedRoute.snapshot.queryParamMap.get("e_plan_deau"),
				f_mrc: this._activatedRoute.snapshot.queryParamMap.get("f_mrc"),
				g_bassin_versant: this._activatedRoute.snapshot.queryParamMap.get("g_bassin_versant"),
				trend_pct: this._activatedRoute.snapshot.queryParamMap.get("trend_pct"),
				prev: this.isPrevOnly
			},
			queryParamsHandling: "merge"
		});

		this.constructFiltersString(selectedOptions);
	}

	getSelectedCount(filterOptions: FilterOption[]): number {
		let selectedCount: number = 0;

		for (const filterOption of filterOptions) {
			if (filterOption.selected) {
				selectedCount += 1;
			}
		}

		return selectedCount;
	}

	setQueryParams(filter: string, queryParamString: string, queryParams: Params): void {
		for (const f of Object.keys(this.moreFiltersOptions)) {
			if (f === filter) {
				queryParams[f] = queryParamString;
			} else if (this._activatedRoute.snapshot.queryParamMap.get(f) !== "" &&
			this._activatedRoute.snapshot.queryParamMap.get(f) !== null) {
				queryParams[f] = this._activatedRoute.snapshot.queryParamMap.get(f);
			} else {
				queryParams[f] = "";
			}
		}

		for (const f of Object.keys(this.filtersOptions)) {
			if (f === filter) {
				queryParams[f] = queryParamString;
			} else if (this._activatedRoute.snapshot.queryParamMap.get(f) !== "" &&
			this._activatedRoute.snapshot.queryParamMap.get(f) !== null) {
				queryParams[f] = this._activatedRoute.snapshot.queryParamMap.get(f);
			} else {
				queryParams[f] = "";
			}
		}

		queryParams["prev"] = this.isPrevOnly;

		this._router.navigate([], {
			queryParams: queryParams,
			queryParamsHandling: "merge"
		});
	}

	checkOptionsAreSelected(selectedOptions: object): boolean {
		let optionsAreSelected: boolean = false;

		for (const selectedOption in selectedOptions) {
			if (selectedOptions[selectedOption].length) {
				optionsAreSelected = true;
				break;
			}
		}

		return optionsAreSelected;
	}
}
