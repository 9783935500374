<div class="links">
    <div *ngIf="showPreviousLink"
        class="link"
        (click)="goTo(previousLinkUrl)">
        <div class="link-arrow-container">
            <mat-icon class="arrow">arrow_back</mat-icon>
        </div>
        <div class="link-text-container">
            <p class="link-text-label">{{ 'about.training.sequentialLinks.previous' | translate }}</p>
            <p class="link-text-text">{{ previousLinkText }}</p>
        </div>
    </div>
    <div *ngIf="showNextLink"
        class="link"
        (click)="goTo(nextLinkUrl)">
        <div class="link-arrow-container">
            <mat-icon class="arrow">arrow_forward</mat-icon>
        </div>
        <div class="link-text-container">
            <p class="link-text-label">{{ 'about.training.sequentialLinks.next' | translate }}</p>
            <p class="link-text-text">{{ nextLinkText }}</p>
        </div>
    </div>
</div>