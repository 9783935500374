import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { StationForList,
  StationForFilters,
  StationForDetailsMetadata,
  StationForDetailsReadings } from './stations.interface';
import { Injectable } from '@angular/core';
import { toZonedDateTime } from './stations.utils';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StationListService {

  get urlStationList (): string {
    return environment.apiUrl.stationList;
  }

  get urlStationFilters (): string {
    return environment.apiUrl.stationFilters;
  }

  get urlStationDetailsMetadata (): string {
    return environment.apiUrl.stationDetailsMetadata;
  }

  get urlStationDetailsReadings (): string {
    return environment.apiUrl.stationDetailsReadings;
  }

  get urlStationCount (): string {
    return environment.apiUrl.stationCount;
  }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-store',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
    }),
    withCredentials: environment.apiUrl.withCredentials
  };

  constructor(private _http: HttpClient) { }

  /**
   * @description get the station list used for the station list
   * @param options object representing the options
   * @returns observable of the station list
   */
  getStationList(options?: object): Observable<StationForList[] | ArrayBuffer> {
    const optionsHttp = Object.assign(options ? options : {}, this.httpOptions);
    return this._http.get<StationForList[]>(this.urlStationList, optionsHttp).pipe(
      map(response => {
        for (const station of response) {
          station.date_prise_valeur = toZonedDateTime(station.date_prise_valeur);
        }

        return response;
      }),
      retry(3));
  }

  getStationFilters(options?: object): Observable<StationForFilters[] | ArrayBuffer> {
    const optionsHttp = Object.assign(options ? options : {}, this.httpOptions);
    return this._http.get<StationForFilters[]>(this.urlStationFilters, optionsHttp).pipe(retry(3));
  }

  /**
   * @description get the station list used for the station details metadata
   * @param options object representing the options
   * @returns observable of the station list
   */
  getStationDetailsMetadata(options?: object): Observable<void | StationForDetailsMetadata[] | ArrayBuffer> {
    const optionsHttp = Object.assign(options ? options : {}, this.httpOptions);
    return this._http.get<StationForDetailsMetadata[]>(this.urlStationDetailsMetadata, optionsHttp).pipe(
      map(response => {
        for (const station of response) {
          station.date_prise_valeur = toZonedDateTime(station.date_prise_valeur);
        }

        return response;
      }),
      retry(3));
  }

  /**
   * @description get the station list used for the station details readings
   * @param options object representing the options
   * @returns observable of the station list
   */
  getStationDetailsReadings(options?: object): Observable<StationForDetailsReadings[] | ArrayBuffer> {
    const optionsHttp = Object.assign(options ? options : {}, this.httpOptions);
    return this._http.get<StationForDetailsReadings[]>(this.urlStationDetailsReadings, optionsHttp).pipe(
      map(response => {
        for (const station of response) {
          if (station.valeurs_deb) {
            station.valeurs_deb.map(valeur_deb => {
              valeur_deb.date_prise_valeur = toZonedDateTime(valeur_deb.date_prise_valeur);
            });
          }

          if (station.valeurs_deb_prev) {
            station.valeurs_deb_prev.map(valeur_deb_prev => {
              valeur_deb_prev.date_prise_valeur = toZonedDateTime(valeur_deb_prev.date_prise_valeur);
            });
          }

          if (station.valeurs_deb_prev_min) {
            station.valeurs_deb_prev_min.map(valeur_deb_prev_min => {
              valeur_deb_prev_min.date_prise_valeur = toZonedDateTime(valeur_deb_prev_min.date_prise_valeur);
            });
          }

          if (station.valeurs_deb_prev_max) {
            station.valeurs_deb_prev_max.map(valeur_deb_prev_max => {
              valeur_deb_prev_max.date_prise_valeur = toZonedDateTime(valeur_deb_prev_max.date_prise_valeur);
            });
          }

          if (station.valeurs_niv) {
            station.valeurs_niv.map(valeur_niv => {
              valeur_niv.date_prise_valeur = toZonedDateTime(valeur_niv.date_prise_valeur);
            });
          }

          if (station.valeurs_niv_prev) {
            station.valeurs_niv_prev.map(valeur_niv_prev => {
              valeur_niv_prev.date_prise_valeur = toZonedDateTime(valeur_niv_prev.date_prise_valeur);
            });
          }

          if (station.valeurs_niv_prev_min) {
            station.valeurs_niv_prev_min.map(valeur_niv_prev_min => {
              valeur_niv_prev_min.date_prise_valeur = toZonedDateTime(valeur_niv_prev_min.date_prise_valeur);
            });
          }

          if (station.valeurs_niv_prev_max) {
            station.valeurs_niv_prev_max.map(valeur_niv_prev_max => {
              valeur_niv_prev_max.date_prise_valeur = toZonedDateTime(valeur_niv_prev_max.date_prise_valeur);
            });
          }
        }

        return response;
      }),
      retry(3));
  }

  /**
   * @description get the total number of stations
   * @returns object containt the count
   */
  getStationCount(): Observable<object[]> {
    const optionsHttp = Object.assign({}, this.httpOptions);
    return this._http.get<object[]>(this.urlStationCount, optionsHttp).pipe(retry(3));
  }
}
