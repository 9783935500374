export enum FilterLabel {
	a_etat_max = "filters.state",
	b_label = "Station",
	e_plan_deau = "filters.body",
	d_regadmin = "filters.region",
	f_mrc = "filters.mrc",
	c_mun = "filters.municipality",
	g_bassin_versant = "filters.bassin",
	trend_pct = "filters.trend"
}

export enum Trend {
	"trend.up" = 1,
	"trend.stable" = 0,
	"trend.down" = -1,
	"trend.na" = null
}

export enum State {
	"flooding.major" = 6,
	"flooding.medium" = 5,
	"flooding.minor" = 4,
	"flooding.watch" = 3,
	"flooding.normal" = 1,
	"flooding.unknown" = 0,
	"flooding.deactivated" = null
}

export enum StateLegend {
	"assets/legends/legend-stations/inondation-majeure.svg" = 6,
	"assets/legends/legend-stations/inondation-moyenne.svg" = 5,
	"assets/legends/legend-stations/inondation-mineure.svg" = 4,
	"assets/legends/legend-stations/surveillance.svg" = 3,
	"assets/legends/legend-stations/glace.svg" = 2,
	"assets/legends/legend-stations/etat-normal.svg" = 1,
	"assets/legends/legend-stations/etat-inconnu.svg" = 0,
	"assets/legends/legend-stations/desactivee.svg" = null
}

export enum StateDescription {
	"about.stations.state.watch.description" = 3,
	"about.stations.state.ice.description" = 2,
	"about.stations.state.normal.description" = 1,
	"about.stations.state.unknown.description" = 0,
	"about.stations.state.deactivated.description" = null
}

export enum ThresholdFlow {
	"simaj-debit" = "thresholds.major",
	"simoy-debit" = "thresholds.medium",
	"sim-deb" = "thresholds.minor",
	"spa-deb" = "thresholds.watch",
	"smg-debit" = "thresholds.ice"
}

export enum ThresholdFlowColor {
	"simaj-debit" = "#8d5150",
	"simoy-debit" = "#d56050",
	"sim-deb" = "#e8983c",
	"spa-deb" = "#dae821",
	"smg-debit" = "#576265"
}

export enum ThresholdHeight {
	"simaj-niv" = "thresholds.major",
	"simoy-niv" = "thresholds.medium",
	"sim-niv" = "thresholds.minor",
	"spa-niv" = "thresholds.watch"
}

export enum ThresholdHeightColor {
	"simaj-niv" = "#8d5150",
	"simoy-niv" = "#d56050",
	"sim-niv" = "#e8983c",
	"spa-niv" = "#dae821"
}

export enum ThresholdNivDeb {
	"simaj" = "thresholds.major",
	"simoy" = "thresholds.medium",
	"sim" = "thresholds.minor",
	"spa" = "thresholds.watch",
	"smg" = "thresholds.ice"
}

export enum StateColor{
	"#8d5150" = 6,
	"#d56050" = 5,
	"#e8983c" = 4,
	"#dae821" = 3,
	"#576265" = 2,
	"#55c67b" = 1,
	"#dedee1" = 0,
	"#dedee2" = null
}
