<div mat-dialog-actions>
  <button
    mat-icon-button
    mat-dialog-close
    [matTooltip]="'legend.legendClose' | translate">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<h4 mat-dialog-title>{{ 'legend.legendButton' | translate }}</h4>

<div mat-dialog-content>
  <igo-layer-legend-list
    [layers]="mapLayersShownInLegend"
    [excludeBaseLayers]="true"
    [allowShowAllLegends]="false"
    [updateLegendOnResolutionChange]="true"
    [showAllLegendsValue]="false">
  </igo-layer-legend-list>
</div>