<div class="dialog" *ngIf="hasAuthService() && authenticated" (stationIdAlerts)="getStationId($event)">

  <div mat-dialog-actions style="width:fit-content;justify-content: flex-end;">
    <button
      mat-icon-button mat-dialog-close
      [matTooltipClass]="'tooltip-above'"
      matTooltipPosition="above"
      [matTooltip]="'stationsFiltersList.report.close' | translate">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <h1 *ngIf="displayThresholds | async; else spinnertitle" mat-dialog-title class="flex-column-left">{{ alreadysubscribed ? ('stationsDetails.modifySubscriptionTitle' | translate) : ( 'stationsDetails.newSubscriptionTitle' | translate ) }} {{stationName}}</h1>

  <mat-dialog-content>
    <div *ngIf="displayThresholds | async; else spinner">
      <div
        *ngTemplateOutlet="(userSubscriptionNumber >= 20) && !alreadysubscribed? exceededSubscriptions : canSubscribe">
      </div>
      <ng-template #exceededSubscriptions>
        <p class="avis">
          <span class="avis-icon avis-erreur"><mat-icon svgIcon="close-circle-outline"></mat-icon></span>
          <span class="avis-content">
              <span class="avis-title">{{'user.pref.alertsDialog.maxSubscriptionTitle' | translate}}</span><br>
              <span class="avis-label" [innerHTML]="'user.pref.alertsDialog.maxSubscriptionDesc' | translate"></span>
          </span>
        </p>
      </ng-template>
      <ng-template #canSubscribe>
        <p [innerHTML]="'user.pref.alertsDialog.description' | translate"></p>
        <strong>{{'user.pref.baseSubscription' | translate}} :</strong> <span *ngTemplateOutlet="station.baseThreshold.spa? ((station.baseThreshold?.spa.length > 1) ?  baseThresholds : baseThreshold) : na" ></span>
      </ng-template>
      <ng-template #baseThresholds > 
        <span style="display: inline;color: #223654"> {{'user.pref.alertsDialog.baseThresholds' | translate}} 
          <span style="flex-wrap: no-wrap;">(<span *ngFor="let baseThreshold of station.baseThreshold?.spa; let last = last">{{
            baseThreshold?.valeur | formatReading }} {{baseThreshold?.debNiv.includes('niv')?('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}<span *ngIf="!last"> {{'user.pref.or' | translate}}</span></span>)</span>
            </span>
      </ng-template>
      <ng-template #baseThreshold >
        <span style="display: inline;">
          {{'user.pref.alertsDialog.baseThreshold' | translate}} 
          (<span>{{station?.baseThreshold?.spa[0].valeur | formatReading}} {{
            station?.baseThreshold.spa[0]?.debNiv.includes('niv')? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span>)
        </span>
      </ng-template>
      <ng-template #na displayCustomForm="false" checked="false">
        <span class="text" style="color: #223654"> {{'user.pref.alertsDialog.na' | translate}}</span>
      </ng-template>

      <div style="margin-top: 1rem; display:flex;flex-direction: column;">
        <mat-slide-toggle [disabled]="userSubscriptionNumber >= 20" #supp [(ngModel)]="suppThresholdSelected" ><strong [innerHTML]="'user.pref.additionalSubscription' | translate"></strong></mat-slide-toggle>
      </div>
      <form [formGroup]="alertForm" (ngSubmit)="submitForm()">
        <div [formGroupName]="'EndpointRequest'">
          <div [formGroupName]="'Attributes'">
          <mat-radio-group
            aria-labelledby="station-thresholds"
            class="alerts-thresholds-list"
            [(ngModel)]="selectedThreshold"
            required
            [formControlName]="data.stationId"
            [value]="['spa']">
              <mat-radio-button [disabled]="!suppThresholdSelected" #simaj *ngIf="station.suppThreshold.simaj" [checked]="subscribedSuppThreshold.type === 'simaj'" class="radio-button" [value]="['spa', 'simaj']" (change)="onDisplayCustomForm(false)">
                {{ ThresholdNivDeb['simaj'] | translate }} 
                <span style="flex-wrap: no-wrap;"> (<span *ngFor="let type of station.suppThreshold.simaj; let last = last ">
                  <span>{{type?.valeur  | formatReading }} {{type?.debNiv.includes('niv')? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span> 
                  <span *ngIf="!last"> {{'user.pref.or' | translate}} </span> 
                </span>)</span> <span *ngIf="subscribedSuppThreshold.type === 'simaj'" [ngClass]=" disabled? 'mat-radio-disabled' : 'value' "> ({{'user.pref.alertsDialog.subscribedThreshold' | translate}})</span>
              </mat-radio-button>
              <mat-radio-button  [disabled]="!suppThresholdSelected" #simoy *ngIf="station.suppThreshold.simoy" [checked]="subscribedSuppThreshold.type === 'simoy'" class="radio-button" [value]="['spa', 'simoy']" (change)="onDisplayCustomForm(false)">
                {{ ThresholdNivDeb['simoy'] | translate }} 
                <span style="flex-wrap: no-wrap;"> (<span *ngFor="let type of station.suppThreshold.simoy; let last = last ">
                  <span span>{{type?.valeur  | formatReading }} {{type?.debNiv.includes('niv')? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span> 
                  <span *ngIf="!last"> {{'user.pref.or' | translate}} </span> 
                </span>)</span> <span *ngIf="subscribedSuppThreshold.type === 'simoy'" [ngClass]=" disabled? 'mat-radio-disabled' : 'value' "> ({{'user.pref.alertsDialog.subscribedThreshold' | translate}})</span> 
              </mat-radio-button>
              <mat-radio-button  [disabled]="!suppThresholdSelected" #sim *ngIf="station.suppThreshold.sim" [checked]="subscribedSuppThreshold.type === 'sim'" class="radio-button" [value]="['spa', 'sim']" (change)="onDisplayCustomForm(false)">
                {{ ThresholdNivDeb['sim'] | translate }} 
                <span style="flex-wrap: no-wrap;"> (<span *ngFor="let type of station.suppThreshold.sim; let last = last ">
                  <span span>{{type?.valeur  | formatReading }} {{type?.debNiv.includes('niv')? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span> 
                  <span *ngIf="!last"> {{'user.pref.or' | translate}} </span> </span>)</span>
                  <span *ngIf="subscribedSuppThreshold.type === 'sim'" [ngClass]=" disabled? 'mat-radio-disabled' : 'value' "> ({{'user.pref.alertsDialog.subscribedThreshold' | translate}})</span>
              </mat-radio-button>
              <mat-radio-button [disabled]="!suppThresholdSelected" #smg *ngIf="station.suppThreshold.smg" [checked]="subscribedSuppThreshold.type === 'smg'" class="radio-button" [value]="['spa', 'smg']" (change)="onDisplayCustomForm(false)">
                {{ ThresholdNivDeb['smg'] | translate }} 
                <span *ngFor="let type of station.suppThreshold.smg; let last = last ">
                  <span style="flex-wrap: no-wrap;"> (<span span>{{type?.valeur  | formatReading }} {{type?.debNiv.includes('niv')? ('user.pref.unitHeight' | translate) : ('user.pref.unitFlow' | translate)}}</span> 
                  <span *ngIf="!last"> {{'user.pref.or' | translate}} </span>
                </span>)</span> <span *ngIf="subscribedSuppThreshold.type === 'smg'" [ngClass]=" disabled? 'mat-radio-disabled' : 'value' "> ({{'user.pref.alertsDialog.subscribedThreshold' | translate}})</span>
              </mat-radio-button>
              <mat-radio-button [disabled]="!suppThresholdSelected" [checked]="displayCustomForm" class="radio-button" (click)="onDisplayCustomForm(true)">
                {{ 'user.pref.customAlert' | translate }} <span *ngIf="alreadysubscribed && subscribedSuppThreshold.type && !suppThresholdTypes.includes(subscribedSuppThreshold.type)"
                [ngClass]=" disabled? 'mat-radio-disabled' : 'value' ">({{'user.pref.alertsDialog.subscribedThreshold' | translate}})</span>
              </mat-radio-button>
                <div *ngIf="suppThresholdSelected && displayCustomForm" class="optional-form" id="custom-alert-form" [formGroup]="customAlertForm" >      
                    <div class="form-fields" id="custom-alert-name">
                      <h6>{{'user.pref.customAlertForm.nameLabel' | translate}}<span class="mandatory-field"></span></h6>
                      <mat-form-field style="max-width:16rem;padding-top: 0.5rem;">
                        <input #name matInput [formControlName]="'description'" maxlength="30" [hintLabel]="'user.pref.customAlertForm.nameHint' | translate">
                        <mat-hint align="end" >{{name.value.length}}/30</mat-hint>
                      </mat-form-field>
                      <div class="form-error" *ngIf="customAlertForm.controls.description.errors && customAlertForm.controls.valeur.dirty && customAlertForm.controls.valeur.touched">{{'user.pref.customAlertForm.nameError' | translate}}</div>
                    </div>
                    <div class="form-fields" id="custom-alert-type">
                      <h6>{{'user.pref.customAlertForm.typeLabel' | translate}}<span class="mandatory-field"></span></h6>
                      <mat-radio-group #customType [formControlName]="'type'" class="form-fields" required aria-label="'user.pref.customAlertForm.typeLabel' | translate">
                        <mat-radio-button #deb *ngIf="hasDebit" [value]="'custom-debit'" >{{'user.pref.customAlertForm.flow' | translate}}</mat-radio-button>
                        <mat-radio-button #niv *ngIf="hasNiveau" [value]="'custom-niveau'" >{{'user.pref.customAlertForm.height' | translate}}</mat-radio-button>
                      </mat-radio-group>
                      <div class="form-error" *ngIf="customAlertForm.controls.type.errors && customAlertForm.controls.valeur.dirty && customAlertForm.controls.valeur.touched">{{'user.pref.customAlertForm.typeError' | translate}}</div>
                    </div>
                    <div *ngIf="customAlertForm.controls.type.value === 'custom-debit' || (hasDebit && !hasNiveau)" class="form-fields" id="custom-alert-value">
                      <h6>{{'user.pref.customAlertForm.valueFlowLabel' | translate}}<span class="mandatory-field"></span></h6>
                      <mat-label style="max-width:40rem;">{{'user.pref.customAlertForm.valueFlowHint' | translate}}</mat-label>
                      <mat-form-field style="padding-top: 0.5rem;max-width:10rem;height: 2rem;margin-bottom:2rem;">
                        <input #customvalue id="customvalue" matInput [formControlName]="'valeur'" minlenght="2" maxlength="10" pattern="\d+[0]" (input)="customvalue.value=customvalue.value.replace(regexOnlyNumbers,'')" [placeholder]="'user.pref.customAlertForm.valueFlowPlaceholder' | translate">
                      </mat-form-field>
                      <div class="form-error" *ngIf="customAlertForm.controls.valeur.errors?.['pattern'] && customAlertForm.controls.valeur.dirty && customAlertForm.controls.valeur.touched">{{'user.pref.customAlertForm.flowErrPattern' | translate}}</div>
                      <div class="form-error" *ngIf="customAlertForm.controls.valeur.errors?.['forbiddenValue'] && customAlertForm.controls.valeur.dirty && customAlertForm.controls.valeur.touched">{{'user.pref.customAlertForm.existingValue' | translate}}</div>
                    </div>
                    <div *ngIf="customAlertForm.controls.type.value === 'custom-niveau' || (hasNiveau && !hasDebit)" class="form-fields" id="custom-alert-value">
                      <h6>{{'user.pref.customAlertForm.valueLevelLabel' | translate}}<span class="mandatory-field"></span></h6>
                      <mat-label style="max-width:40rem;">{{'user.pref.customAlertForm.valuelevelHint' | translate}}</mat-label>
                      <mat-form-field  style="padding-top: 0.5rem;max-width:10rem;height: 2rem;margin-bottom:2rem;">
                        <input #customvalue matInput [formControlName]="'valeur'" maxlength="10" pattern="\d+[\.|,]\d{1}[0|5]" [placeholder]="'user.pref.customAlertForm.valueLevelPlaceholder' | translate">
                      </mat-form-field>
                      <mat-hint class="form-error" *ngIf="customAlertForm.controls.valeur.errors?.['pattern'] && customAlertForm.controls.valeur.dirty && customAlertForm.controls.valeur.touched">{{'user.pref.customAlertForm.levelErrPattern' | translate}}</mat-hint>
                      <mat-hint class="form-error" *ngIf="customAlertForm.controls.valeur.errors?.['forbiddenValue'] && customAlertForm.controls.valeur.dirty && customAlertForm.controls.valeur.touched">{{'user.pref.customAlertForm.existingValue' | translate}}</mat-hint>
                    </div>
                    <div class="form-fields" id="custom-alert-comment">
                      <h6>{{'user.pref.customAlertForm.commentLabel' | translate}}</h6>
                      <mat-label style="max-width:40rem;">{{'user.pref.customAlertForm.commentHint' | translate}}</mat-label>
                      <mat-form-field style="padding-top: 0.5rem;">
                        <textarea #comment matInput [formControlName]="'commentaire'" maxlength="150" ></textarea>
                        <mat-hint align="end">{{comment.value.length}}/150</mat-hint>
                      </mat-form-field>
                    </div>
                </div>
          </mat-radio-group>
        </div>
      </div>

        <p style="margin-bottom:0px;">{{'user.pref.alertLimit1' | translate}} <strong>{{20 - userSubscriptionNumber}}</strong> {{'user.pref.alertLimit2' | translate}}</p>
        <div #spinner *ngIf="!displaySpinner; else spinner"></div>
        <div class="closing-buttons">
          <mat-dialog-actions *ngIf="alreadysubscribed" align="start" style="margin-bottom:0;float:left;">
            <mat-stroke-button  class="deleteAlert" (click)="confirmDeleteUserStation(stationID, stationName)"
            matTooltipPosition="right" [matTooltipClass]="'tooltip-right'" [matTooltip]="'user.pref.stationList.delete.tooltip' | translate">
            <mat-icon svgIcon="trash-can-outline" class="icon" color="warn"></mat-icon>
            {{'user.pref.stationList.delete.button' | translate}}</mat-stroke-button>
          </mat-dialog-actions>
          <mat-dialog-actions align="end" style="margin-bottom:0;align-items: flex-end;">
            <mat-stroke-button color="primary" style="margin-right:1rem;"
              [mat-dialog-close]="!data.shouldReload"
              matTooltipPosition="above" [matTooltipClass]="'tooltip-above'" [matTooltip]="'user.pref.alertsDialog.cancelTooltip' | translate"
              click="cancel()">{{'user.info.cancel' | translate}}</mat-stroke-button>
            <button color="primary" mat-raised-button click="submitForm()"
              matTooltipPosition="above" [matTooltipClass]="'tooltip-above'"
              [disabled]="disabledSubmit()"
              [matTooltip]="disabledSubmit()? ('user.info.invalidForm' | translate) : ('user.pref.alertsDialog.submit' | translate)"
              cdkFocusInitial type="submit"
              >{{'user.pref.alertsDialog.confirm' | translate}}</button>
          </mat-dialog-actions>
        </div>
      </form>
  </div>

  <ng-template #spinner>
    <span style="color: #223654">{{'user.spinner' | translate}}</span>
    <igo-spinner igoSpinnerActivity style="display:block;position:relative!important;top:8rem!important;"></igo-spinner>
  </ng-template>

  <ng-template #spinnertitle>
    <h1 mat-dialog-title class="flex-column-left" style="color: #223654">{{'user.spinnerTitle' | translate}}</h1>
    <span style="width:200px;height:1rem;overflow:hidden;"></span>
  </ng-template>

  </mat-dialog-content>

</div>