import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Reading } from './../../../../../shared/stations/stations.interface';
import { format } from 'date-fns';

@Component({
  selector: 'app-stations-details-table',
  templateUrl: './stations-details-table.component.html',
  styleUrls: ['./stations-details-table.component.scss']
})
export class StationsDetailsTableComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
	onResize() {
  		this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  @Input() readings: Reading[];
  @Input() flow: boolean;
  @Input() forecasts: boolean;

  public times: string[] = [];
  public dates: string[] = [];
  public readingsTable: object = {};
  public isMobile: boolean = window.innerWidth >= 768 ? false : true;

  constructor() { }

  ngOnInit(): void {
    for (const reading of this.readings) {
      const time: string = format(new Date(reading.date_prise_valeur), "HH:mm");
      if (!this.times.includes(time)) {
        this.readingsTable[time] = {};
        this.times.push(time);
      }
    }

    this.times.sort((a,b) => ('' + a).localeCompare(b));

    for (const reading of this.readings) {
      const date: string = format(new Date(reading.date_prise_valeur), "yyyy-MM-dd");
      if (!this.dates.includes(date)) {
        this.dates.push(date);
      }
    }

    for (const time in this.readingsTable) {
      for (const [i, value] of this.dates.entries()) {
        this.readingsTable[time][i] = null;
      }
    }

    for (const reading of this.readings) {
      const dateIndex: number = this.dates.indexOf(format(new Date(reading.date_prise_valeur), "yyyy-MM-dd"));
      const readingTime: string = format(new Date(reading.date_prise_valeur), "HH:mm");

      this.readingsTable[readingTime][dateIndex] = reading.valeur;
    }
  }

  /**
	 * @description get date format
	 * @returns date format
	 */
  getDateFormat(): string {
		if (this.getLanguage().includes("fr")) {
			return this.isMobile ? "d MMM" : "d MMMM";
		} else {
			return this.isMobile ? "MMM d" : "MMMM d";
		}
	}

  getTimeFormat(time: string): string {
    if (this.getLanguage().includes("fr")) {
      return format(new Date("1901-01-01T" + time), "H:mm");
    } else {
      return format(new Date("1901-01-01T" + time), "h:mm a");
    }
  }

  /**
	 * @description get browser language
	 * @returns language
	 */
  getLanguage(): string {
    // return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
		return "fr";
	}
}
