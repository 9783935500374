import { AboutTrainingM4Component } from './about-training-m4.component';
import { IgoLanguageModule } from '../../../../../../language/language.module';//'@igo2/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SharedComponentsModule } from '../../../../../shared/shared-components.module';

@NgModule({
  declarations: [AboutTrainingM4Component],
  imports: [
    CommonModule,
    IgoLanguageModule,
    RouterModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    SharedComponentsModule
  ],
  exports: [AboutTrainingM4Component]
})
export class AboutTrainingM4Module { }
