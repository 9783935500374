import { Injectable } from '@angular/core';
import { Observable, map, retry, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@igo2/auth';
import { environment } from 'src/environments/environment';
import { NotifHttpOptionService } from './notifHttpOption.service';


@Injectable({
    providedIn: 'root'
  })
  export class NotificationsService {

  get urlNotifications (): string { return environment.apiNotifications.url; }
  get urlToInedit(): string { return environment.apiToInedit.url; }

  constructor(private http: HttpClient, public authService: AuthService, public notifHttpOptionService: NotifHttpOptionService) { }

  /**
   * @description get the notification list used for the user stations list
   * @param options object representing the options
   * @returns observable of the notification list
   */

  getNotificationsList(options?: object): Observable<any> {
    const cacheBusting = `timestamp=${new Date().getTime()}`;
    const cacheBustingUrl = `${this.urlNotifications}?${cacheBusting}`;
    const optionsHttp = Object.assign(options ? options : {}, this.notifHttpOptionService.httpOptions);
    let observable = this.http.get<any>(cacheBustingUrl, optionsHttp);
    return observable.pipe(
        map(response => {
        return response;
        }),
        retry(3));
  }

  addUserStation(bodyJSON: {}, options?: object ): Observable<any> {
    const cacheBusting = `timestamp=${new Date().getTime()}`;
    const cacheBustingUrl = `${this.urlNotifications}?${cacheBusting}`;
    const optionsHttp = Object.assign(options ? options : {}, this.notifHttpOptionService.httpOptions);
    return this.http.post<any>(cacheBustingUrl, bodyJSON, optionsHttp)
      .pipe(
        catchError(this.handleError)
      );
  }

  replaceNotificationList(bodyJSON: {}, options?: object): Observable<any> {
    const cacheBusting = `timestamp=${new Date().getTime()}`;
    const cacheBustingUrl = `${this.urlNotifications}?${cacheBusting}`;
    const optionsHttp = Object.assign(options ? options : {}, this.notifHttpOptionService.httpOptions);
    return this.http.put<any>(cacheBustingUrl, bodyJSON, optionsHttp)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteNotificationChannel(id: string, options?: object): Observable<any> {
    const cacheBusting = `timestamp=${new Date().getTime()}`;
    let newid = id.replace('+', '%2B');
    const completeURL = `${this.urlNotifications}/${newid}?${cacheBusting}`;//
    const optionsHttp = Object.assign(options ? options : {}, this.notifHttpOptionService.httpOptions);
    return this.http.delete<any>(completeURL, optionsHttp)
      .pipe(
        catchError(this.handleError)
      );
  }

  addCustomThreshold(bodyJSON: {}, options?: object ): Observable<any> {
    const cacheBusting = `timestamp=${new Date().getTime()}`;
    const cacheBustingUrl = `${this.urlToInedit}?${cacheBusting}`;
    const optionsHttp = Object.assign(options ? options : {}, this.notifHttpOptionService.httpOptionsInedit);
    return this.http.post<any>(this.urlToInedit, bodyJSON, optionsHttp)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomThresholds(options?: object ): Observable<any> {
    const cacheBusting = `timestamp=${new Date().getTime()}`;//  ?v=1  timestamp=${new Date().getTime()}
    const cacheBustingUrl = `${this.urlToInedit}?${cacheBusting}`; //${'seuil_id='}${id} ?v=1
    const optionsHttp = Object.assign(options ? options : {}, this.notifHttpOptionService.httpOptionsInedit);
    return this.http.get<any>(this.urlToInedit, optionsHttp)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

}
