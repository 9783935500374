import { TouchendService } from './../../../../../../shared/touchend/touchend.service';
import { Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterOption } from 'src/app/pages/shared/stations/stations.interface';
import { FilterLabel } from '../../../../../../shared/stations/stations.enum';
import { BehaviorSubject,
  Subscription } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-stations-filters-filter',
  templateUrl: './stations-filters-filter.component.html',
  styleUrls: ['./stations-filters-filter.component.scss']
})
export class StationsFiltersFilterComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) panelTrigger: MatAutocompleteTrigger;
  @Input() form: FormGroup;
  @Input() filter: string; // filter
  @Input() filterOptions: FilterOption[]; // filter options to display in the list
  @Input() selectedCount: number; // number of selected options
  @Output() optionChange: EventEmitter<object> = new EventEmitter(); // emitted when there is an option change

  public filterOptions$: BehaviorSubject<FilterOption[]> = new BehaviorSubject(undefined);
  public filterOptions$$: Subscription;

  public touchEvent$$: Subscription;

  FilterLabel = FilterLabel;

  constructor(private _tes: TouchendService) { }

  ngOnInit(): void {
    this.filterOptions$.next(this.filterOptions);

    this.filterOptions$$ = this.form.controls[this.filter].valueChanges.subscribe((searchString: string) => {
      this.filterOptions$.next(this.filteredOptions(searchString));
    });

    this.touchEvent$$ = this._tes.touchEndEvent.subscribe((event: Event) => {
      if (this.panelTrigger.panelOpen) {
        event.stopPropagation();
        this.panelTrigger.openPanel();
      }
    });
  }

  ngOnDestroy(): void {
    this.filterOptions$$.unsubscribe();

    if (this.touchEvent$$) {
      this.touchEvent$$.unsubscribe();
    }
  }

  /**
   * @description toggle selected filter option
   * @param filter filter
   * @param filterOption filter option
   */
  toggleSelection(filter: string, filterOption: FilterOption): void {
    this.optionChange.emit({filter: filter, filterOption: filterOption});
    this.panelTrigger.openPanel();
  }

  /**
   * @description filter options in list
   * @param inputValue string in the input field
   * @returns filtered options
   */
  filteredOptions(inputValue: string): FilterOption[] {
    const filterValue: string = inputValue.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();

    return this.filterOptions.filter(filterOption => filterOption.string?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(filterValue));
  }

	displayFn(): string {
		return "";
	}

  closeOpenPanel(isPanelOpen: boolean, event: Event): void {
    event.stopPropagation();
    document.getElementById("list-scroll").click();
    isPanelOpen ? this.panelTrigger.closePanel() : this.panelTrigger.openPanel();
  }
}
