import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NetworkService, ConnectionState, LanguageService } from '@igo2/core';
import { ConfigService } from '@igo2/core';
import { SearchSource, IgoMap, Feature } from '@igo2/geo';
import { HttpClient } from '@angular/common/http';
import { startOfToday, startOfTomorrow, endOfTomorrow, isToday, isTomorrow, getUnixTime } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ChartData } from './chart.interface';
import 'chartjs-adapter-date-fns';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

import { State, StateLegend, StateDescription } from 'src/app/pages/shared/stations/stations.enum';
import { TooltipPosition } from '@angular/material/tooltip';
import { toZonedDateTime } from 'src/app/pages/shared/stations/stations.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feature-details',
  templateUrl: './feature-details.component.html',
  styleUrls: ['./feature-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FeatureDetailsComponent implements OnDestroy, AfterViewInit, OnChanges {
  private state: ConnectionState;
  private unsubscribe$ = new Subject<void>();

  @Input()
  get source(): SearchSource {
    return this._source;
  }
  set source(value: SearchSource) {
    this._source = value;
    this.cdRef.detectChanges();
  }

  @Input() map: IgoMap;

  @Input()
  get feature(): Feature {
    return this._feature;
  }
  set feature(value: Feature) {
    this._feature = value;
    this.cdRef.detectChanges();
    this.selectFeature.emit();
  }

  @Input()
  get mobile(): boolean {
    return this._mobile;
  }
  set mobile(value: boolean) {
    this._mobile = value;
  }
  private _mobile: boolean;

  @Input()
  get scenarioDateToggle(): string {
    return this._scenarioDateToggle;
  }
  set scenarioDateToggle(value: string) {
    this._scenarioDateToggle = value;
    //this.cdRef.detectChanges();
  }
  private _scenarioDateToggle: string;

  public selectedScenarioBorderColor = '#E58271';

  @Input()
  get mapQueryClick(): boolean {
    return this._mapQueryClick;
  }
  set mapQueryClick(value: boolean) {
    this._mapQueryClick = value;
  }
  private _mapQueryClick: boolean;

  private _feature: Feature;
  private _source: SearchSource;

  //@Output() routeEvent = new EventEmitter<boolean>();
  @Output() selectFeature = new EventEmitter<boolean>();
  @Output() htmlDisplayEvent = new EventEmitter<boolean>();

  @Input()
  matTooltipPosition: TooltipPosition;

  // Hydrogramme
  public waterChartContext: CanvasRenderingContext2D;
  @ViewChild('waterChartCanvas', {static: false}) waterChartCanvas: ElementRef;
  public urlChartModerate = "";
  public urlChartHigh = "";
  public waterChart: Chart;
  public troncon: string;
  //public date = new Date();
  public minChart: any ;
  public maxChart: any;
  public minChartData: number ;
  public maxChartData: number;
  public chartDataModListXY: ChartData[] = [];
  public chartDataHighListXY: ChartData[] = [];
  public previsionDataModerate : Subscription;
  public previsionDataHigh : Subscription;
  public datasetMod: any = {};
  public datasetHigh: any = {};
  public timeofMaxPrevX = '';
  public chartLabels = [];
  public chartInitialized = false;

  get highUrl (): string {
    return environment.apiUrl.waterChart.highUrl;
  }

  get moderateUrl (): string {
    return environment.apiUrl.waterChart.moderateUrl;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private networkService: NetworkService,
    private languageService: LanguageService,
    private configService: ConfigService,
    private http: HttpClient,
    private _languageService: LanguageService
  ) {
    this.networkService.currentState().pipe(takeUntil(this.unsubscribe$)).subscribe((state: ConnectionState) => {
      this.state = state;
    });
  }

  ngAfterViewInit() {
    if (this.feature.properties.Troncon) {
      this.clearWaterChart();
      setTimeout(() => {
        this.createWaterChart();
        this.chartInitialized = true;
        this.scenarioSelectedDatasetMod();
        this.scenarioSelectedDatasetHigh();
      }, 100);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.mapQueryClick === false || !this.feature.properties.Troncon) {
      this.clearWaterChart();
    }
    if (this.mapQueryClick === true || this.feature.properties.Troncon || !this.chartInitialized){
      this.ngAfterViewInit();
    }
    if ( this.chartInitialized === true &&
          (
            (this.feature.properties.Troncon && this.mapQueryClick === true ||
              (this.feature.properties.Troncon && changes.scenarioDateToggle)
            )
          )
      ){
      this.clearWaterChart();
      this.createWaterChart();
      this.scenarioSelectedDatasetMod();
      this.scenarioSelectedDatasetHigh();
    }
  }

  ngOnDestroy() {
    this.mapQueryClick = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.clearWaterChart();
  }

  filterFeatureProperties(feature) {
    const allowedFieldsAndAlias = feature.meta ? feature.meta.alias : undefined;
    const properties = {};
    let offlineButtonState;

    if (feature.properties && feature.properties.Route) {
      delete feature.properties.Route;
    }

    if (allowedFieldsAndAlias) {
      Object.keys(allowedFieldsAndAlias).forEach(field => {
        properties[allowedFieldsAndAlias[field]] = feature.properties[field];
      });
      return properties;
    } else if (offlineButtonState !== undefined) {
      if (!offlineButtonState) {
        if (this.state.connection && feature.meta && feature.meta.excludeAttribute) {
          const excludeAttribute = feature.meta.excludeAttribute;
          excludeAttribute.forEach(attribute => {
            delete feature.properties[attribute];
          });
        } else if (!this.state.connection && feature.meta && feature.meta.excludeAttributeOffline) {
          const excludeAttributeOffline = feature.meta.excludeAttributeOffline;
          excludeAttributeOffline.forEach(attribute => {
            delete feature.properties[attribute];
          });
        }
      } else {
        if (feature.meta && feature.meta.excludeAttributeOffline) {
          const excludeAttributeOffline = feature.meta.excludeAttributeOffline;
          excludeAttributeOffline.forEach(attribute => {
            delete feature.properties[attribute];
          });
        }
      }
    } else {
      if (this.state.connection && feature.meta && feature.meta.excludeAttribute) {
        const excludeAttribute = feature.meta.excludeAttribute;
        excludeAttribute.forEach(attribute => {
          delete feature.properties[attribute];
        });
      } else if (!this.state.connection && feature.meta && feature.meta.excludeAttributeOffline) {
        const excludeAttributeOffline = feature.meta.excludeAttributeOffline;
        excludeAttributeOffline.forEach(attribute => {
          delete feature.properties[attribute];
        });
      }
    }
    return feature.properties;
  }

  // Hydrogramme

  createWaterChart(){
    let unit = this.feature.properties.typePrevision === 'Q' ?
      this.languageService.translate.instant("sideResult.chart.qLegend")
      : this.languageService.translate.instant("sideResult.chart.hLegend");

    // setting the datasets at the chart creation sets the datasetIndexes

      this.datasetMod = {
        backgroundColor: "#095797",
        borderColor: "#095797",
        borderWidth: 3,
        data: this.chartDataModListXY,
        label: " Prévision (scénario modéré)",
        datasetIndex: 0
      };

      this.datasetHigh = {
        backgroundColor: "#D0DC03",
        borderColor: "#D0DC03",
        borderWidth: 3,
        data: this.chartDataHighListXY,
        label: " Prévision (scénario élevé)",
        datasetIndex: 1
      };

      let annotationLegend = {
        backgroundColor: "rgba(136, 147, 162, 0.7)",
        borderColor: "rgba(136, 147, 162, 0.7)",
        borderWidth: 3,
        label: "Prévision affichée sur la carte",
        datasetIndex: 2
      };
    this.waterChartContext = null;
    this.waterChartContext = this.waterChartCanvas.nativeElement.getContext('2d');
      this.waterChart = new Chart(this.waterChartContext, {
      type: 'line',
      data: {
        datasets: [this.datasetMod, this.datasetHigh, annotationLegend],
        labels: this.chartLabels
      },
      options: {
        locale: 'fr-CA',
        interaction: {
          mode: 'index',
          intersect: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            offset: true,
              ticks: {
                major: {
                  enabled: true
                },
                source: "labels",
                align: "start"
              },
              type: 'time',
              time: {
                  unit: 'day',
                  displayFormats: {
                    day: "d MMM"
                  },
                  tooltipFormat: "d MMM yyyy, H:mm"
              },
              adapters: {
                date: {
                  locale: fr
                }
              },
              min: this.minChart,
              max: this.maxChart
          },
          y: {
            min: this.minChartData,
            max: this.maxChartData,
            title: {
              display: true,
              text: unit,
              color: "#223654",
              align: 'center'
            },
            ticks: {
              major: {
                enabled: false
              },
              callback: function (val) { return new Intl.NumberFormat('fr-CA', {
                maximumFractionDigits: 2, minimumFractionDigits: 2
              }).format(Number(val)); }
            }
          }
        },
        elements: {
					line: {
						borderCapStyle: "round",
            borderDash: [8, 5],
						borderJoinStyle: "round",
            borderWidth: 2,
            tension: 0.3 // increasing this number might cause the curve to go back in the past
					},
          point: {
            hitRadius: 4,
            pointStyle: "circle",
            radius: 0
          }
				},
        plugins: {
          annotation: {
            annotations: {
            }
          },
					legend: {
            align: "start",
						labels: {
              textAlign: 'left',
              usePointStyle: true,
              pointStyle: 'rect',
              boxWidth: 10,
              padding: 10,
              boxHeight: 10
						},
						position: "bottom",
            onClick: null
					}
				}
      }
    });

    this.waterChart.options.plugins.legend.labels.textAlign = 'left';
    Chart.defaults.font.family = "'Open Sans', sans-serif";
    Chart.defaults.font.size = 13;
    this.addChartData();
  }

  addChartData() {

    this.minChart = startOfToday();

    let startTomorrow = startOfTomorrow(); // for the label
    this.maxChart = endOfTomorrow();

    this.chartLabels.push( this.minChart, startTomorrow);

    this.constructChartUrl(this.troncon);

    // the annotation needs to be declared this way so we can push its data into it
    this.waterChart.options.plugins.annotation.annotations['maxAnnotation'] = {
      type: 'line',
      scaleID: 'x',
      borderColor: 'rgba(136, 147, 162, 0.7)',
      borderWidth: 4,
      label: {
        content: 'Sélection',
        position: 'end'
      }
    };

    Promise.resolve(this.doAsyncMod())
    .then(
      () => {
        this.doAsyncHigh();
      },
      (err) => console.error(err)
    );
  }

  doAsyncMod() {
    var promise = new Promise((resolve) => {
      this.moderateDataset();
      setTimeout(() => {
        resolve(1);
      });
    });
    return promise;
  }

  doAsyncHigh() {
    var promise = new Promise((resolve) => {
      this.highDataset();
      setTimeout(() => {
        resolve(2);
      }, 50);
    });
    return promise;
  }

  constructChartUrl(troncon = this.feature.properties.Troncon) {
    this.urlChartModerate = (this.moderateUrl + "%27" + troncon + "%27");
    this.urlChartHigh = (this.highUrl + "%27" + troncon + "%27");
  }

  scenarioSelectedDatasetMod(){ // reduces opacity on High dataset in waterChart
    if (this.scenarioDateToggle === "infocrue-fort-jour1" || this.scenarioDateToggle === "infocrue-fort-jour2"){
      this.datasetMod.borderColor = 'rgba(9, 87, 151, 0.4)';
      this.cdRef.detectChanges();
      this.datasetMod.backgroundColor = 'rgba(9, 87, 151, 0.4)';
      this.cdRef.detectChanges();
      this.datasetMod.borderWidth = 2;
      this.cdRef.detectChanges();
    }
  }

  scenarioSelectedDatasetHigh(){ // reduces opacity on Moderate dataset in waterChart
    if (this.scenarioDateToggle === "infocrue-median-jour1" || this.scenarioDateToggle === "infocrue-median-jour2"){
      this.datasetHigh.borderColor = 'rgba(208, 220, 3, 0.4)';
      this.cdRef.detectChanges();
      this.datasetHigh.backgroundColor = 'rgba(208, 220, 3, 0.4)';
      this.cdRef.detectChanges();
      this.datasetHigh.borderWidth = 2;
      this.cdRef.detectChanges();
      this.waterChart.update();
    }
  }

  maxPrev(selectedScenario: number, dayData: ChartData[] = []){
    let timeofMaxPrev: ChartData;
    timeofMaxPrev = dayData.reduce((prev, current) => (prev.y > current.y) ? prev : current);
    this.timeofMaxPrevX = timeofMaxPrev.x;
    this.waterChart.options.plugins.annotation.annotations['maxAnnotation'].value = this.timeofMaxPrevX;
    this.tooltip(selectedScenario);
    this.cdRef.detectChanges();
  }

  moderateDataset() {
    this.previsionDataModerate = this.http.get(this.urlChartModerate).subscribe(response => {
      let tronconPrevisions : any = response;
      let chartDataModList : any[] = [];

      for (let previsionsData of tronconPrevisions.features) {
        chartDataModList.push(previsionsData);
      }
      for (let feature of chartDataModList) {
        const chartData: ChartData = {
          x: '',
          y: 0
        };
        chartData.x = feature.attributes.Date + " " + feature.attributes.Heure;
        chartData.y = Number(feature.attributes.Prevision);
        this.chartDataModListXY.push(chartData);
      }

      console.log('mod', this.chartDataHighListXY);

      let min = Math.min(...this.chartDataModListXY.map(item => item.y));
      let newMin = (min / 1.0005).toFixed(2);
      this.feature.properties.typePrevision === 'Q' ?
        this.minChartData = Math.floor(Number(min)) :
        this.minChartData = Number((Math.floor(min * 20)/20).toFixed(2));
      this.waterChart.options.scales.y.min = this.minChartData;
      console.log('min', min);
      //////////////////M1////////////////
      // Find time of Maximum prediction and create tooltip
      if (this.scenarioDateToggle === "infocrue-median-jour1") {
        let dayData: ChartData[] = [];

        // Find time of Maximum prediction per day
        for (let feature of this.chartDataModListXY) {
          const chartData: ChartData = {
            x: '',
            y: 0
          };
          chartData.x = feature.x;
          chartData.y = feature.y;
          if ( isToday(new Date(chartData.x)) ) {
            dayData.push(chartData);
          }
        }

        this.maxPrev(0, dayData);
      }

      /////////// M2 ////////////
      // Find time of Maximum prediction and create tooltip
      if (this.scenarioDateToggle === "infocrue-median-jour2") {
        let dayData: ChartData[] = [];

        // Max prev per day
        for (let feature of this.chartDataModListXY) {
          const chartData: ChartData = {
            x: '',
            y: 0
          };
          chartData.x = feature.x;
          chartData.y = feature.y;
          if ( isTomorrow(new Date(chartData.x)) ) {
            dayData.push(chartData);
          }
        }

        this.maxPrev(0, dayData);
      };
      this.scenarioSelectedDatasetMod(); // reduces opacity on the  highDataset
      this.waterChart.update();
      this.cdRef.detectChanges();
    });
  }

  highDataset() {
    this.previsionDataHigh = this.http.get(this.urlChartHigh).subscribe(response => {
      let tronconPrevisions : any = response;
      let chartDataHighList : any[] = [];
      for (let previsionsData of tronconPrevisions.features) {
        chartDataHighList.push(previsionsData);
      }

      for (let feature of chartDataHighList) {
        const chartData: ChartData = {
          x: '',
          y: 0
        };

        chartData.x = feature.attributes.Date + " " + feature.attributes.Heure;
        chartData.y = Number(feature.attributes.Prevision);
        this.chartDataHighListXY.push(chartData);
      }

      console.log('high', this.chartDataHighListXY);
      let max = Math.max(...this.chartDataHighListXY.map(item => item.y));
      let newMax = (max * 1.0005).toFixed(2);
      console.log(max, newMax);
      this.feature.properties.typePrevision === 'Q' ?
        this.maxChartData = Math.ceil(Number(max)) :
        this.maxChartData = Number((Math.ceil(max * 20)/20).toFixed(2));
      this.waterChart.options.scales.y.max = this.maxChartData;

      /////////////////F1//////////////////////////////
      // Find time of Maximum prediction and create tooltip
      if (this.scenarioDateToggle === "infocrue-fort-jour1"){
        let dayData: ChartData[] = [];
        // max prev per day
        for (let feature of this.chartDataHighListXY) {
          const chartData: ChartData = {
            x: '',
            y: 0
          };
          chartData.x = feature.x;
          chartData.y = feature.y;
          if ( isToday(new Date(chartData.x)) ) {
            dayData.push(chartData);
          }
        }
        this.maxPrev(1, dayData);
      }

      ///////////////////////F2/////////////////////////////
      // Find time of Maximum prediction and create tooltip
      if (this.scenarioDateToggle === "infocrue-fort-jour2"){
        let dayData: ChartData[] = [];
        // max prev per day
        for (let feature of this.chartDataHighListXY) {
          const chartData: ChartData = {
            x: '',
            y: 0
          };
          chartData.x = feature.x;
          chartData.y = feature.y;
          if ( isTomorrow(new Date(chartData.x)) ) {
            dayData.push(chartData);
          }
        }
        this.maxPrev(1, dayData);
      }
      this.scenarioSelectedDatasetHigh();
      this.waterChart.update();
      this.cdRef.detectChanges();
    });
  }

  tooltip(index:number) {
    let unit = this.feature.properties.typePrevision === 'Q' ?
      this.languageService.translate.instant("sideResult.chart.qUnit") : this.languageService.translate.instant("sideResult.chart.hUnit");
    let max = (getUnixTime(new Date(this.timeofMaxPrevX)) * 1000); //  tooltipItem.parsed.x === (getUnixTime(new Date(this.timeofMaxPrevX)) * 1000); // format(new Date(this.timeofMaxPrevX), 'dd MMM y, H:mm', {locale: fr})
    this.waterChart.options.plugins.tooltip = {
      filter:
        function (tooltipItem) {
          if (tooltipItem.datasetIndex === index) {
            return tooltipItem.parsed.x === max; // only shows tooltip on the max prev  // tooltipItem.label
          } else {
            return false;
          }
        },
      backgroundColor: "rgba(255,255,255,0.75)",
      displayColors: false,
      titleColor: "#223654",
      bodyColor: "#6194BF",
      borderColor: "#063665",
      borderWidth: 1,
      cornerRadius: 0,
      titleFont: {
        family: "'Roboto', sans-serif"
      },
      bodyFont: {
        size: 12
      },
      callbacks: {
        label: function(context) {
          let label: string = context.dataset.label || '';

          if (label) {
              label += ': ';
          }
          if (context.parsed.y !== null) {
              label += (Intl.NumberFormat('fr-FR', {
                maximumFractionDigits: 2, minimumFractionDigits: 2
              }).format(context.parsed.y)) + ' ' + unit;
          }
          return label;
        }
      }
    };
  }

  /**
	 * @description get the state legend image
	 * @param stateValue numeric value of a state
	 * @returns corresponding legend of the state
	 */
  getStationStateLegend(stateValue: number): string {
    if (stateValue === -1) {
      stateValue = null;
    }
		return StateLegend[stateValue];
	}

  /**
	 * @description get the state label
	 * @param stateValue numeric value of a state
	 * @returns corresponding label of the state
	 */
	getStationState(stateValue: number): string {
		if (stateValue === -1) {
      stateValue = null;
    }
    return State[stateValue];
	}

  /**
	 * @description get the trend label
	 * @param value trend
	 * @returns corresponding trend
	 */
	getStationTrend(value: string): string {
		if (value === "Baisse") {
			return this.languageService.translate.instant("trend.down");
		} else if (value === "Hausse") {
			return this.languageService.translate.instant("trend.up");
		} else {
			return value;
		}
	}

  /**
	 * @description get the state description
	 * @param stateValue numeric value of a state
	 * @returns corresponding description of the state
	 */
  getStationStateDescription(stateValue: number) {
    if (stateValue === -1) {
      stateValue = null;
    }

    if (stateValue === null || (stateValue >= 0 && stateValue <= 3)) {
      return StateDescription[stateValue];
    } else if (stateValue === 4) {
      return this._languageService.translate.instant("about.stations.state.minor.description.intro") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.minor.description.roads") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.minor.description.inhabitedAreas") + "\n";
    } else if (stateValue === 5) {
      return this._languageService.translate.instant("about.stations.state.medium.description.intro") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.medium.description.inhabitedAreas") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.medium.description.sewers") + "\n";
    } else if (stateValue === 6) {
      return this._languageService.translate.instant("about.stations.state.major.description.intro") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.major.description.inhabitedAreas") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.major.description.largeAreas") + "\n" +
        "• " + this._languageService.translate.instant("about.stations.state.major.description.roads") + "\n";
    }
  }

  getDateFormat(): string {
		if (this.getLanguage().includes("fr")) {
			return "d MMMM y, H:mm";
		} else {
			return "MMMM d y, h:mm a";
		}
	}

	getLanguage(): string {
    // return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
		return "fr";
	}

  formatReading(reading: number): string {
    return reading.toString().replace(".", ",");
  }

  // to prevent data to add up in the data list and prevent canvas in use error
  clearWaterChart(){
    if (this.waterChart) {
      this.waterChart.destroy();
      this.cdRef.detectChanges();
      this.waterChart.data.labels.pop();
      this.waterChart.data.datasets.forEach((dataset) => {
            dataset.data.pop();
            this.cdRef.detectChanges();
        });
      this.datasetMod = null;
      this.cdRef.detectChanges();
      this.datasetHigh = null;
      this.cdRef.detectChanges();
      this.waterChartContext = null;
      this.cdRef.detectChanges();
      delete this.waterChart;
      this.cdRef.detectChanges();
    }
    this.chartDataModListXY = [];
    this.cdRef.detectChanges();
    this.chartDataHighListXY = [];
    this.cdRef.detectChanges();
    this.urlChartModerate = '';
    this.cdRef.detectChanges();
    this.urlChartHigh = '';
    this.cdRef.detectChanges();
    if (this.previsionDataModerate){
      this.previsionDataModerate.unsubscribe();
      this.cdRef.detectChanges();
    }
    if (this.previsionDataHigh){
      this.previsionDataHigh.unsubscribe();
      this.cdRef.detectChanges();
    }
  }

  tooltipPosition(){
    if (this.mobile) {
      this.matTooltipPosition = 'above';
    } else {
      this.matTooltipPosition = 'right';
    }
  }

  changeTimeZone(date) {
    return toZonedDateTime(date);
  }
}
