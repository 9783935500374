import { GetCustomThreshold } from './../../../shared/stations/stations.interface';
import { NotificationsService } from '../../../../services/notifications.service';
import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@igo2/auth';
import { LanguageService, MessageService } from '@igo2/core';
import { AlertComponent } from './alert/alert.component';
import { Subscription } from 'rxjs';
import { StationForMyAccount, ThresholdsByType, NotifItemPinPoint, Threshold
        } from 'src/app/pages/shared/stations/stations.interface';
import { HttpEventType, HttpHeaders } from '@angular/common/http';
import {
  HttpOptions, StationForDetailsMetadata
  } from 'src/app/pages/shared/stations/stations.interface';
import { StationListService } from 'src/app/pages/shared/stations/stations.service';
import { ThresholdFlow, ThresholdHeight, ThresholdNivDeb } from 'src/app/pages/shared/stations/stations.enum';
import { NotifHttpOptionService } from 'src/app/services/notifHttpOption.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { SuccessError } from './success-error';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyAccountComponent implements AfterViewInit, OnDestroy {
  public user;
  public confirmation = '';
  public displayPhoneForm = false;
  public stationListApi$$: Subscription;
  public isCheckedEmail: boolean;
  public isCheckedSMS: boolean;
  public debit = 'deb';
  public niveau = 'niv';
  public suppThresholdTypes = ['smg', 'sim', 'simoy', 'simaj'];
  public notificationsApi$$: Subscription;
  public userStationList: StationForMyAccount[] = [];
  public thresholdFlow = ThresholdFlow; // enum for flow thresholds
	public thresholdHeight = ThresholdHeight; // enum for height thresholds
  public thresholdTypes : string[];
	public stationMetadata$$: Subscription;
  public stationID : number;
  public displayStationList : Promise<boolean>;
  public notificationSubscription: Subscription;
	public ThresholdNivDeb = ThresholdNivDeb; // enum for thresholds
  public channelTypeEmail: NotifItemPinPoint;
  public channelTypeSMS: NotifItemPinPoint;
  public userCellPhone = '';
  public numberRegex = /[0-9]/;
  public channelIDSMS : string;
  public disabledStationsList: Number[] = [];
  public emptyStationList = false;
  public isMobile: boolean = window.innerWidth >= 768 ? false : true;
  @HostListener('window:resize', ['$event'])
	onResize() {
    this.isMobile = window.innerWidth >= 768 ? false : true;
	}

  @ViewChild('area') areaInput: ElementRef;
  @ViewChild('exchange') exchangeInput: ElementRef;
  @ViewChild('subscriber') subscriberInput: ElementRef;

  phoneParts: FormGroup;

  constructor(
    public authService: AuthService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private successError: SuccessError,
    private messageService: MessageService,
    private _stationListService: StationListService,
    private notificationsService: NotificationsService,
    public notifHttpOptionService: NotifHttpOptionService,
    private _messageService: MessageService,
    private _languageService: LanguageService,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {
    this.authService = authService;
    const decodeToken = this.authService.decodeToken();
    this.user = decodeToken.user;
    this.phoneParts = formBuilder.group({
      area: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern("^[0-9]*$")]],
      exchange: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern("^[0-9]*$")]],
      subscriber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]*$")]]
    });
  }

  ngAfterViewInit(): void {
    this.displayStationList = Promise.resolve(false);
    this.getUserNotifications();

    }

  ngOnDestroy(): void {
    this.displayStationList = Promise.resolve(false);
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    this.userStationList = [];
    this.disabledStationsList = [];
    this.userCellPhone = '';
    this.cdRef.detectChanges();
    if (this.stationMetadata$$) {
      this.stationMetadata$$.unsubscribe;}
    if (this.stationListApi$$) {
      this.stationListApi$$.unsubscribe;}
	}

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  hasAuthService() {
    return this.authService.hasAuthService;
  }

  getUserNotifications() {
    this.displayStationList = Promise.resolve(false);
    this.notificationSubscription = this.notificationsService
      .getNotificationsList(this.notifHttpOptionService.httpOptions)
      .subscribe(
        (response) => {
          let items;
          let stationIDArray : number[] = [];
          let customThresholds: string[] = [];
          if (!response){
            this.displayStationList = Promise.resolve(true);
            this.emptyStationList = true;
            this.cdRef.detectChanges();
          } else {
            items = response.EndpointsResponse.Item;
            for (let item of items) {
              let disabledStation : number;
              //if(item.OptOut === 'ALL'){ //Remove -OptOut to build the lists
                for ( let [key, value] of Object.entries(item.Attributes)){
                  Object.values(value).forEach(threshold => {
                    let seuilType = threshold.split('-', 2);
                    if (seuilType[1] === 'singleOptOut'){
                      disabledStation = Number(key);
                      item.Attributes[key] = ['spa', seuilType[0]];
                    }
                    else if (seuilType[1] === 'optOut'){
                      item.Attributes[key] = ['spa', seuilType[0]];
                    }
                  });
                  if(disabledStation !== undefined && !this.disabledStationsList.includes(disabledStation)){
                    this.disabledStationsList.push(disabledStation);
                    this.cdRef.detectChanges();
                  }
                }
                //console.log('response after', response, this.disabledStationsList);
              //}
              if(item.ChannelType === 'EMAIL'){
                this.channelTypeEmail = item;
                delete this.channelTypeEmail.Id;
                this.channelTypeEmail.Address = this.user.email;
                this.isCheckedEmail = item.OptOut === 'NONE'? true : false;
                // construct userStationList
                for ( const [key, value] of Object.entries(this.channelTypeEmail.Attributes)){
                  if(key.length && value.length >= 1){
                    let stationID = Number(key);
                    stationIDArray.push(stationID);
                    let userStation: StationForMyAccount = {
                      id: stationID,
                      baseThreshold: {},
                      disabled: this.disabledStationsList.includes(stationID)? true : false
                    };
                    Object.values(value).forEach(threshold => {
                      let string = threshold? String(threshold) : '';
                      if (this.numberRegex.test(string)){
                        let seuilType = string.split('-', 2);// remove -optOut
                        customThresholds.push(seuilType[0]);
                      }
                        let newBaseThreshold: ThresholdsByType = {
                          [string]: []
                        };
                         (string.length && string !== 'spa')?
                          userStation.suppThreshold = newBaseThreshold : userStation.baseThreshold = newBaseThreshold;
                    });
                    this.userStationList.push(userStation);
                    this.cdRef.detectChanges();
                  }
                }
                if(!this.userStationList.length){
                  this.emptyStationList = true;
                  this.cdRef.detectChanges();
                }
              } if(item.ChannelType === 'SMS'){
                this.channelIDSMS = item.Id;
                this.userCellPhone = item.Address;
                this.channelTypeSMS = item;
                delete this.channelTypeSMS.Id;
                this.isCheckedSMS = item.OptOut === 'NONE'? true : false;
              }
            }
            setTimeout(() => {
              this.getStationDetails(stationIDArray);
              this.getINEDIT(customThresholds);
            }, 500);
            setTimeout(() => {
              this.displayStationList = Promise.resolve(true);
              this.cdRef.detectChanges();
            }, 2000);
          }
        },
        (error) => {
          console.warn('Notification error', error);
          this.cdRef.detectChanges();
          setTimeout(() => {
            this.displayStationList = Promise.resolve(false);
          }, 5000);
        }
      );
  }

  changePhoneNumber(){
    this.displayPhoneForm = !this.displayPhoneForm;
    this.cdRef.detectChanges();
  }

  onCellInput(event){
    this.cdRef.detectChanges();
    if (event.code !== 'Backspace'){
      if (this.phoneParts.controls.area.valid){
        this.exchangeInput.nativeElement.focus();
        this.cdRef.detectChanges();
      }
      if (this.phoneParts.controls.exchange.valid){
        this.subscriberInput.nativeElement.focus();
        this.cdRef.detectChanges();
      }
    }
     if (event.code === 'Backspace'){
      this.cdRef.detectChanges();
     }
  }

  submitPhoneChange(){
    if (this.phoneParts.invalid) {
      return;
    }
    this.displayPhoneForm = false;
    this.userCellPhone =
      '+1' + this.phoneParts.controls.area.value + this.phoneParts.controls.exchange.value + this.phoneParts.controls.subscriber.value;
    this.cdRef.detectChanges();

    if (this.channelTypeSMS){
      this.toDeleteChannel(this.channelIDSMS), false;
      this.channelTypeSMS.Address = this.userCellPhone;
      this.toPostSingleChannel(JSON.stringify({ EndpointRequest: this.channelTypeSMS }));
    } else {
      this.channelTypeSMS = this.channelTypeEmail;
      this.channelTypeSMS.Address = this.userCellPhone;
      this.channelTypeSMS.ChannelType = 'SMS';
      delete this.channelTypeSMS.Id;
      this.toPostSingleChannel(JSON.stringify({ EndpointRequest: this.channelTypeSMS }));
    }
  }

  submitPhoneDelete(){
    if (this.channelTypeSMS.Address){
      this.toDeleteChannel(this.channelIDSMS, true);
    } else {
      this.userCellPhone = '';
    }
  }

  deletePhoneNumber(){
    this.cdRef.detectChanges();
    this.openWarningDialog({ toDo: 'deletePhone', channelID: this.channelIDSMS});
  }

  modifyPhoneNumber(){
    this.cdRef.detectChanges();
    this.openWarningDialog({ toDo: 'modifyPhone', channelID: this.channelIDSMS});
  }

  /**
 * @description get the station details metadata from a station label
 * @param stationId station ID
 */

  getStationDetails(stationIDArray: number[]): void {
    let _httpOptionsStation: HttpOptions = {
      params: {
        id: "in.(" + stationIDArray + ")"
      }
    };

    this.stationMetadata$$ = this._stationListService.getStationDetailsMetadata(_httpOptionsStation)
    .subscribe((stationList: StationForDetailsMetadata[]) => {
      for (let stationData of stationList) {
        let indexUserStation = this.userStationList.findIndex(i => (i.id === stationData.id));
        let userStation = this.userStationList[indexUserStation];
        userStation.name = stationData.label;
        userStation.planDeau = stationData.plan_deau;
        userStation.location = stationData.description;
        this.cdRef.detectChanges();

        if(stationData.seuils_niv){
          let thresholdType = Object.keys(userStation.baseThreshold);
          let index = stationData.seuils_niv.findIndex(i => (i.type.includes(thresholdType[0] + '-')));
          this.cdRef.detectChanges();
          if (index >= 0){
            let threshold = {
              debNiv: this.niveau,
              valeur: stationData.seuils_niv[index].valeur
            };
            userStation.baseThreshold[thresholdType[0]].push(threshold);
            this.cdRef.detectChanges();
          }
          if (userStation.suppThreshold){
            let thresholdType = Object.keys(userStation.suppThreshold);
            this.cdRef.detectChanges();
            let index = stationData.seuils_niv.findIndex(i => (i.type.includes(thresholdType[0] + '-')));
            if (!this.numberRegex.test(thresholdType[0]) && index >= 0){ //
              let threshold = {
                debNiv: this.niveau,
                valeur: stationData.seuils_niv[index].valeur
              };
              userStation.suppThreshold[thresholdType[0]].push(threshold);
              this.cdRef.detectChanges();
            }
          }
        }
        if(stationData.seuils_deb){
          let thresholdType = Object.keys(userStation.baseThreshold);
          let index = stationData.seuils_deb.findIndex(i => (i.type.includes(thresholdType[0] + '-')));
          this.cdRef.detectChanges();
          if (index >= 0){
            let threshold = {
              debNiv: this.debit,
              valeur: stationData.seuils_deb[index].valeur
            };
            userStation.baseThreshold[thresholdType[0]].push(threshold);
            this.cdRef.detectChanges();
          }
          if (userStation.suppThreshold){
            let thresholdType = Object.keys(userStation.suppThreshold);
            this.cdRef.detectChanges();
            let index = stationData.seuils_deb.findIndex(i => (i.type.includes(thresholdType[0] + '-')));
            if (!this.numberRegex.test(thresholdType[0]) && index >= 0 ){ //&& !this.userStationList[index].suppThreshold
              let threshold = {
                debNiv: this.debit,
                valeur: stationData.seuils_deb[index].valeur
              };
              userStation.suppThreshold[thresholdType[0]].push(threshold);
              this.cdRef.detectChanges();
            }
          }
        }
      }
      this.userStationList.sort((a, b) => (a.planDeau.localeCompare(b.planDeau, 'fr')));
      this.cdRef.detectChanges();
    },
    error => { console.warn('Notification error ', error); }
  );
  this.cdRef.detectChanges();
  };

  getCustomThresholdData(response: GetCustomThreshold[]){

    for (let station of this.userStationList){
      if(station.suppThreshold){
        for (const [key] of Object.entries(station.suppThreshold)){
          if (this.numberRegex.test(key) ){ //&& !this.userStationList[index].suppThreshold
            let index = response.findIndex(i => (i.seuil_id === Number(key)));
            if (index !== -1){
              let threshold: Threshold = {
                debNiv: response[index].type === 'custom-debit' ? this.debit : this.niveau,
                valeur: Number(response[index].valeur),
                name: response[index].description,
                comment: response[index].commentaire
              };
              let indexUserStation = this.userStationList.findIndex(i => (i.id === station.id));
              this.userStationList[indexUserStation].suppThreshold[key].push(threshold);
              this.cdRef.detectChanges();
            }
          }
        }
      }
    }
  }

  toggleNotifications(){
    this.confirmation = this.languageService.translate.instant('user.pref.title');
    this.messageService.success(this.languageService.translate.instant('user.info.cellSuccess'));
    this.cdRef.detectChanges();
  }

  optInOutAlerts(alertMode: string){
    switch(alertMode){
      case 'email':
        this.sendOptOutOptInRequest(this.channelTypeEmail, this.isCheckedEmail);
      break;
      case 'sms':
        if(!this.channelTypeSMS && this.userCellPhone.length){
          this.channelTypeSMS? this.isCheckedSMS : this.isCheckedSMS = true;
          this.channelTypeSMS = this.channelTypeEmail;
          this.channelTypeSMS.ChannelType = 'SMS';
          this.channelTypeSMS.Address = this.userCellPhone;
          this.channelTypeSMS.OptOut = this.isCheckedSMS? 'NONE' : 'ALL';
        }
        //console.log('this.channelTypeSMS', this.channelTypeSMS);
        this.sendOptOutOptInRequest(this.channelTypeSMS, this.isCheckedSMS);
      break;
    }
  }

  sendOptOutOptInRequest(endpoint: NotifItemPinPoint, isChecked: boolean){ // NONE = receives notifs. ALL = receives no notifs
    endpoint.OptOut = 'NONE';
    isChecked !== true ? endpoint.OptOut = 'ALL' : endpoint.OptOut = 'NONE'; // toggle
    if(endpoint.OptOut === 'ALL'){
      for ( let [key, value] of Object.entries(endpoint.Attributes)){
          Object.values(value).forEach(threshold => {
            endpoint.Attributes[key] = this.disabledStationsList.includes(Number(key))?
              ['spa-singleOptOut', (threshold + "-singleOptOut")] : ['spa-optOut', (threshold + "-optOut")];
          });
      }
      //console.log('endpoint', endpoint);
      this.toPostSingleChannel(JSON.stringify({ EndpointRequest: endpoint }) );
    }
    //console.log('endpoint', endpoint);
    if(endpoint.OptOut === 'NONE'){
      this.toPostSingleChannel(JSON.stringify({ EndpointRequest: endpoint }) );
    }
  }

	modifyAlerts(stationIDtoModify: string) {

    const dialogRef = this.dialog
    .open(AlertComponent,
      {
        data: { stationId: stationIDtoModify, shouldReload: true, IneditRequest: false },
        panelClass: 'alertDialog',
        position: this.dialogPosition(),
        maxWidth: this.dialogWidth(),
        maxHeight: '80vh',
        disableClose: true
      } )
    .afterClosed().subscribe(response => {
        if (response.data.shouldReload === true) {
            this.refreshUserStationsList(response.data.IneditRequest);
        }
        if(response.toDo === 'deleteStation'){
          this.confirmDeleteUserStation(response.stationId, response.stationName);
        }
        dialogRef.unsubscribe();
      });
	}

	dialogPosition(){
		if (this.isMobile){
			return {top: '10vh'};
		} else
		return {top: '15vh'};
	}

	dialogWidth(){
		if (this.isMobile){
			return '90vw';
		} else
		return '700px';
	}

  onDisableUserStation(stationId: number, disabled: boolean, suppThreshold?: Threshold): void {
    let spaThreshold: string = disabled? 'spa' : 'spa-singleOptOut';
    let threshold: string;

    if(suppThreshold){
      for ( let [key] of Object.entries(suppThreshold)){
        disabled? threshold = key : threshold = key + "-singleOptOut";
      }
    }

    function updateChannel(channel: NotifItemPinPoint){
      channel.Attributes = {};
      channel.Attributes[stationId] = suppThreshold? [spaThreshold, threshold] : [spaThreshold];
      return {
        EndpointRequest: channel
      };
    }

    let requestEmail = updateChannel(this.channelTypeEmail);

    if(this.channelTypeSMS){
      let requestSMS = updateChannel(this.channelTypeSMS);
      this.toPostTwoChannel(JSON.stringify(requestEmail), JSON.stringify(requestSMS));
    } else {
      this.toPostSingleChannel(JSON.stringify(requestEmail));
    }
  }

  openWarningDialog(dataToSend: {} ){ const dialogRef = this.dialog
    .open(WarningDialogComponent,
      {
        data: dataToSend,
        panelClass: 'confirmDialog',
        maxWidth: '600px',
      } )
    .afterClosed().subscribe(response => {
        if (response.toDo === 'deletePhone'){this.submitPhoneDelete();}
        if (response.toDo === 'modifyPhone'){this.submitPhoneChange();}
        if (response.toDo === 'deleteStation'){this.deleteUserStation(response.stationId);}
        dialogRef.unsubscribe();
      });
  }

  confirmDeleteUserStation(stationId: number, name: string): void {
    this.openWarningDialog({ toDo: 'deleteStation', stationId: stationId, stationName: name});
  }

  public deleteUserStation(stationID?: number){

    function emptyAttribute (channel: NotifItemPinPoint){
      channel.Attributes[stationID] = [];
        let request = {
          EndpointRequest: channel
        };
        return JSON.stringify(request);
    }

    if(this.channelTypeSMS){
      this.toPostTwoChannel(emptyAttribute(this.channelTypeEmail), emptyAttribute(this.channelTypeSMS));
    } else {
      this.toPostSingleChannel(emptyAttribute(this.channelTypeEmail));
    }

  }

  toReplaceNotifList(bodyJSON: {}){
    this.notificationsService
    .replaceNotificationList(bodyJSON)
    .subscribe(response => {
        if (HttpEventType.Response) {
          console.log('Success');
          this.successError.success();
          this.refreshUserStationsList();
        }
      },
      (error) => {
        this.successError.error();
        console.warn('Error', error);
      }
    );
  }

  toPostTwoChannel(bodyJSON1: {}, bodyJSON2?: {}){
    this.notificationsService
    .addUserStation(bodyJSON1)
    .subscribe(response => {
        if (HttpEventType.Response) {
          this.successError.success();
          if(bodyJSON2){
            this.toPostSingleChannel(bodyJSON2);
          }
          else {
            this.refreshUserStationsList();
          }
        }
      },
      (error) => {
        this.successError.error();
        console.warn('Error', error);
      }
    );
  }

  toPostSingleChannel(bodyJSON2: {} ){
    this.notificationsService
    .addUserStation(bodyJSON2)
    .subscribe(response => {
        if (HttpEventType.Response) {
          console.log('Success');
          this.successError.success();
            this.refreshUserStationsList();
          }
      },
      (error) => {
        this.successError.error();
        console.warn('Error', error);
      }
    );
  }

  getINEDIT(customThresholds: string[]){
    let _httpOptionsStation: HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Cache-Control': 'no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 3000 00:00:00 GMT',
        //'Access-Control-Allow-Methods': ['POST', 'GET', 'OPTIONS']
      }),
      params: {
        seuil_id: "in.(" + customThresholds + ")",
        commentaire: "neq."+ new Date().getTime()
        //v: `${new Date().getTime()}+".23208834`//  ?v=1  timestamp=${new Date().getTime()}
      }
    };
    this.notificationsService
    .getCustomThresholds(_httpOptionsStation)
    .subscribe(response => {
        if (HttpEventType.Response) {
          this.getCustomThresholdData(response);
        }
      },
      (error) => {
        console.warn('submitINEDIT error', error);
      }
    );
  }

  toDeleteChannel(id: string, reload?: boolean){
    this.notificationsService
    .deleteNotificationChannel(id)
    .subscribe(response => {
        if (HttpEventType.Response) {
          console.log('Success');
          this.successError.success();
          if (reload){
            this.refreshUserStationsList();
          }
        }
      },
      (error) => {
        this.successError.error();
        console.warn('Error', error);
      }
    );
  }

  refreshUserStationsList(IneditRequest?){
    console.log('reloading page...');
    this.ngOnDestroy();
    let time = IneditRequest? 5000 : 2000;
    setTimeout(() => {
      this.getUserNotifications();
    }, time);
  }

}
