import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@igo2/auth';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent {
  public user;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {toDo: string, stationId?: any, stationName?: string}
    ) {
      this.authService = authService;
      const decodeToken = this.authService.decodeToken();
      this.user = decodeToken.user;
    }

  public cancel(): void {
    this.dialogRef.close(
      {
        data: { shouldReload: false }
      });
  }

  public submitNewUser(): void {
    this.dialogRef.close();
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  hasAuthService() {
    return this.authService.hasAuthService;
  }

}
