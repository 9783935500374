import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IgoMap, Layer } from '@igo2/geo';
import { MapState } from '@igo2/integration';
import { Router, Event, NavigationStart} from '@angular/router';

@Component({
  selector: 'app-legend-button',
  templateUrl: './legend-button.component.html',
  styleUrls: ['./legend-button.component.scss']
})

export class LegendButtonComponent implements OnInit {

  public dialogRef: MatDialogRef<LegendButtonDialogComponent> = null;
  public dialogIsOpened: boolean = false;
  @Output() openLegend = new EventEmitter<string>();

  constructor(public dialog: MatDialog, private _router: Router) { }

  ngOnInit(): void {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.dialog.open){
          this.dialog.closeAll();
          this.dialogIsOpened = false;
        }
      }
    });
  }

  toggleDialog(): void {
    const openedDialog: MatDialogRef<LegendButtonDialogComponent> = this.dialog.getDialogById('legend-button-dialog-container');
      if (!openedDialog) {
        this.openLegend.emit();
        this.dialogRef = this.dialog.open(
          LegendButtonDialogComponent, {
            id: 'legend-button-dialog-container',
            hasBackdrop: false,
            closeOnNavigation: true
        });
        this.dialogIsOpened = true;
      } else {
        this.dialogRef.close();
        this.dialogIsOpened = false;
      }
  }
}

@Component({
  selector: 'app-legend-button-dialog',
  templateUrl: 'legend-button-dialog.component.html'
})
export class LegendButtonDialogComponent implements OnInit {
  @Input()
  get map(): IgoMap {
    return this.mapState.map;
  }

  // import layers from portal
  @Input()
  get layers(): Layer[] {
    return this._layers;
  }
  set layers(value: Layer[]) {
    this._layers = value;
  }
  private _layers: Layer[];

  public mapLayersShownInLegend: Layer[];

  constructor(
    private mapState: MapState
  ) {}

  ngOnInit() {
    this.map.propertyChange$.subscribe(() => {
      this.mapLayersShownInLegend = this.map.layers.filter(layer => (
        layer.showInLayerList !== false
      ));
    });
  }
}
