import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailConfirmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email: AbstractControl = control.get("email");
    const emailConfirm: AbstractControl = control.get('emailConfirm');

    if (email.value && emailConfirm.value && email.value !== emailConfirm.value) {
        emailConfirm.setErrors({email: true});
        return null;
    } else {
        if (emailConfirm.getError("required")) {
            emailConfirm.setErrors(null);
            emailConfirm.setErrors({required: true});
        } else {
            emailConfirm.setErrors(null);
        }
        return null;
    }
};

export function defaultObjectOptionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return control.value === "0" ? {defaultValue: true} : null;
    };
}
