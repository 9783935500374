// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { LanguageOptions } from '@igo2/core';
import { AuthOptions } from '@igo2/auth';
import {
  Projection,
  SearchSourceOptions
} from '@igo2/geo';

interface Environment {
  choseAfaire: Function,
  production: boolean;
  pathConfig: string,
  apiNotifications: {
    url: string
  },
  apiToInedit: {
    url: string
  },
  toPublic: string,
  apiUrl: {
    stationList: string;
    stationFilters: string;
    stationDetailsMetadata:string;
    stationDetailsReadings: string;
    stationCount: string;
    notifications: string;
    waterChart: {
      moderateUrl: string;
      highUrl: string;
    };
    tronconQueryUrl: {
      M1: string;
      M2: string;
      F1: string;
      F2: string;
    },
    withCredentials: boolean;
  },
  recaptcha?: {
    siteKey: string;
    urlEmail: string;
  },
  igo: {
    app: {
      forceCoordsNA: boolean,
      pwa: {
        enabled: false,
        promote: false
      }
    };
    auth?: AuthOptions;
    context?: {
      defaultContextUri?: string;
    };
    language?: LanguageOptions;
    projections?: Projection[];
    searchSources?: { [key: string]: SearchSourceOptions };
    queryOverlayStyle?:{
      base: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 0.8, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 0.7, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      focus: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      selection: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      }
    };
    searchOverlayStyle?: {
      base: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 0.8, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 0.7, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      focus: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      selection: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      }
    };
  };
}

export let environmentBase: Environment = {

  choseAfaire(domain, contextParDefaut, urlEmail?) {
    this.apiUrl.stationList = domain + this.apiUrl.stationList;
    this.apiUrl.stationFilters = domain + this.apiUrl.stationFilters;
    this.apiUrl.stationDetailsMetadata = domain + this.apiUrl.stationDetailsMetadata;
    this.apiUrl.stationDetailsReadings = domain + this.apiUrl.stationDetailsReadings;
    this.apiUrl.stationCount = domain + this.apiUrl.stationCount;
    this.apiUrl.notifications = domain + this.apiUrl.notifications;
    this.apiUrl.notifications = domain + this.apiUrl.notifications;
    this.apiUrl.waterChart.highUrl = domain + this.apiUrl.waterChart.highUrl;
    this.apiUrl.waterChart.moderateUrl = domain + this.apiUrl.waterChart.moderateUrl;
    //this.apiUrl.tronconQueryUrl.M1 = domain + this.apiUrl.tronconQueryUrl.M1;
    //this.apiUrl.tronconQueryUrl.M2 = domain + this.apiUrl.tronconQueryUrl.M2;
    this.apiUrl.tronconQueryUrl.F1 = domain + this.apiUrl.tronconQueryUrl.F1;
    this.apiNotifications.url = domain + this.apiNotifications.url;
    this.apiToInedit.url = domain + this.apiToInedit.url;
    this.toPublic = this.toPublic;

    if (this.igo.auth) {
      this.igo.auth.url = domain + this.igo.auth.url;
      this.igo.auth.trustHosts = [
        domain ,
        'testgeoegl.msp.gouv.qc.ca',
        'pregeoegl.msp.gouv.qc.ca',
        'geoegl.msp.gouv.qc.ca'
      ];
    }

    this.igo.context.defaultContextUri = contextParDefaut;

    // patch, mettre les urls dans les fichiers d'environnement
    if ( contextParDefaut === 'acc' || contextParDefaut === 'acc-partenaires' ) {
      this.production = true;
    }

    if (urlEmail){
      this.recaptcha.urlEmail = urlEmail;
    }

    return this;
  },
  production: false,
  pathConfig: './config/config.json',
  apiNotifications: {
    url: '/apis/vigilance/notifications'
  },
  apiToInedit: {
    url: '/apis/vapi/seuil_personnalise_partenaires'
  },
  toPublic: 'https://vigilance.devgeo.msp.gouv.qc.ca/',
  apiUrl: {
    stationList: '/station_liste_api',
    stationFilters: '/station_filters_api',
    stationDetailsMetadata: '/station_details_metadata_api',
    stationDetailsReadings: '/station_details_readings_api',
    stationCount: '/station_count_active',
    notifications: '',
    waterChart: {
      highUrl: '',
      moderateUrl: ''
    },
    tronconQueryUrl: {
      M1: '',
      M2: '',
      F1: '',
      F2: ''
    },
    withCredentials: false
  },
  recaptcha: {
    siteKey: '6LftjWkkAAAAABb_4WQJjoA5K-QomNSD4dActicC',
    urlEmail: 'https://karl-malone.devgeo.msp.gouv.qc.ca/nous-joindre-vigilance'
  },
  igo: {
    context: {
      defaultContextUri: "dev"
    },
    app: {
      forceCoordsNA: true,
      pwa: {
        enabled: false,
        promote: false
      }
    },
    language: {
      prefix: ['./locale/', './particular/locale/']
    },
    projections: [
      {
        code: 'EPSG:32198',
        alias: 'Quebec Lambert',
        def:
          '+proj=lcc +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +ellps=GRS80 \
          +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
        extent: [-799574, 45802, 891595.4, 1849567.5]
      },
      {
        code: 'EPSG:3798',
        alias: 'MTQ Lambert',
        def:
          '+proj=lcc +lat_1=50 +lat_2=46 +lat_0=44 +lon_0=-70 +x_0=800000 +y_0=0 \
          +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
        extent: [31796.5834, 158846.2231, 1813323.4284, 2141241.0978]
      }
    ],
    searchSources: {
      nominatim: {
        available: false
      },
      storedqueries: {
        enabled: false,
        available: false
      },
      icherche: {
        searchUrl: 'https://geoegl.msp.gouv.qc.ca/apis/icherche/',
        order: 2,
        params: {
          limit: '5'
        },
        settings:[],
        showInPointerSummary: true
      },
      coordinatesreverse: {
        order: 1,
        showInPointerSummary: true
      },
      icherchereverse: {
        showInPointerSummary: true,
        searchUrl: 'https://geoegl.msp.gouv.qc.ca/apis/terrapi',
        order: 3,
        enabled: true
      },
      ilayer: {
        enabled: false,
        searchUrl: 'https://geoegl.msp.gouv.qc.ca/apis/icherche/layers',
        order: 4,
        params: {
          limit: '3'
        }
      },
      cadastre: {
        enabled: false
      }
    },
    queryOverlayStyle: {
      base: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 0.8, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 0.7, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      focus: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      selection: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      }
    },
    searchOverlayStyle: {
      base: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 0.8, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 0.7, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      focus: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      },
      selection: {
        markerColor: '#45D7E6', // marker fill
        markerOpacity: 1, // marker opacity not applied if a rgba markerColor is provided
        markerOutlineColor: '#ffffff', // marker contour
        fillColor: 'transparent', // poly
        fillOpacity: 0, // poly fill opacity not applied if a rgba fillColor is provided
        strokeColor: '#45D7E6', // line and poly
        strokeOpacity: 1, // line and poly not applied if a rgba strokeColor is provided
        strokeWidth: 4 // line and poly
      }
    }
  }
};
