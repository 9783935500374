import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@igo2/core';

@Pipe({
  name: 'getStationTrend'
})
export class GetStationTrendPipe implements PipeTransform {
	constructor(private _languageService: LanguageService) {}
  /**
	 * @description get the trend label
	 * @param trendPct numeric trend
	 * @returns string representing corresponding trend label
	 */
  transform(trendPct: number | null): string {
    if (trendPct < 0) {
		return this._languageService.translate.instant("trend.down");
	} else if (trendPct > 0) {
		return this._languageService.translate.instant("trend.up");
	} else {
		return this._languageService.translate.instant("trend.stable");
	}
  }
}
