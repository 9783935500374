<mat-expansion-panel
    id="toggle-panel"
    expanded
    *ngIf="zoom >= minZoom"
    [hideToggle]="false"
    >
    <mat-expansion-panel-header>
        <h6 class="layer-toggles-title">{{ 'layerToggle.title' | translate }}</h6>
    </mat-expansion-panel-header>

    <div class="layer-toggles">
        <div class="layer-toggle" id="layer-toggle-date">
            <div class="layer-toggle-header" (click)="tooltipDate.toggle()">
                <p class="layer-toggle-title info-toggle">{{ 'layerToggle.date' | translate }}</p>
                <div
                    class="info-container"
                    #tooltipDate="matTooltip"
                    [matTooltip]="'info.date' | translate"
                    matTooltipPosition="left"
                    matTooltipClass="tooltip-left"
                    matTooltipShowDelay="100"
                    matTooltipTouchGestures="auto">
                    <mat-icon
                        class="info"
                        color="primary"
                        svgIcon="information">
                    </mat-icon>
                </div>
            </div>
            <mat-button-toggle-group 
                #groupDate="matButtonToggleGroup"
                class="button-group"
                [(value)]="dateToggle">
                <mat-button-toggle color="primary" value="j1" (change)="toggleLayer()">{{ today | date : getDateFormat() }}</mat-button-toggle>
                <mat-button-toggle color="primary" value="j2" (change)="toggleLayer()">{{ tomorrow | date : getDateFormat() }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="layer-toggle info-toggle" id="layer-toggle-scenario">
            <div class="layer-toggle-header" (click)="tooltipScenario.toggle()">
                <p class="layer-toggle-title">{{ 'layerToggle.scenario.scenario' | translate }}</p>
                <div
                    class="info-container"
                    #tooltipScenario="matTooltip"
                    [matTooltip]="'info.scenario-q' | translate"
                    matTooltipPosition="left"
                    matTooltipClass="tooltip-left"
                    matTooltipShowDelay="100"
                    matTooltipTouchGestures="auto">
                    <mat-icon
                        class="info"
                        color="primary"
                        svgIcon="information">
                    </mat-icon>
                </div>
            </div>
            <mat-button-toggle-group 
                #groupScenario="matButtonToggleGroup"
                class="button-group"
                [(value)]="scenarioToggle">
                <mat-button-toggle color="primary" value="median" (change)="toggleLayer()">{{ 'layerToggle.scenario.moderate' | translate }}</mat-button-toggle>
                <mat-button-toggle color="primary" value="fort" (change)="toggleLayer()">{{ 'layerToggle.scenario.high' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

</mat-expansion-panel>