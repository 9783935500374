import { StationCacheService } from './station-cache.service';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

const TIME_TO_RESET_CACHE: number = 15 * 60; // number of seconds before a new request in sent

@Injectable()
export class StationCacheInterceptor implements HttpInterceptor {
    constructor(private _scs: StationCacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if the method is not GET, continue
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        // get an eventual cached response
        const cachedResponse = this._scs.getCache(req.urlWithParams);

        // if there's a cached response, return it; else get new response
        return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // set new cache
                    this._scs.setCache(req.urlWithParams, event, TIME_TO_RESET_CACHE);
                }
            })
        );
    }
}
