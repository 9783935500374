import { ContactComponent } from './../menu-pages/contact/contact.component';
import { StationsFiltersListComponent } from './../menu-pages/stations/stations-filters-list/stations-filters-list.component';
import { StationsDetailsComponent } from './../menu-pages/stations/stations-details/stations-details.component';
import { HomeComponent } from '../menu-pages/home/home.component';
import { MyAccountComponent } from '../menu-pages/my-account/my-account.component';
import { AboutMapComponent } from '../menu-pages/about/about-map/about-map.component';
import { AboutStationsComponent } from '../menu-pages/about/about-stations/about-stations.component';
import { AboutTrainingComponent } from '../menu-pages/about/about-training/about-training.component';
import { AboutTrainingM1Component } from '../menu-pages/about/about-training/about-training-m1/about-training-m1.component';
import { AboutTrainingM2Component } from '../menu-pages/about/about-training/about-training-m2/about-training-m2.component';
import { AboutTrainingM3Component } from '../menu-pages/about/about-training/about-training-m3/about-training-m3.component';
import { AboutTrainingM4Component } from '../menu-pages/about/about-training/about-training-m4/about-training-m4.component';
import { AboutTrainingM5Component } from '../menu-pages/about/about-training/about-training-m5/about-training-m5.component';

import { MapComponent } from '../menu-pages/map/map.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'carte', component: MapComponent },
    { path: 'stations', component: StationsFiltersListComponent },
    { path: 'stations/:id', component: StationsDetailsComponent },
    { path: 'nous-joindre', component: ContactComponent },
    { path: 'en-savoir-plus/carte', component: AboutMapComponent },
    { path: 'mon-compte', component: MyAccountComponent, data: { breadCrum: 'Mon Compte' }},
    { path: 'en-savoir-plus/stations', component: AboutStationsComponent },
    { path: 'en-savoir-plus/formations', component: AboutTrainingComponent },
    { path: 'en-savoir-plus/formations/module-1', component: AboutTrainingM1Component },
    { path: 'en-savoir-plus/formations/module-2', component: AboutTrainingM2Component },
    { path: 'en-savoir-plus/formations/module-3', component: AboutTrainingM3Component },
    { path: 'en-savoir-plus/formations/module-4', component: AboutTrainingM4Component },
    { path: 'en-savoir-plus/formations/module-5', component: AboutTrainingM5Component },
  ];

@NgModule({
    imports: [
      RouterModule.forRoot(routes, {
      //anchorScrolling: 'enabled',
      //scrollPositionRestoration: 'enabled',
      //onSameUrlNavigation: 'reload',
      //relativeLinkResolution: 'legacy'
  }),
  RouterModule.forRoot(
    [{ path: "mon-compte", component: MyAccountComponent}],
    { onSameUrlNavigation: "reload" })
],
    exports: [RouterModule],
  })
  export class MenuRoutingModule { }
